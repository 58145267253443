import { TranslationKeys, TranslationObject } from "./keys";

export const en: TranslationObject = {
  [TranslationKeys.Wallets]: "Wallets",
  [TranslationKeys.Categories]: "Categories",
  [TranslationKeys.Transactions]: "Transactions",
  [TranslationKeys.Transaction]: "Transaction",
  [TranslationKeys.Income]: "Income",
  [TranslationKeys.Expenses]: "Expenses",
  [TranslationKeys.AddTransaction]: "Add transaction",
  [TranslationKeys.EditTransaction]: "Edit transaction",
  [TranslationKeys.Amount]: "Amount",
  [TranslationKeys.Category]: "Category",
  [TranslationKeys.Title]: "Title",
  [TranslationKeys.Wallet]: "Wallet",
  [TranslationKeys.ToWallet]: "To wallet",
  [TranslationKeys.Chart]: "Chart",
  [TranslationKeys.Assets]: "Assets",
  [TranslationKeys.Liabilities]: "Liabilities",
  [TranslationKeys.AddANewWallet]: "Add a new wallet",
  [TranslationKeys.CreditCard]: "Credit Card",
  [TranslationKeys.CheckingAccount]: "Checking Account",
  [TranslationKeys.SavingsAccount]: "Savings Account",
  [TranslationKeys.Balance]: "Balance",
  [TranslationKeys.Limit]: "Limit",
  [TranslationKeys.LastFourDigits]: "Last Four Digits",
  [TranslationKeys.AddWallet]: "Add wallet",
  [TranslationKeys.EditWallet]: "Edit wallet",
  [TranslationKeys.Reminders]: "Reminders",
  [TranslationKeys.Reminder]: "Reminder",
  [TranslationKeys.AddANewReminder]: "Add a new reminder",
  [TranslationKeys.AddReminder]: "Add reminder",
  [TranslationKeys.EditReminder]: "Edit reminder",
  [TranslationKeys.Type]: "Type",
  [TranslationKeys.Subscription]: "Subscription",
  [TranslationKeys.Bill]: "Bill",
  [TranslationKeys.Mortgage]: "Mortgage",
  [TranslationKeys.Installment]: "Installment",
  [TranslationKeys.Recurring]: "Recurring",
  [TranslationKeys.Weekly]: "Weekly",
  [TranslationKeys.Monthly]: "Monthly",
  [TranslationKeys.Yearly]: "Yearly",
  [TranslationKeys.On]: "on",
  [TranslationKeys.RemindMe]: "Remind me",
  [TranslationKeys.Day]: "Day",
  [TranslationKeys.Week]: "Week",
  [TranslationKeys.Month]: "Month",
  [TranslationKeys.Notification]: "Notification",
  [TranslationKeys.Company]: "Company",
  [TranslationKeys.Custom]: "Custom",
  [TranslationKeys.Search]: "Search...",
  [TranslationKeys.Name]: "Name",
  [TranslationKeys.Description]: "Description",
  [TranslationKeys.Settings]: "Settings",
  [TranslationKeys.Light]: "Light",
  [TranslationKeys.Dark]: "Dark",
  [TranslationKeys.GoldMembership]: "Gold Membership",
  [TranslationKeys.SignOut]: "Sign Out",
  [TranslationKeys.Home]: "Home",
  [TranslationKeys.SignIn]: "Sign in",
  [TranslationKeys.SignUp]: "Sign up",
  [TranslationKeys.MostUsed]: "Most used",
  [TranslationKeys.NextOn]: "Next on",
  [TranslationKeys.Before]: "before",
  [TranslationKeys.Days]: "days",
  [TranslationKeys.Weeks]: "weeks",
  [TranslationKeys.Months]: "months",
  [TranslationKeys.Animations]: "Animations",
};
