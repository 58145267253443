import { TranslationKeys, TranslationObject } from "./keys";

export const de: TranslationObject = {
  [TranslationKeys.Wallets]: "Geldbörsen",
  [TranslationKeys.Categories]: "Kategorien",
  [TranslationKeys.Transactions]: "Transaktionen",
  [TranslationKeys.Transaction]: "Transaktion",
  [TranslationKeys.Income]: "Einkommen",
  [TranslationKeys.Expenses]: "Ausgaben",
  [TranslationKeys.AddTransaction]: "Transaktion hinzufügen",
  [TranslationKeys.EditTransaction]: "Transaktion bearbeiten",
  [TranslationKeys.Amount]: "Betrag",
  [TranslationKeys.Category]: "Kategorie",
  [TranslationKeys.Title]: "Titel",
  [TranslationKeys.Wallet]: "Geldbörse",
  [TranslationKeys.ToWallet]: "Zur Geldbörse",
  [TranslationKeys.Chart]: "Diagramm",
  [TranslationKeys.Assets]: "Vermögenswerte",
  [TranslationKeys.Liabilities]: "Verbindlichkeiten",
  [TranslationKeys.AddANewWallet]: "Neue Geldbörse hinzufügen",
  [TranslationKeys.CreditCard]: "Kreditkarte",
  [TranslationKeys.CheckingAccount]: "Girokonto",
  [TranslationKeys.SavingsAccount]: "Sparkonto",
  [TranslationKeys.Balance]: "Saldo",
  [TranslationKeys.Limit]: "Limit",
  [TranslationKeys.LastFourDigits]: "Letzte vier Ziffern",
  [TranslationKeys.AddWallet]: "Geldbörse hinzufügen",
  [TranslationKeys.EditWallet]: "Geldbörse bearbeiten",
  [TranslationKeys.Reminders]: "Erinnerungen",
  [TranslationKeys.Reminder]: "Erinnerung",
  [TranslationKeys.AddANewReminder]: "Neue Erinnerung hinzufügen",
  [TranslationKeys.AddReminder]: "Erinnerung hinzufügen",
  [TranslationKeys.EditReminder]: "Erinnerung bearbeiten",
  [TranslationKeys.Type]: "Typ",
  [TranslationKeys.Subscription]: "Abonnement",
  [TranslationKeys.Bill]: "Rechnung",
  [TranslationKeys.Mortgage]: "Hypothek",
  [TranslationKeys.Installment]: "Rate",
  [TranslationKeys.Recurring]: "Wiederkehrend",
  [TranslationKeys.Weekly]: "Wöchentlich",
  [TranslationKeys.Monthly]: "Monatlich",
  [TranslationKeys.Yearly]: "Jährlich",
  [TranslationKeys.On]: "am",
  [TranslationKeys.RemindMe]: "Erinnere mich",
  [TranslationKeys.Day]: "Tag",
  [TranslationKeys.Week]: "Woche",
  [TranslationKeys.Month]: "Monat",
  [TranslationKeys.Notification]: "Benachrichtigung",
  [TranslationKeys.Company]: "Unternehmen",
  [TranslationKeys.Custom]: "Benutzerdefiniert",
  [TranslationKeys.Search]: "Suche...",
  [TranslationKeys.Name]: "Name",
  [TranslationKeys.Description]: "Beschreibung",
  [TranslationKeys.Settings]: "Einstellungen",
  [TranslationKeys.Light]: "Hell",
  [TranslationKeys.Dark]: "Dunkel",
  [TranslationKeys.GoldMembership]: "Gold-Mitgliedschaft",
  [TranslationKeys.SignOut]: "Abmelden",
  [TranslationKeys.Home]: "Startseite",
  [TranslationKeys.SignIn]: "Anmelden",
  [TranslationKeys.SignUp]: "Registrieren",
  [TranslationKeys.MostUsed]: "Am häufigsten verwendet",
  [TranslationKeys.NextOn]: "Nächstes am",
  [TranslationKeys.Before]: "vor",
  [TranslationKeys.Days]: "Tage",
  [TranslationKeys.Weeks]: "Wochen",
  [TranslationKeys.Months]: "Monate",
  [TranslationKeys.Animations]: "Animationen",
};
