import {
  Icon,
  Input,
  Pressable,
  useColorModeValue,
  useTheme,
} from "native-base";
import * as React from "react";
import { Ionicons } from "@expo/vector-icons";
import RNEmojiPicker from "rn-emoji-keyboard";
import Emoji from "./Emoji";

type EmojiPickerProps = {
  value?: string;
  onChange: (value: string) => void;
};

export function EmojiPicker(props: EmojiPickerProps) {
  const { value, onChange } = props;
  const [open, setOpen] = React.useState(false);
  const { colors } = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value: string) => {
    onChange(value);
    handleClose();
  };

  return (
    <>
      <RNEmojiPicker
        onEmojiSelected={(emoji) => handleSelect(emoji.slug)}
        open={open}
        onClose={handleClose}
        enableSearchBar={true}
        backdropColor="#16161888"
        categoryContainerColor={useColorModeValue(
          colors.dark[200],
          colors.dark[600]
        )}
        categoryColor={useColorModeValue(colors.dark[400], colors.dark[800])}
        activeCategoryColor={useColorModeValue(
          colors.dark[600],
          colors.dark[300]
        )}
        activeCategoryContainerColor={useColorModeValue(
          colors.dark[100],
          colors.dark[900]
        )}
        knobStyles={{
          backgroundColor: useColorModeValue(
            colors.dark[200],
            colors.dark[600]
          ),
        }}
        containerStyles={{
          backgroundColor: useColorModeValue(colors.dark[50], colors.dark[800]),
        }}
        headerStyles={{
          color: useColorModeValue(colors.dark[800], colors.dark[100]),
          fontSize: 16,
        }}
        searchBarStyles={{
          borderColor: useColorModeValue(colors.dark[200], colors.dark[500]),
        }}
        searchBarTextStyles={{
          color: useColorModeValue(colors.dark[800], colors.dark[100]),
        }}
        searchBarPlaceholderColor={useColorModeValue(
          colors.dark[400],
          colors.dark[50]
        )}
      />
      <Input
        w="100%"
        value={value}
        placeholder="Icon"
        size="lg"
        InputLeftElement={
          value ? (
            <Emoji name={value} fontSize="lg" ml="10px" />
          ) : (
            <Icon as={Ionicons} name="settings" size="sm" ml="10px" />
          )
        }
        isReadOnly
      />
      <Pressable
        onPress={() => handleOpen()}
        h="40px"
        w="100%"
        position="relative"
        mt="-40px"
      ></Pressable>
    </>
  );
}
