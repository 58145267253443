import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CategoryDto, CreateCategoryDto } from "./types";
import { api } from "./api";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

export default function useCreateCategory() {
  const token = useSelector((state: RootState) => state.auth.token);
  const accountId = useSelector((state: RootState) => state.auth.accountId);
  const queryClient = useQueryClient();

  const query = async (input: CreateCategoryDto) => {
    const result = await api.post(`accounts/${accountId}/categories`, input, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  };
  return useMutation<CategoryDto, unknown, CreateCategoryDto>(
    ["createCategory"],
    query,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["categories"],
        });
        queryClient.invalidateQueries({
          queryKey: ["categoriesChart"],
        });
        queryClient.invalidateQueries({
          queryKey: ["categoriesOverview"],
        });
        queryClient.invalidateQueries({
          queryKey: ["topCategories"],
        });
      },
    }
  );
}
