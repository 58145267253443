import * as Localization from "expo-localization";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SettingsState {
  colorMode: "light" | "dark";
  year: number;
  month: number;
  language: string;
  animation: boolean;
}

const initialState: SettingsState = {
  colorMode: "light",
  year: new Date().getFullYear(),
  month: new Date().getMonth(),
  language: Localization.locale,
  animation: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    changeColorMode: (state, action: PayloadAction<"light" | "dark">) => {
      state.colorMode = action.payload;
    },
    updateDate: (
      state,
      action: PayloadAction<{
        year?: number;
        month?: number;
      }>
    ) => {
      if (action.payload.year !== undefined) {
        state.year = action.payload.year;
      }
      if (action.payload.month !== undefined) {
        state.month = action.payload.month;
      }
    },
    updateLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    updateAnimation: (state, action: PayloadAction<boolean>) => {
      state.animation = action.payload;
    },
  },
});

export const { changeColorMode, updateDate, updateLanguage, updateAnimation } =
  settingsSlice.actions;

export default settingsSlice.reducer;
