import React from "react";
import { useColorModeValue, View } from "native-base";
import { HeaderCard } from "../components/HeaderCard";
import Overview from "../components/Overview";
import { DailyTransactions } from "../components/DailyTransactions";
import { useSharedValue } from "react-native-reanimated";

export default function TransactionsScreen() {
  const [wallets, setWallets] = React.useState<string[]>([]);
  const [categories, setCategories] = React.useState<string[]>([]);

  const scrollValue = useSharedValue(0);
  const onScroll = (value: number) => {
    scrollValue.value = value;
  };

  return (
    <View bg={useColorModeValue("tertiary.50", "tertiary.900")} flex={1}>
      <HeaderCard
        scrollValue={scrollValue}
        content={
          <Overview
            wallets={wallets}
            setWallets={setWallets}
            categories={categories}
            setCategories={setCategories}
            scrollValue={scrollValue}
          />
        }
      />
      <DailyTransactions
        wallets={wallets}
        categories={categories}
        onScroll={onScroll}
      />
    </View>
  );
}
