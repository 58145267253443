import { useQuery } from "@tanstack/react-query";
import { CategoryDto, UserDto } from "./types";
import { api } from "./api";
import { RootState, store } from "../store/store";
import { useSelector } from "react-redux";
import { logout } from "../store/slices/authSlice";

export default function useGetMe() {
  const token = useSelector((state: RootState) => state.auth.token);

  const getMe = async () => {
    const result = await api.get(`users/me`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  };

  return useQuery<UserDto>(["me"], getMe, {
    onError: (error: any) => {
      if (error.response.status === 401) {
        store.dispatch(logout());
      }
    },
  });
}
