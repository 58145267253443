export type CategoryDto = {
  id: string;
  title: string;
  icon: string;
  type: string;
  order: number;
  parentId?: string;
  parent?: CategoryDto;
  children: CategoryDto[];
};

export type CategoriesChartItemDto = {
  key: string;
  value: number;
  category: CategoryDto;
};

export type GetCategoriesChartDto = {
  reverse?: boolean;
  year?: number;
  month?: number;
  week?: number;
  day?: number;
  type?: string;
};

export type CreateCategoryDto = {
  title: string;
  icon: string;
  type: string;
  parentId?: string;
};

export type UpdateCategoryDto = {
  id: string;
  title?: string;
  icon?: string;
  type?: string;
  parentId?: string;
};

export type CategoryOverviewChartItemDto = {
  key: string;
  value: number;
};

export type BudgetDto = {
  limit: number;
};

export type CategoriesOverviewItemDto = {
  value: number;
  category: CategoryDto;
  children: CategoriesOverviewItemDto[];
  chartItems: CategoryOverviewChartItemDto[];
  budget?: BudgetDto;
};

export type GetCategoriesOverviewDto = {
  reverse?: boolean;
  year?: number;
  month?: number;
  week?: number;
  day?: number;
  type?: string;
};

export type GetMostUsedCategoriesDto = {
  limit?: number;
};

export type WalletDto = {
  id: string;
  ownerId: string;
  title: string;
  description?: string;
  currency: string;
  type: string;
  bank: string;
  balance: number;
  lastFour?: string;
  limit?: number;
};

export type TransactionDto = {
  id: string;
  ownerId: string;
  title: string;
  description?: string;
  amount: number;
  walletId: string;
  wallet: WalletDto;
  categoryId: string;
  category: CategoryDto;
  time: Date;
  toWalletId?: string;
  toWallet?: WalletDto;
};

export type CreateTransactionDto = {
  title: string;
  description?: string;
  amount: number;
  walletId: string;
  categoryId: string;
  time: Date;
  toWalletId?: string;
};

export type UpdateTransactionDto = {
  id: string;
  title?: string;
  description?: string;
  amount?: number;
  walletId?: string;
  categoryId?: string;
  time?: Date;
  toWalletId?: string;
};

export const GroupedTransactionsGroupBy = {
  DAY: "DAY",
};

export type GroupedTransactionsGroupBy =
  (typeof GroupedTransactionsGroupBy)[keyof typeof GroupedTransactionsGroupBy];

export type GetGroupedTransactionsDto = {
  groupBy: GroupedTransactionsGroupBy;
  limit?: number;
  cursor?: number;
  reverse?: boolean;
  year?: number;
  month?: number;
  week?: number;
  day?: number;
  wallets?: string[];
  categories?: string[];
};

export type GroupedTransactionsDto = {
  groupBy: GroupedTransactionsGroupBy;
  nextPage?: number;
  items: GroupedTransactionsItemDto[];
};

export type GroupedTransactionsItemDto = {
  key: string;
  amount: number;
  transactions: TransactionDto[];
};

export type SessionDto = {
  id: string;
  ownerId: string;
  expoDeviceId: string;
};

export type CreateSessionDto = {
  expoDeviceId: string;
};

export type GetOverviewDto = {
  year: number;
  month?: number;
  week?: number;
  day?: number;
  wallets?: string[];
  categories?: string[];
};

export type OverviewDto = {
  income: number;
  expenses: number;
  net: number;
  transactionsCount: number;
};

export type RegisterDto = {
  email: string;
  password: string;
  name: string;
};

export type TokenDto = {
  accessToken: string;
  accountId: string;
};

export type ThirdPartyDto = {
  id: string;
  name: string;
  image: string;
  domain?: string;
};

export const ReminderType = {
  SUBSCRIPTION: "SUBSCRIPTION",
  BILL: "BILL",
  RENT: "RENT",
  MORTGAGE: "MORTGAGE",
  INSTALLMENT: "INSTALLMENT",
};

export type ReminderType = (typeof ReminderType)[keyof typeof ReminderType];

export type ReminderDto = {
  id: string;
  ownerId: string;
  thirdParty: ThirdPartyDto;
  title: string;
  amount: number;
  type: ReminderType;
  recurring?: boolean;
  recurringWeek?: number;
  recurringBiweek?: number;
  recurringMonth?: number;
  recurringYear?: number;
  reminder?: boolean;
  reminderDay?: number;
  reminderWeek?: number;
  reminderMonth?: number;
  reminderDismissed?: boolean;
};

export type CreateThirdPartyDto = {
  name: string;
  image: string;
  domain?: string;
};

export type CreateReminderDto = {
  thirdParty: CreateThirdPartyDto;
  title: string;
  amount: number;
  type: ReminderType;
  recurring?: boolean;
  recurringWeek?: number;
  recurringBiweek?: number;
  recurringMonth?: number;
  recurringYear?: number;
  reminder?: boolean;
  reminderDay?: number;
  reminderWeek?: number;
  reminderMonth?: number;
};

export type UpdateThirdPartyDto = {
  name?: string;
  image?: string;
  domain?: string;
};

export type UpdateReminderDto = {
  id: string;
  thirdParty?: UpdateThirdPartyDto;
  title?: string;
  amount?: number;
  type?: ReminderType;
  recurring?: boolean;
  recurringWeek?: number;
  recurringBiweek?: number;
  recurringMonth?: number;
  recurringYear?: number;
  reminder?: boolean;
  reminderDay?: number;
  reminderWeek?: number;
  reminderMonth?: number;
  reminderDismissed?: boolean;
};

export type RemindersOverviewItemDto = {
  date: Date;
  amount: number;
  count: number;
};

export type CreateWalletDto = {
  title: string;
  description?: string;
  currency: string;
  type: string;
  bank: string;
  balance?: number;
  lastFour?: string;
  limit?: number;
};

export type UpdateWalletDto = {
  id: string;
  title?: string;
  description?: string;
  type?: string;
  bank?: string;
  lastFour?: string;
  limit?: number;
};

export type GetTransactionsDto = {
  cursor?: number;
  limit?: number;
  reverse?: boolean;
  year?: number;
  month?: number;
  week?: number;
  day?: number;
};

export type PaginatedTransactionsDto = {
  nodes: TransactionDto[];
  cursor?: number;
  hasNext?: boolean;
};

export type AuthenticateDto = {
  email: string;
  password: string;
};

export type AuthenticateWithGoogleDto = {
  idToken: string;
};

export type CompanyDto = {
  image: string;
  name: string;
  domain?: string | undefined;
};
export type UserDto = {
  id: string;
  email: string;
  name: string;
  accountId?: string;
  avatar?: string;
};
