export enum TranslationKeys {
  Wallets = "Wallets",
  Categories = "Categories",
  Transactions = "Transactions",
  Transaction = "Transaction",
  Income = "Income",
  Expenses = "Expenses",
  AddTransaction = "Add transaction",
  EditTransaction = "Edit transaction",
  Amount = "Amount",
  Category = "Category",
  Title = "Title",
  Wallet = "Wallet",
  ToWallet = "To wallet",
  Chart = "Chart",
  Assets = "Assets",
  Liabilities = "Liabilities",
  AddANewWallet = "Add a new wallet",
  CreditCard = "Credit Card",
  CheckingAccount = "Checking Account",
  SavingsAccount = "Savings Account",
  Balance = "Balance",
  Limit = "Limit",
  LastFourDigits = "Last Four Digits",
  AddWallet = "Add wallet",
  EditWallet = "Edit wallet",
  Reminders = "Reminders",
  Reminder = "Reminder",
  AddANewReminder = "Add a new reminder",
  AddReminder = "Add reminder",
  EditReminder = "Edit reminder",
  Type = "Type",
  Subscription = "SUBSCRIPTION",
  Bill = "BILL",
  Mortgage = "MORTGAGE",
  Installment = "INSTALLMENT",
  Recurring = "Recurring",
  Weekly = "Weekly",
  Monthly = "Monthly",
  Yearly = "Yearly",
  On = "on",
  RemindMe = "Remind me",
  Day = "Day",
  Week = "Week",
  Month = "Month",
  Notification = "Notification",
  Company = "Company",
  Custom = "Custom",
  Search = "Search...",
  Name = "Name",
  Description = "Description",
  Settings = "Settings",
  Light = "Light",
  Dark = "Dark",
  GoldMembership = "Gold Membership",
  SignOut = "Sign Out",
  Home = "Home",
  SignIn = "Sign in",
  SignUp = "Sign up",
  MostUsed = "Most used",
  NextOn = "Next on",
  Before = "before",
  Days = "days",
  Weeks = "weeks",
  Months = "months",
  Animations = "Animations",
}

export enum LanguageKeys {
  en = "en",
  fa = "fa",
  de = "de",
  fr = "fr",
  ar = "ar",
  es = "es",
}

export type TranslationObject = Record<TranslationKeys, string>;
