import React, { useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import LoginScreen from "./LoginScreen";
import RegisterScreen from "./RegisterScreen";
import { StatusBar, useColorMode, useTheme } from "native-base";
import * as NavigationBar from "expo-navigation-bar";
import { Platform } from "react-native";
import SettingsNavigation from "./SettingsNavigation";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";
import TabsNavigation from "./TabsNavigation";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";

const Stack = createStackNavigator();

export default function MainNavigation() {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const { colors } = useTheme();
  const token = useSelector((state: RootState) => state.auth.token);

  if (Platform.OS === "android") {
    NavigationBar.setBackgroundColorAsync(
      colorMode === "light" ? colors.tertiary[50] : colors.tertiary[900]
    );
  }

  return (
    <>
      <StatusBar
        barStyle="light-content"
        backgroundColor={
          colorMode === "light" ? colors.primary[800] : colors.dark[800]
        }
      />
      <NavigationContainer>
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
            headerTintColor: colors.dark[200],
            headerStyle: {
              backgroundColor:
                colorMode === "light" ? colors.primary[800] : colors.dark[800],
              borderBottomWidth: 0,
              elevation: 0,
              shadowOpacity: 0,
              shadowRadius: 0,
            },
            gestureEnabled: true,
            ...TransitionPresets.SlideFromRightIOS,
          }}
        >
          {token ? (
            <>
              <Stack.Screen
                name="Home"
                component={TabsNavigation}
                options={{ title: t(TranslationKeys.Home) as string }}
              />
              <Stack.Screen
                name="Settings"
                component={SettingsNavigation}
                options={{ title: t(TranslationKeys.Settings) as string }}
              />
            </>
          ) : (
            <>
              <Stack.Screen
                name="Login"
                component={LoginScreen}
                options={{
                  title: t(TranslationKeys.SignIn) as string,
                  headerShown: true,
                }}
              />
              <Stack.Screen
                name="Register"
                component={RegisterScreen}
                options={{
                  title: t(TranslationKeys.SignUp) as string,
                  headerShown: true,
                }}
              />
            </>
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </>
  );
}
