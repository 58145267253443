import React from "react";
import {
  createStackNavigator,
  TransitionPresets,
} from "@react-navigation/stack";
import { StatusBar, useColorMode, useTheme } from "native-base";
import SettingsMainScreen from "./SettingsMainScreen";
import CategoriesScreen from "./CategoriesScreen";
import { Platform } from "react-native";
import * as NavigationBar from "expo-navigation-bar";
import WalletsScreen from "./WalletsScreen";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";

const Stack = createStackNavigator();

export default function SettingsNavigation() {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const { colors } = useTheme();

  if (Platform.OS === "android") {
    NavigationBar.setBackgroundColorAsync(
      colorMode === "light" ? colors.white : colors.dark[900]
    );
  }

  return (
    <>
      <StatusBar
        barStyle="light-content"
        backgroundColor={
          colorMode === "light" ? colors.primary[800] : colors.dark[800]
        }
      />
      <Stack.Navigator
        screenOptions={{
          headerStyle: {
            backgroundColor:
              colorMode === "light" ? colors.primary[800] : colors.dark[800],
            borderBottomWidth: 0,
            elevation: 0,
            shadowOpacity: 0,
            shadowRadius: 0,
          },
          headerTintColor: colors.blueGray[300],
          gestureEnabled: true,
          ...TransitionPresets.SlideFromRightIOS,
        }}
      >
        <Stack.Screen
          name="Main"
          component={SettingsMainScreen}
          options={{ title: t(TranslationKeys.Settings) as string }}
        />
        <Stack.Screen
          name="Categories"
          component={CategoriesScreen}
          options={{ title: t(TranslationKeys.Categories) as string }}
        />
        <Stack.Screen
          name="Wallets"
          component={WalletsScreen}
          options={{ title: t(TranslationKeys.Wallets) as string }}
        />
      </Stack.Navigator>
    </>
  );
}
