import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { Ionicons } from "@expo/vector-icons";
import {
  Center,
  FormControl,
  Heading,
  Icon,
  IconButton,
  Input,
  Modal,
  Select,
  useColorModeValue,
  VStack,
} from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CategoryPicker from "./CategoryPicker";
import { EmojiPicker } from "./EmojiPicker";
import useCreateCategory from "../hooks/useCreateCategory";
import useUpdateCategory from "../hooks/useUpdateCategory";
import { CategoryDto } from "../hooks/types";

type CategoryModalProps = {
  open: boolean;
  handleClose: () => void;
  category?: CategoryDto;
};

export function CategoryModal(props: CategoryModalProps) {
  const { open, handleClose, category } = props;

  const mode = category ? "edit" : "add";

  const schema = yup
    .object({
      id: yup.string().optional(),
      title: yup.string().min(3).required().label("Title"),
      icon: yup.string().required().label("Icon"),
      type: yup
        .string()
        .oneOf(["expenses", "income", "transfer"])
        .required()
        .label("Type"),
      parentId: yup
        .object({
          id: yup.string().optional(),
          type: yup
            .string()
            .oneOf(["expenses", "income", "transfer"])
            .optional(),
        })
        .optional()
        .label("Parent"),
    })
    .required();

  const { handleSubmit, control, setValue, reset, formState } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: "",
      icon: "",
      title: "",
      type: "",
      parentId: {
        id: "",
        type: "expenses",
      },
    },
  });

  React.useEffect(() => {
    if (category) {
      setValue("id", category.id);
      setValue("icon", category.icon);
      setValue("title", category.title);
      setValue("type", category.type);
      if (category.parentId) {
        setValue("parentId", { id: category.parentId, type: "expenses" });
      }
    }
  }, [setValue, category]);

  const { mutateAsync: createCategory } = useCreateCategory();

  const onCreate = (data: any) =>
    createCategory({
      icon: data.icon,
      title: data.title,
      type: data.type,
      parentId: data.parentId.id.length > 0 ? data.parentId.id : undefined,
    }).then(() => {
      reset();
      handleClose();
    });

  const { mutateAsync: updateCategory } = useUpdateCategory();

  const onEdit = (data: any) =>
    updateCategory({
      id: data.id,
      icon: data.icon,
      title: data.title,
      type: data.type,
      parentId: data.parentId.id.length > 0 ? data.parentId.id : undefined,
    }).then(() => {
      reset();
      handleClose();
    });

  const onModalClose = () => {
    handleClose();
    reset();
  };

  const addButtonColorScheme = useColorModeValue("green", "dark");
  const deleteButtonColorScheme = useColorModeValue("red", "dark");
  const deleteButtonVariant = useColorModeValue("solid", "outline");
  const editButtonColorScheme = useColorModeValue("blue", "dark");

  return (
    <Modal
      isOpen={open}
      onClose={onModalClose}
      size="xl"
      animationPreset="slide"
      avoidKeyboard={true}
    >
      <Modal.Content
        borderRadius="3xl"
        bg={useColorModeValue("white", "dark.700")}
      >
        <Modal.CloseButton />
        <Modal.Header
          borderBottomWidth={0}
          bg={useColorModeValue("white", "dark.700")}
        >
          <Heading size="md">
            {mode === "add" ? "Add category" : "Edit category"}
          </Heading>
        </Modal.Header>
        <Modal.Body>
          <VStack w="100%" space={5}>
            <Controller
              name={"title"}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormControl isRequired w="100%" isInvalid={invalid}>
                  <Input
                    value={value}
                    onChangeText={onChange}
                    placeholder="Title"
                    size="lg"
                    InputLeftElement={
                      <Icon
                        as={MaterialIcons}
                        name="description"
                        size="sm"
                        ml="10px"
                        textAlign="center"
                      />
                    }
                  />
                  <FormControl.ErrorMessage>
                    {error?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            />
            <Controller
              name={"icon"}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormControl isRequired w="100%" isInvalid={invalid}>
                  <EmojiPicker onChange={onChange} value={value} />
                  <FormControl.ErrorMessage>
                    {error?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            />
            <Controller
              name={"type"}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormControl isRequired w="100%" isInvalid={invalid}>
                  <Select
                    placeholder="Type"
                    selectedValue={value}
                    onValueChange={onChange}
                    size="lg"
                  >
                    <Select.Item label="Expenses" value="expenses" />
                    <Select.Item label="Income" value="income" />
                    <Select.Item label="Transfer" value="transfer" />
                  </Select>
                  <FormControl.ErrorMessage>
                    {error?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            />
            <Controller
              name={"parentId"}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormControl w="100%" isInvalid={invalid}>
                  <CategoryPicker
                    value={value}
                    onChange={onChange}
                    parentsOnly={true}
                  />
                  <FormControl.ErrorMessage>
                    {error?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            />
          </VStack>
        </Modal.Body>
        <Modal.Footer bg={useColorModeValue("white", "dark.700")}>
          <Center w="100%">
            {mode === "add" && (
              <IconButton
                colorScheme={addButtonColorScheme}
                variant="solid"
                size="lg"
                borderRadius="full"
                icon={<Icon as={Ionicons} name="checkmark" size="sm" />}
                onPress={handleSubmit(onCreate)}
              />
            )}
            {mode === "edit" && (
              <IconButton
                colorScheme={editButtonColorScheme}
                variant="solid"
                size="lg"
                borderRadius="full"
                icon={<Icon as={Ionicons} name="pencil" size="sm" />}
                onPress={handleSubmit(onEdit)}
              />
            )}
          </Center>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
