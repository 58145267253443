import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Icon,
  IconButton,
  Input,
  Modal,
  Pressable,
  Text,
  useColorModeValue,
} from "native-base";
import moment from "moment";
import { Ionicons, AntDesign } from "@expo/vector-icons";

type DatePickerProps = {
  value?: Date;
  onChange: (date: Date) => void;
};

function DatePicker({ value, onChange }: DatePickerProps) {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    value ? moment(value).startOf("day") : moment().startOf("day")
  );
  const currentMonth = selectedDate.clone().startOf("month");
  const [date, setDate] = useState(currentMonth);

  const nextMonth = date.clone().add({ month: 1 });

  const handleNext = () => {
    setDate(date.clone().add({ month: 1 }));
  };

  const handlePrev = () => {
    setDate(date.clone().subtract({ month: 1 }));
  };

  const handleChange = (date: moment.Moment) => {
    setSelectedDate(date);
    onChange(date.toDate());
    setShowModal(false);
  };

  useEffect(() => {
    setSelectedDate(moment(value).startOf("day"));
    const currentMonth = moment(value).startOf("day").clone().startOf("month");
    setDate(currentMonth);
  }, [value]);

  const grid: Array<Array<moment.Moment | undefined>> = [];
  let start = date.clone();
  let row = 0;
  let col = 0;
  while (start.month() === date.month()) {
    if (!grid[row]) {
      grid[row] = [];
    }
    const day = start.day();
    while (col < day) {
      grid[row][col] = undefined;
      col += 1;
    }
    grid[row][col] = start.clone();
    col += 1;
    if (col === 7) {
      col = 0;
      row += 1;
    }
    start = start.clone().add({ day: 1 });
  }
  while (row < 6) {
    if (!grid[row]) {
      grid[row] = [];
    }
    while (col < 7) {
      grid[row][col] = undefined;
      col += 1;
    }
    col = 0;
    row += 1;
  }

  return (
    <Center>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        animationPreset="slide"
        avoidKeyboard={true}
      >
        <Modal.Content
          borderRadius="3xl"
          bg={useColorModeValue("white", "dark.700")}
        >
          <Modal.Body>
            <Flex>
              <Flex direction="row" flex={1} mb="20px">
                <IconButton
                  colorScheme={useColorModeValue("primary", "dark")}
                  aria-label="Prev"
                  size="lg"
                  borderRadius="full"
                  variant="solid"
                  icon={<Icon as={AntDesign} name="caretleft" size="xs" />}
                  onPress={handlePrev}
                />
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  textAlign="center"
                  p="0px 20px"
                  flex="1"
                >
                  {date.format("MMM YYYY")}
                </Text>
                <IconButton
                  colorScheme={useColorModeValue("primary", "dark")}
                  aria-label="Next"
                  size="lg"
                  borderRadius="full"
                  variant="solid"
                  icon={<Icon as={AntDesign} name="caretright" size="xs" />}
                  onPress={handleNext}
                  isDisabled={nextMonth > moment()}
                />
              </Flex>
              <Flex direction="row" flex={1}>
                {["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day) => (
                  <Text
                    key={day}
                    textAlign="center"
                    flex={1}
                    color={useColorModeValue("dark.700", "white")}
                  >
                    {day}
                  </Text>
                ))}
              </Flex>
              <Divider my="2" bg={useColorModeValue("dark.700", "white")} />
              {grid.map((row, ridx) => (
                <Flex key={ridx} direction="row" flex={1}>
                  {row.map((day, cidx) => {
                    return (
                      <Box
                        w="40px"
                        h="40px"
                        p="5px"
                        flex={1}
                        key={`${ridx}-${cidx}`}
                      >
                        {day && (
                          <Button
                            w="30px"
                            h="30px"
                            p="5px"
                            borderRadius="full"
                            variant={
                              day.isSame(selectedDate, "day")
                                ? "solid"
                                : "ghost"
                            }
                            colorScheme={useColorModeValue("primary", "dark")}
                            onPress={() => handleChange(day)}
                          >
                            {day.date()}
                          </Button>
                        )}
                      </Box>
                    );
                  })}
                </Flex>
              ))}
            </Flex>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Input
        w="100%"
        value={moment(value).startOf("day").format("YYYY MMM DD")}
        placeholder="Amount"
        size="lg"
        borderRadius={"full"}
        InputLeftElement={
          <Icon as={Ionicons} name="calendar" size="sm" ml="10px" />
        }
        isReadOnly
      />
      <Pressable
        onPress={() => setShowModal(true)}
        h="40px"
        w="100%"
        position="relative"
        mt="-40px"
      ></Pressable>
    </Center>
  );
}

const areEqual = (prevProps: DatePickerProps, nextProps: DatePickerProps) => {
  if (prevProps.value !== nextProps.value) {
    return false;
  }
  return true;
};

export default React.memo(DatePicker, areEqual);
