import {
  Center,
  Flex,
  Pressable,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import * as React from "react";
import { NumericFormat } from "react-number-format";
import Emoji from "./Emoji";
import { TransactionDto } from "../hooks/types";

type DailyTransactionsDayItemProps = {
  transaction: TransactionDto;
  onClick: () => void;
};

function DailyTransactionsDayItem(props: DailyTransactionsDayItemProps) {
  const { transaction, onClick } = props;
  return (
    <Pressable w="100%" onPress={onClick}>
      <Flex w="100%" alignItems="center" direction="row">
        <Center
          bg={useColorModeValue("tertiary.100", "dark.600")}
          color="white"
          borderRadius="2xl"
          width="45px"
          height="45px"
        >
          <Emoji name={transaction?.category?.icon ?? ""} fontSize="3xl" />
        </Center>
        <VStack space={0} marginLeft="10px" alignItems="flex-start">
          <Text fontSize="sm">{transaction?.category?.title}</Text>
          <Text fontSize="xs">{transaction?.title}</Text>
        </VStack>
        <Spacer />
        <NumericFormat
          value={Math.abs(transaction?.amount)}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          renderText={(value) => (
            <Text
              fontSize="md"
              color={
                transaction?.category?.type === "transfer"
                  ? "blue.500"
                  : transaction?.amount >= 0
                  ? "green.500"
                  : "red.500"
              }
            >
              {value}
            </Text>
          )}
        />
      </Flex>
    </Pressable>
  );
}

const areEqual = (
  prevProps: DailyTransactionsDayItemProps,
  nextProps: DailyTransactionsDayItemProps
) => {
  if (prevProps.transaction?.id !== nextProps.transaction?.id) {
    return false;
  }
  if (prevProps.transaction?.amount !== nextProps.transaction?.amount) {
    return false;
  }
  return true;
};

export default React.memo(DailyTransactionsDayItem, areEqual);
