import {
  Badge,
  Button,
  Checkbox,
  Flex,
  HStack,
  Modal,
  Pressable,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import * as React from "react";
import Emoji from "./Emoji";
import useGetCategories from "../hooks/useGetCategories";
import { TranslationKeys } from "../translations/keys";
import { useTranslation } from "react-i18next";

type CategoryFilterProps = {
  parentsOnly?: boolean;
  categories: string[];
  setCategories: (value: string[]) => void;
};

function CategoryFilter(props: CategoryFilterProps) {
  const { parentsOnly = false, categories: categoryIds, setCategories } = props;
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState<string[]>([]);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (categoryIds) {
      setValues(categoryIds);
    }
  }, [categoryIds]);

  const categories = useGetCategories();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCategories(values);
  };
  const handleSelect = (value: any) => () => {
    const index = values.findIndex((v) => v === value.id);
    if (index > -1) {
      const selectedValues = [value.id];
      if (value.children && value.children.length > 0) {
        value.children.forEach((child: any) => {
          selectedValues.push(child.id);
        });
      }
      setValues((prev) => prev.filter((v) => selectedValues.indexOf(v) === -1));
    } else {
      const selectedValues = [value.id];
      if (value.children && value.children.length > 0) {
        value.children.forEach((child: any) => {
          selectedValues.push(child.id);
        });
      }
      setValues((prev) => [...prev, ...selectedValues]);
    }
  };

  const bgColor = useColorModeValue("dark.50", "dark.800");
  const badgeBgColor = useColorModeValue("primary.200", "primary.200");

  return (
    <>
      <Modal
        isOpen={open}
        onClose={handleClose}
        animationPreset="slide"
        avoidKeyboard={true}
      >
        <Modal.Content
          borderRadius="3xl"
          bg={useColorModeValue("white", "dark.700")}
          h="60%"
        >
          <Modal.CloseButton />
          <Modal.Body>
            <VStack>
              {categories.data?.map((category) => {
                if (
                  parentsOnly === false &&
                  category.children &&
                  category.children.length > 0
                ) {
                  const children = category.children.map((child) => (
                    <Pressable key={child.id} onPress={handleSelect(child)}>
                      <Flex
                        alignItems="center"
                        w="100%"
                        pl="20px"
                        py="10px"
                        mb="2px"
                        bg={bgColor}
                        borderRadius="lg"
                        direction="row"
                      >
                        <Checkbox
                          value={child.id}
                          isChecked={
                            values.findIndex((v) => v === child.id) > -1
                          }
                          mr="10px"
                          accessibilityLabel={child.title}
                        />
                        <Emoji name={child.icon} fontSize="lg" />
                        <Text pl="10px">{child.title}</Text>
                      </Flex>
                    </Pressable>
                  ));
                  return [
                    <Pressable
                      key={category.id}
                      onPress={handleSelect(category)}
                    >
                      <Flex
                        key={category.id}
                        alignItems="center"
                        w="100%"
                        py="10px"
                        direction="row"
                      >
                        <Checkbox
                          value={category.id}
                          isChecked={
                            values.findIndex((v) => v === category.id) > -1
                          }
                          mr="10px"
                          accessibilityLabel={category.title}
                        />
                        <Emoji name={category.icon} fontSize="lg" />
                        <Text pl="10px" fontSize="xl">
                          {category.title}
                        </Text>
                      </Flex>
                    </Pressable>,
                    children,
                  ];
                } else {
                  return (
                    <Pressable
                      key={category.id}
                      onPress={handleSelect(category)}
                    >
                      <Flex
                        alignItems="center"
                        w="100%"
                        py="10px"
                        bg={bgColor}
                        direction="row"
                      >
                        <Checkbox
                          value={category.id}
                          isChecked={
                            values.findIndex((v) => v === category.id) > -1
                          }
                          mx="10px"
                          accessibilityLabel={category.title}
                        />
                        <Emoji name={category.icon} fontSize="lg" />
                        <Text pl="10px">{category.title}</Text>
                      </Flex>
                    </Pressable>
                  );
                }
              })}
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Button
        size="sm"
        rounded="2xl"
        flex={1}
        variant={useColorModeValue("subtle", "solid")}
        colorScheme={
          values.length > 0
            ? useColorModeValue("primary", "primary")
            : useColorModeValue("coolGray", "dark")
        }
        onPress={handleOpen}
      >
        <HStack space={2}>
          <Text>{t(TranslationKeys.Categories)}</Text>
          {values.length > 0 && (
            <Badge rounded="full" bg={badgeBgColor}>
              {values.length}
            </Badge>
          )}
        </HStack>
      </Button>
    </>
  );
}

const areEqual = (
  prevProps: CategoryFilterProps,
  nextProps: CategoryFilterProps
) => {
  if (
    prevProps.parentsOnly === nextProps.parentsOnly &&
    prevProps.categories.length === nextProps.categories.length
  ) {
    return true;
  }
  return false;
};

export default React.memo(CategoryFilter, areEqual);
