import { useQuery } from "@tanstack/react-query";
import { CategoriesOverviewItemDto, GetCategoriesOverviewDto } from "./types";
import { api } from "./api";
import { RootState, store } from "../store/store";
import { useSelector } from "react-redux";
import { logout } from "../store/slices/authSlice";

export default function useGetCategoriesOverview(qs: GetCategoriesOverviewDto) {
  const token = useSelector((state: RootState) => state.auth.token);
  const accountId = useSelector((state: RootState) => state.auth.accountId);

  const query = async () => {
    const result = await api.get(`accounts/${accountId}/categories/overview`, {
      params: qs,
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  };
  return useQuery<CategoriesOverviewItemDto[]>(["categoriesOverview"], query, {
    onError: (error: any) => {
      if (error.response.status === 401) {
        store.dispatch(logout());
      }
    },
  });
}
