import { Text, useTheme } from "native-base";
import * as React from "react";
import { FlashList } from "@shopify/flash-list";
import { CategoryOverviewItem } from "./CategoryOverviewItem";
import useGetCategoriesOverview from "../hooks/useGetCategoriesOverview";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

type CategoryOverviewProps = {
  categoryType: string;
  onScroll: (value: number) => void;
};

export function CategoryOverview(props: CategoryOverviewProps) {
  const { categoryType, onScroll } = props;

  const { colors } = useTheme();
  const pieColors = [
    colors.red[500],
    colors.blue[500],
    colors.orange[400],
    colors.green[500],
    colors.pink[400],
    colors.cyan[400],
    colors.yellow[400],
    colors.teal[500],
  ];

  const year = useSelector((state: RootState) => state.settings.year);
  const month = useSelector((state: RootState) => state.settings.month);
  const animation = useSelector((state: RootState) => state.settings.animation);

  const { data, refetch, isRefetching, isLoading, isError } =
    useGetCategoriesOverview({
      year,
      month: month !== undefined ? month + 1 : undefined,
      reverse: true,
      type: categoryType,
    });

  const onRefresh = () => {
    refetch();
  };

  React.useEffect(() => {
    refetch();
  }, [year, month, categoryType]);

  if (isLoading) return <Text>Loading...</Text>;
  if (isError) return <Text>An error occurred while fetching data</Text>;

  return (
    <FlashList
      contentContainerStyle={{ paddingTop: 325, paddingBottom: 110 }}
      keyExtractor={(item: any) => item.category.id}
      data={data}
      renderItem={({ item, index }) => (
        <CategoryOverviewItem
          key={item.category.id}
          color={pieColors[index % pieColors.length]}
          item={item}
          date={{ year, month }}
        />
      )}
      estimatedItemSize={20}
      onRefresh={onRefresh}
      refreshing={isRefetching}
      showsVerticalScrollIndicator={false}
      onScroll={(e) => {
        if (animation) {
          onScroll(e.nativeEvent.contentOffset.y);
        }
      }}
    />
  );
}
