import React from "react";
import { Provider } from "react-redux";
import { Theme } from "./components/Theme";
import MainNavigation from "./screens/MainNavigation";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./app/queryClient";
import { persistor, store } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <Theme>
            <MainNavigation />
          </Theme>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}
