import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import settingsReducer from "./slices/settingsSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

const settingsPersistConfig = {
  key: "settings",
  storage: AsyncStorage,
  blacklist: ["year", "month"],
};

const persistedSettingsReducer = persistReducer(
  settingsPersistConfig,
  settingsReducer
);

const rootReducer = combineReducers({
  auth: authReducer,
  settings: persistedSettingsReducer,
});

const rootPersistConfig = {
  key: "root",
  storage: AsyncStorage,
  blacklist: ["settings"],
};

const persistedRootReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedRootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
