const emojis = require("rn-emoji-keyboard/lib/commonjs/assets/emojis.json");

const emojiMap: Record<string, string> = {};

for (const group of emojis) {
  for (const emoji of group.data) {
    emojiMap[emoji.name.split(" ").join("_")] = emoji.emoji;
  }
}

export function getEmoji(emojiSlug: string): any {
  return emojiMap[emojiSlug];
}
