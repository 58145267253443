import React from "react";
import { useColorModeValue, View } from "native-base";
import { RegisterForm } from "../components/RegisterForm";
import { NavigationProp } from "@react-navigation/native";

type RegisterScreenProps = {
  navigation: NavigationProp<any>;
};

export default function RegisterScreen({ navigation }: RegisterScreenProps) {
  return (
    <View bg={useColorModeValue("tertiary.50", "tertiary.900")} flex={1}>
      <RegisterForm navigation={navigation} />
    </View>
  );
}
