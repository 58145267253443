import { TranslationKeys, TranslationObject } from "./keys";

export const ar: TranslationObject = {
  [TranslationKeys.Wallets]: "محافظ",
  [TranslationKeys.Categories]: "التصنيفات",
  [TranslationKeys.Transactions]: "المعاملات",
  [TranslationKeys.Transaction]: "المعاملة",
  [TranslationKeys.Income]: "الإيرادات",
  [TranslationKeys.Expenses]: "المصروفات",
  [TranslationKeys.AddTransaction]: "إضافة معاملة",
  [TranslationKeys.EditTransaction]: "تعديل المعاملة",
  [TranslationKeys.Amount]: "المبلغ",
  [TranslationKeys.Category]: "التصنيف",
  [TranslationKeys.Title]: "العنوان",
  [TranslationKeys.Wallet]: "المحفظة",
  [TranslationKeys.ToWallet]: "إلى المحفظة",
  [TranslationKeys.Chart]: "الرسم البياني",
  [TranslationKeys.Assets]: "الأصول",
  [TranslationKeys.Liabilities]: "الخصوم",
  [TranslationKeys.AddANewWallet]: "إضافة محفظة جديدة",
  [TranslationKeys.CreditCard]: "بطاقة ائتمان",
  [TranslationKeys.CheckingAccount]: "الحساب الجاري",
  [TranslationKeys.SavingsAccount]: "حساب التوفير",
  [TranslationKeys.Balance]: "الرصيد",
  [TranslationKeys.Limit]: "الحد",
  [TranslationKeys.LastFourDigits]: "آخر أربعة أرقام",
  [TranslationKeys.AddWallet]: "إضافة محفظة",
  [TranslationKeys.EditWallet]: "تعديل المحفظة",
  [TranslationKeys.Reminders]: "التذكيرات",
  [TranslationKeys.Reminder]: "التذكير",
  [TranslationKeys.AddANewReminder]: "إضافة تذكير جديد",
  [TranslationKeys.AddReminder]: "إضافة تذكير",
  [TranslationKeys.EditReminder]: "تعديل التذكير",
  [TranslationKeys.Type]: "النوع",
  [TranslationKeys.Subscription]: "الاشتراك",
  [TranslationKeys.Bill]: "الفاتورة",
  [TranslationKeys.Mortgage]: "الرهن العقاري",
  [TranslationKeys.Installment]: "القسط",
  [TranslationKeys.Recurring]: "متكرر",
  [TranslationKeys.Weekly]: "أسبوعي",
  [TranslationKeys.Monthly]: "شهري",
  [TranslationKeys.Yearly]: "سنوي",
  [TranslationKeys.On]: "في",
  [TranslationKeys.RemindMe]: "ذكرني",
  [TranslationKeys.Day]: "اليوم",
  [TranslationKeys.Week]: "الأسبوع",
  [TranslationKeys.Month]: "الشهر",
  [TranslationKeys.Notification]: "الإشعار",
  [TranslationKeys.Company]: "الشركة",
  [TranslationKeys.Custom]: "مخصص",
  [TranslationKeys.Search]: "بحث...",
  [TranslationKeys.Name]: "الاسم",
  [TranslationKeys.Description]: "الوصف",
  [TranslationKeys.Settings]: "الإعدادات",
  [TranslationKeys.Light]: "فاتح",
  [TranslationKeys.Dark]: "داكن",
  [TranslationKeys.GoldMembership]: "عضوية ذهبية",
  [TranslationKeys.SignOut]: "تسجيل الخروج",
  [TranslationKeys.Home]: "الرئيسية",
  [TranslationKeys.SignIn]: "تسجيل الدخول",
  [TranslationKeys.SignUp]: "إنشاء حساب",
  [TranslationKeys.MostUsed]: "الأكثر استخدامًا",
  [TranslationKeys.NextOn]: "التالي في",
  [TranslationKeys.Before]: "قبل",
  [TranslationKeys.Days]: "أيام",
  [TranslationKeys.Weeks]: "أسابيع",
  [TranslationKeys.Months]: "شهور",
  [TranslationKeys.Animations]: "الرسوم المتحركة",
};
