import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  AuthenticateDto,
  AuthenticateWithGoogleDto,
  RegisterDto,
  TokenDto,
} from "../../hooks/types";
import { authenticateApi } from "../../hooks/useAuthenticate";
import { authenticateWithGoogleApi } from "../../hooks/useAuthenticateWithGoogle";
import { registerApi } from "../../hooks/useRegister";

export interface AuthState {
  loggedIn: boolean;
  token: string | undefined;
  accountId: string | undefined;
}

const initialState: AuthState = {
  loggedIn: false,
  token: undefined,
  accountId: undefined,
};

export const authenticate = createAsyncThunk<TokenDto, AuthenticateDto>(
  "users/authenticate",
  authenticateApi
);

export const authenticateWithGoogle = createAsyncThunk<
  TokenDto,
  AuthenticateWithGoogleDto
>("users/authenticateWithGoogle", authenticateWithGoogleApi);

export const register = createAsyncThunk<TokenDto, RegisterDto>(
  "users/register",
  registerApi
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.loggedIn = false;
      state.token = undefined;
      state.accountId = undefined;
    },
    changeAccountId: (state, action: PayloadAction<string>) => {
      state.accountId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      authenticate.fulfilled,
      (state, action: PayloadAction<TokenDto>) => {
        state.token = action.payload.accessToken;
        state.accountId = action.payload.accountId;
        state.loggedIn = true;
      }
    );
    builder.addCase(
      authenticateWithGoogle.fulfilled,
      (state, action: PayloadAction<TokenDto>) => {
        state.token = action.payload.accessToken;
        state.accountId = action.payload.accountId;
        state.loggedIn = true;
      }
    );
    builder.addCase(
      register.fulfilled,
      (state, action: PayloadAction<TokenDto>) => {
        state.token = action.payload.accessToken;
        state.accountId = action.payload.accountId;
        state.loggedIn = true;
      }
    );
  },
});

export const { logout, changeAccountId } = authSlice.actions;

export default authSlice.reducer;
