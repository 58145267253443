import React from "react";
import { useColorModeValue, View } from "native-base";
import { HeaderCard } from "../components/HeaderCard";
import { WalletsOverview } from "../components/WalletsOverview";
import { Wallets } from "../components/Wallets";
import { useSharedValue } from "react-native-reanimated";

export default function WalletsScreen() {
  const scrollValue = useSharedValue(0);
  const onScroll = (value: number) => {
    scrollValue.value = value;
  };

  return (
    <View bg={useColorModeValue("tertiary.50", "tertiary.900")} flex={1}>
      <HeaderCard scrollValue={scrollValue} content={<WalletsOverview />} />
      <Wallets onScroll={onScroll} />
    </View>
  );
}
