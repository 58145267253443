import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CategoryDto, UpdateCategoryDto } from "./types";
import { api } from "./api";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";

export default function useUpdateCategory() {
  const token = useSelector((state: RootState) => state.auth.token);
  const accountId = useSelector((state: RootState) => state.auth.accountId);
  const queryClient = useQueryClient();

  const query = async (input: UpdateCategoryDto) => {
    const result = await api.put(
      `accounts/${accountId}/categories/${input.id}`,
      input,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return result.data;
  };
  return useMutation<CategoryDto, unknown, UpdateCategoryDto>(
    ["updateCategory"],
    query,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["categories"],
        });
        queryClient.invalidateQueries({
          queryKey: ["categoriesChart"],
        });
        queryClient.invalidateQueries({
          queryKey: ["categoriesOverview"],
        });
        queryClient.invalidateQueries({
          queryKey: ["topCategories"],
        });
        queryClient.invalidateQueries({
          queryKey: ["groupedTransactions"],
        });
        queryClient.invalidateQueries({
          queryKey: ["transactionsOverview"],
        });
        queryClient.invalidateQueries({
          queryKey: ["transactions"],
        });
      },
    }
  );
}
