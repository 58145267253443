import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { Ionicons } from "@expo/vector-icons";
import {
  Button,
  Center,
  FormControl,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "./DatePicker";
import CategoryPicker from "./CategoryPicker";
import WalletPicker from "./WalletPicker";
import useCreateTransaction from "../hooks/useCreateTransaction";
import useUpdateTransaction from "../hooks/useUpdateTransaction";
import useDeleteTransaction from "../hooks/useDeleteTransaction";
import { TransactionDto } from "../hooks/types";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";

type TransactionModalProps = {
  open: boolean;
  handleClose: () => void;
  transaction?: TransactionDto;
};

function TransactionModal(props: TransactionModalProps) {
  const { open, handleClose, transaction } = props;

  const { t } = useTranslation();

  const mode = transaction ? "edit" : "add";

  const schema = yup
    .object({
      title: yup.string().min(3).required().label("Title"),
      amount: yup
        .number()
        .typeError("Amount must be a number")
        .positive()
        .required()
        .label("Amount"),
      time: yup.date().required().label("Date"),
      category: yup
        .object({
          id: yup.string().required(),
          type: yup
            .string()
            .oneOf(["expenses", "income", "transfer"])
            .required(),
        })
        .required()
        .label("Category"),
      walletId: yup.string().required().label("Wallet"),
      toWalletId: yup
        .string()
        .when("category.type", {
          is: "transfer",
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.optional(),
        })
        .label("To Wallet"),
    })
    .required();

  const { handleSubmit, control, setValue, reset, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: "",
      title: "",
      amount: "",
      time: moment().startOf("day").toDate(),
      category: {
        id: "",
        type: "expenses",
      },
      walletId: "",
      toWalletId: "",
    },
  });

  React.useEffect(() => {
    if (transaction) {
      setValue("id", transaction.id);
      setValue("title", transaction.title);
      setValue("amount", `${Math.abs(transaction.amount)}`);
      setValue("time", moment(transaction.time).toDate());
      setValue("category", transaction.category);
      setValue("walletId", transaction.wallet.id);
      setValue("toWalletId", transaction.toWallet?.id ?? "");
    }
  }, [setValue, transaction]);

  const { mutateAsync: createTransaction } = useCreateTransaction();

  const onCreate = (data: any) =>
    createTransaction({
      title: data.title,
      description: data.description,
      amount:
        data.category.type !== "income"
          ? parseFloat(data.amount) * -1
          : parseFloat(data.amount),
      time: new Date(data.time),
      categoryId: data.category.id,
      walletId: data.walletId,
      toWalletId: data.toWalletId?.length > 0 ? data.toWalletId : undefined,
    })
      .then(() => {
        reset();
        handleClose();
      })
      .catch(() => {});

  const { mutateAsync: updateTransaction } = useUpdateTransaction();

  const onEdit = (data: any) =>
    updateTransaction({
      id: data.id,
      title: data.title,
      description: data.description,
      amount:
        data.category.type !== "income"
          ? parseFloat(data.amount) * -1
          : parseFloat(data.amount),
      time: new Date(data.time),
      categoryId: data.category.id,
      walletId: data.walletId,
      toWalletId: data.toWalletId?.length > 0 ? data.toWalletId : undefined,
    })
      .then(() => {
        reset();
        handleClose();
      })
      .catch(() => {});

  const { mutateAsync: deleteTransaction } = useDeleteTransaction();

  const onDelete = (data: any) =>
    deleteTransaction(data.id)
      .then(() => {
        reset();
        handleClose();
      })
      .catch(() => {});

  const onModalClose = () => {
    handleClose();
    reset();
  };

  const addButtonColorScheme = useColorModeValue("green", "dark");
  const deleteButtonColorScheme = useColorModeValue("red", "dark");
  const editButtonColorScheme = useColorModeValue("blue", "dark");

  return (
    <Modal
      isOpen={open}
      onClose={onModalClose}
      size="xl"
      animationPreset="slide"
      avoidKeyboard={true}
    >
      <Modal.Content
        borderRadius="3xl"
        bg={useColorModeValue("white", "dark.700")}
        paddingBottom={5}
      >
        <Modal.Header
          borderBottomWidth={0}
          bg={useColorModeValue("white", "dark.700")}
          alignItems={"center"}
        >
          <Heading size="md">{t(TranslationKeys.Transaction)}</Heading>
        </Modal.Header>
        <Modal.Body>
          <VStack w="100%" space={5}>
            <HStack w="99%" space={1}>
              <Controller
                name={"amount"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl isRequired w="1/2" isInvalid={invalid}>
                    <Input
                      value={value}
                      onChangeText={onChange}
                      placeholder={t(TranslationKeys.Amount) as string}
                      keyboardType="decimal-pad"
                      size="lg"
                      borderRadius={"full"}
                      InputLeftElement={
                        <Icon
                          as={FontAwesome}
                          name="dollar"
                          size="sm"
                          ml="10px"
                          textAlign="center"
                        />
                      }
                      InputRightElement={<Text mr="10px">CAD</Text>}
                    />
                    <FormControl.ErrorMessage>
                      {error?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                name={"time"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl isRequired w="1/2" isInvalid={invalid}>
                    <DatePicker value={value} onChange={onChange} />
                    <FormControl.ErrorMessage>
                      {error?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                )}
              />
            </HStack>

            <HStack w="99%" space={1}>
              <Controller
                name={"category"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl isRequired w="1/2" isInvalid={invalid}>
                    <CategoryPicker value={value} onChange={onChange} />
                    <FormControl.ErrorMessage>
                      {error?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                name={"walletId"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl isRequired w="1/2" isInvalid={invalid}>
                    <WalletPicker value={value} onChange={onChange} />
                    <FormControl.ErrorMessage>
                      {error?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                )}
              />
            </HStack>
            {watch("category").type === "transfer" && (
              <HStack w="100%" direction={"row-reverse"}>
                <Controller
                  name={"toWalletId"}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { invalid, error },
                  }) => (
                    <FormControl isRequired w="1/2" isInvalid={invalid}>
                      <WalletPicker
                        value={value}
                        onChange={onChange}
                        placeHolder={t(TranslationKeys.ToWallet) as string}
                      />
                      <FormControl.ErrorMessage>
                        {error?.message}
                      </FormControl.ErrorMessage>
                    </FormControl>
                  )}
                />
              </HStack>
            )}
            <Controller
              name={"title"}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormControl isRequired w="100%" isInvalid={invalid}>
                  <Input
                    value={value}
                    onChangeText={onChange}
                    placeholder={t(TranslationKeys.Title) as string}
                    size="lg"
                    borderRadius={"full"}
                    InputLeftElement={
                      <Icon
                        as={MaterialIcons}
                        name="description"
                        size="sm"
                        ml="10px"
                        textAlign="center"
                      />
                    }
                  />
                  <FormControl.ErrorMessage>
                    {error?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            />

            <Center w="100%">
              {mode === "add" && (
                <HStack space={5} w={"100%"}>
                  <Button.Group isAttached w={"100%"} borderRadius={"full"}>
                    <Button
                      colorScheme={addButtonColorScheme}
                      leftIcon={
                        <Icon as={Ionicons} name="checkmark" size="lg" />
                      }
                      onPress={handleSubmit(onCreate)}
                      w={"2/3"}
                    >
                      Add
                    </Button>
                    <Button
                      variant="outline"
                      colorScheme={deleteButtonColorScheme}
                      leftIcon={
                        <Icon as={Ionicons} name="close-sharp" size="lg" />
                      }
                      onPress={onModalClose}
                      w={"1/3"}
                    >
                      Close
                    </Button>
                  </Button.Group>
                </HStack>
              )}
              {mode === "edit" && (
                <HStack space={5}>
                  <Button.Group isAttached w={"100%"} borderRadius={"full"}>
                    <Button
                      colorScheme={editButtonColorScheme}
                      leftIcon={<Icon as={Ionicons} name="pencil" size="lg" />}
                      onPress={handleSubmit(onEdit)}
                      w={"1/3"}
                    >
                      Edit
                    </Button>
                    <Button
                      colorScheme={deleteButtonColorScheme}
                      leftIcon={<Icon as={Ionicons} name="trash" size="lg" />}
                      onPress={handleSubmit(onDelete)}
                      w={"1/3"}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="outline"
                      colorScheme={deleteButtonColorScheme}
                      leftIcon={
                        <Icon as={Ionicons} name="close-sharp" size="lg" />
                      }
                      onPress={onModalClose}
                      w={"1/3"}
                    >
                      Close
                    </Button>
                  </Button.Group>
                </HStack>
              )}
            </Center>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

const areEqual = (
  prevProps: TransactionModalProps,
  nextProps: TransactionModalProps
) => {
  if (prevProps.open !== nextProps.open) {
    return false;
  }
  if (prevProps.transaction?.id !== nextProps.transaction?.id) {
    return false;
  }
  return true;
};

export default React.memo(TransactionModal, areEqual);
