import {
  Actionsheet,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Modal,
  Pressable,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import * as React from "react";
import { Ionicons } from "@expo/vector-icons";
import { getImage } from "../utils/getImage";
import useGetWallets from "../hooks/useGetWallets";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";

type WalletPickerProps = {
  value: string;
  onChange: (value: string) => void;
  placeHolder?: string;
};

function WalletPicker(props: WalletPickerProps) {
  const { value, onChange, placeHolder } = props;
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation();

  const { data } = useGetWallets();

  const selectedWallet = data?.find((wallet) => wallet.id === value);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value: string) => () => {
    onChange(value);
    handleClose();
  };

  const bgColor = useColorModeValue("white", "dark.700");

  return (
    <>
      <Modal
        isOpen={open}
        onClose={handleClose}
        animationPreset="slide"
        avoidKeyboard={true}
      >
        <Modal.Content borderRadius="3xl" bg={bgColor} h="60%">
          <Modal.CloseButton />
          <Modal.Body>
            <VStack>
              {data?.map((wallet) => (
                <Pressable key={wallet.id} onPress={handleSelect(wallet.id)}>
                  <Flex
                    alignItems="center"
                    w="100%"
                    py="10px"
                    bg={bgColor}
                    direction="row"
                  >
                    <Avatar
                      bg="green.500"
                      source={getImage(wallet.bank)}
                      size="sm"
                    ></Avatar>
                    <Text pl="10px">{wallet.title}</Text>
                  </Flex>
                </Pressable>
              ))}
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Input
        w="100%"
        value={selectedWallet ? selectedWallet.title : ""}
        placeholder={placeHolder || (t(TranslationKeys.Wallet) as string)}
        size="lg"
        borderRadius={"full"}
        InputLeftElement={
          selectedWallet ? (
            <Avatar
              bg="green.500"
              source={getImage(selectedWallet.bank)}
              size="xs"
              ml="10px"
            ></Avatar>
          ) : (
            <Icon as={Ionicons} name="wallet" size="sm" ml="10px" />
          )
        }
        isReadOnly
        onPressOut={() => handleOpen()}
      />
      <Pressable
        onPress={() => handleOpen()}
        h="40px"
        w="100%"
        position="relative"
        mt="-40px"
      ></Pressable>
    </>
  );
}

const areEqual = (
  prevProps: WalletPickerProps,
  nextProps: WalletPickerProps
) => {
  if (prevProps.value !== nextProps.value) {
    return false;
  }
  if (prevProps.placeHolder !== nextProps.placeHolder) {
    return false;
  }
  return true;
};

export default React.memo(WalletPicker, areEqual);
