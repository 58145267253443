import {
  AlertDialog,
  Button,
  HStack,
  Icon,
  ScrollView,
  Switch,
  Text,
  useColorMode,
  useColorModeValue,
  VStack,
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeColorMode,
  updateAnimation,
  updateLanguage,
} from "../store/slices/settingsSlice";
import { logout } from "../store/slices/authSlice";
import Emoji from "./Emoji";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";
import { RootState } from "../store/store";

export function SettingsMenu(props: any) {
  const { navigation } = props;

  const { t } = useTranslation();
  const animation = useSelector((state: RootState) => state.settings.animation);

  const { setColorMode } = useColorMode();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef(null);

  const onColorPress = (mode: "light" | "dark") => {
    setColorMode(mode);
    dispatch(changeColorMode(mode));
  };

  const onLanguagePress = (language: string) => {
    dispatch(updateLanguage(language));
  };

  const onAnimationUpdate = (value: boolean) => {
    dispatch(updateAnimation(value));
  };

  return (
    <ScrollView
      flex={1}
      w="100%"
      showsVerticalScrollIndicator={false}
      _contentContainerStyle={{
        px: "60px",
        pt: "220px",
        pb: "100px",
      }}
    >
      <VStack w="100%" space={5}>
        <Button
          onPress={() => navigation.navigate("Categories")}
          size="lg"
          leftIcon={<Icon as={Ionicons} name="pricetags" size="md" />}
          colorScheme={useColorModeValue("primary", "dark")}
          borderRadius={10}
        >
          {t(TranslationKeys.Categories)}
        </Button>
        <Button
          onPress={() => navigation.navigate("Wallets")}
          size="lg"
          leftIcon={<Icon as={Ionicons} name="wallet" size="md" />}
          colorScheme={useColorModeValue("primary", "dark")}
          borderRadius={10}
        >
          {t(TranslationKeys.Wallets)}
        </Button>
        <Button.Group
          isAttached
          colorScheme={useColorModeValue("primary", "dark")}
          size="lg"
          w="100%"
          borderRadius={10}
        >
          <Button
            w="50%"
            variant={useColorModeValue("solid", "outline")}
            leftIcon={<Icon as={Ionicons} name="sunny" size="md" />}
            onPress={() => onColorPress("light")}
          >
            {t(TranslationKeys.Light)}
          </Button>
          <Button
            w="50%"
            variant={useColorModeValue("outline", "solid")}
            leftIcon={<Icon as={Ionicons} name="moon" size="md" />}
            onPress={() => onColorPress("dark")}
          >
            {t(TranslationKeys.Dark)}
          </Button>
        </Button.Group>
        <Button.Group
          colorScheme={useColorModeValue("primary", "dark")}
          size="lg"
          w="100%"
          borderRadius={10}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <Button w="14%" onPress={() => onLanguagePress("en")}>
            <Emoji name="flag_England" />
          </Button>
          <Button w="14%" onPress={() => onLanguagePress("es")}>
            <Emoji name="flag_Spain" />
          </Button>
          <Button w="14%" onPress={() => onLanguagePress("fr")}>
            <Emoji name="flag_France" />
          </Button>
          <Button w="14%" onPress={() => onLanguagePress("de")}>
            <Emoji name="flag_Germany" />
          </Button>
          <Button w="14%" onPress={() => onLanguagePress("ar")}>
            <Emoji name="flag_Saudi_Arabia" />
          </Button>
          <Button w="14%" onPress={() => onLanguagePress("fa")}>
            <Emoji name="flag_Iran" />
          </Button>
        </Button.Group>
        <HStack alignItems="center" space={4}>
          <Text>{t(TranslationKeys.Animations)}</Text>
          <Switch
            size="sm"
            value={animation}
            onValueChange={(value) => onAnimationUpdate(value)}
          />
        </HStack>
        <Button
          onPress={() => setIsOpen(!isOpen)}
          size="lg"
          leftIcon={<Icon as={Ionicons} name="log-out" size="md" />}
          colorScheme={useColorModeValue("primary", "dark")}
          borderRadius={10}
        >
          {t(TranslationKeys.SignOut)}
        </Button>
        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={isOpen}
          onClose={onClose}
        >
          <AlertDialog.Content>
            <AlertDialog.CloseButton />
            <AlertDialog.Header>
              {t(TranslationKeys.SignOut)}
            </AlertDialog.Header>
            <AlertDialog.Body>
              Are you sure you want to sign out?
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button.Group space={2}>
                <Button
                  variant="unstyled"
                  colorScheme="coolGray"
                  onPress={onClose}
                  ref={cancelRef}
                >
                  Cancel
                </Button>
                <Button colorScheme="danger" onPress={() => dispatch(logout())}>
                  {t(TranslationKeys.SignOut)}
                </Button>
              </Button.Group>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      </VStack>
    </ScrollView>
  );
}
