import React from "react";
import { useColorModeValue, View } from "native-base";
import { HeaderCard } from "../components/HeaderCard";
import { Reminders } from "../components/Reminders";
import { RemindersOverview } from "../components/RemindersOverview";
import { useSharedValue } from "react-native-reanimated";

export default function RemindersScreen() {
  const scrollValue = useSharedValue(0);
  const onScroll = (value: number) => {
    scrollValue.value = value;
  };

  return (
    <View bg={useColorModeValue("tertiary.50", "tertiary.900")} flex={1}>
      <HeaderCard scrollValue={scrollValue} content={<RemindersOverview />} />
      <Reminders onScroll={onScroll} />
    </View>
  );
}
