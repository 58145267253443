import * as React from "react";
import { Avatar, Badge, Center, HStack, Text, VStack } from "native-base";
import useGetMe from "../hooks/useGetMe";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";

export function Profile() {
  const { t } = useTranslation();
  const { data } = useGetMe();

  return (
    <VStack w="100%" px="20px" py="30px">
      <HStack>
        <Center>
          <Avatar
            bg="green.500"
            source={{
              uri: data?.avatar,
            }}
            size="xl"
          >
            {data?.name[0]}
          </Avatar>
        </Center>
        <VStack pl="20px" alignItems="flex-start" justifyContent="space-evenly">
          <Text fontSize="xl">{data?.name}</Text>
          <Text>{data?.email}</Text>
          <Badge colorScheme="amber" borderRadius={5}>
            {t(TranslationKeys.GoldMembership)}
          </Badge>
        </VStack>
      </HStack>
    </VStack>
  );
}
