import * as React from "react";
import {
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  Icon,
  Input,
  Link,
  Text,
  VStack,
} from "native-base";
import { Controller, useForm } from "react-hook-form";
import { NavigationProp } from "@react-navigation/native";
import * as WebBrowser from "expo-web-browser";
import { FontAwesome5 } from "@expo/vector-icons";
import * as Google from "expo-auth-session/providers/google";
import Constants from "expo-constants";
import { useDispatch } from "react-redux";
import {
  authenticate,
  authenticateWithGoogle,
} from "../store/slices/authSlice";
import { AuthenticateDto } from "../hooks/types";
import { AnyAction } from "@reduxjs/toolkit";

type LoginFormProps = {
  navigation: NavigationProp<any>;
};

WebBrowser.maybeCompleteAuthSession();

export function LoginForm({ navigation }: LoginFormProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    ...Constants.manifest?.extra?.google,
  });

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (response?.type === "success") {
      dispatch(
        authenticateWithGoogle({
          idToken: response.params.id_token,
        }) as unknown as AnyAction
      ).catch(() => {
        setError("password", {
          type: "custom",
          message: "Provided email/password is incorrect",
        });
      });
    }
  }, [response]);

  const { handleSubmit, control, setError } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = (data: AuthenticateDto) => {
    setIsLoading(true);
    dispatch(authenticate(data) as unknown as AnyAction)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setError("password", {
          type: "custom",
          message: "Provided email/password is incorrect",
        });
      });
  };

  return (
    <Center px={10} flex={1}>
      <VStack w="100%" space={5}>
        <Flex w="100%">
          <Controller
            name={"email"}
            control={control}
            rules={{
              required: "Email is required",
              minLength: 3,
              pattern: {
                value:
                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                message: "Email is not valid",
              },
            }}
            render={({
              field: { onChange, value },
              fieldState: { invalid, error },
            }) => (
              <FormControl isRequired w="100%" isInvalid={invalid}>
                <FormControl.Label>Email</FormControl.Label>
                <Input
                  value={value}
                  onChangeText={(value) => onChange(value)}
                  placeholder="john@example.com"
                  keyboardType="email-address"
                  type="text"
                />
                <FormControl.ErrorMessage>
                  {error?.message}
                </FormControl.ErrorMessage>
              </FormControl>
            )}
          />
        </Flex>
        <Flex w="100%">
          <Controller
            name={"password"}
            control={control}
            rules={{
              required: "Password is required",
              minLength: 3,
            }}
            render={({
              field: { onChange, value },
              fieldState: { invalid, error },
            }) => (
              <FormControl isRequired w="100%" isInvalid={invalid}>
                <FormControl.Label>Password</FormControl.Label>
                <Input
                  value={value}
                  onChangeText={(value) => onChange(value)}
                  type="password"
                />
                <FormControl.ErrorMessage>
                  {error?.message}
                </FormControl.ErrorMessage>
              </FormControl>
            )}
          />
        </Flex>
        <Flex w="100%">
          <Button onPress={handleSubmit(onSubmit)} isLoading={isLoading}>
            Sign in
          </Button>
        </Flex>
        <Flex w="100%" justifyContent="center" direction="row">
          <Text fontSize="sm">I'm a new user. </Text>
          <Link onPress={() => navigation.navigate("Register")}>Sign up</Link>
        </Flex>
      </VStack>
      <Flex direction="row" w="100%" justifyContent="center" py="4">
        <Divider flex={1} mt="3" />
        <Text w="50px" textAlign="center">
          Or
        </Text>
        <Divider flex={1} mt="3" />
      </Flex>
      <Flex w="100%">
        <Button
          leftIcon={<Icon as={FontAwesome5} name="google" size="sm" />}
          disabled={!request}
          onPress={() => {
            promptAsync();
          }}
        >
          Sign in with Google
        </Button>
      </Flex>
    </Center>
  );
}
