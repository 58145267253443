import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReminderDto, UpdateReminderDto } from "./types";
import { api } from "./api";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";

export default function useUpdateReminder() {
  const token = useSelector((state: RootState) => state.auth.token);
  const accountId = useSelector((state: RootState) => state.auth.accountId);
  const queryClient = useQueryClient();

  const query = async (input: UpdateReminderDto) => {
    const result = await api.put(
      `accounts/${accountId}/reminders/${input.id}`,
      input,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return result.data;
  };
  return useMutation<ReminderDto, unknown, UpdateReminderDto>(
    ["updateReminder"],
    query,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["reminders"],
        });
        queryClient.invalidateQueries({
          queryKey: ["remindersOverview"],
        });
      },
    }
  );
}
