import { Box, Center, Flex, useColorModeValue, useTheme } from "native-base";
import * as React from "react";
import Animated, {
  SharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";

type HeaderCardProps = {
  header?: JSX.Element;
  content?: JSX.Element;
  scrollValue: SharedValue<number>;
};

export function HeaderCard(props: HeaderCardProps) {
  const { header, content, scrollValue } = props;

  const { colors } = useTheme();

  const animatedStyles = useAnimatedStyle(() => {
    return {
      marginTop: Math.max(
        Math.min(-930 - (scrollValue?.value ?? 0) / 5, -920),
        -980
      ),
    };
  });

  const contentContainerAnimatedStyles = useAnimatedStyle(() => {
    return {
      marginTop: Math.max(
        Math.min(-70 + (scrollValue?.value ?? 0) / 5, -20),
        -70
      ),
    };
  });

  const contentBoxAnimatedStyles = useAnimatedStyle(() => {
    return {
      width: `${Math.max(
        Math.min(90 + (scrollValue?.value ?? 0) / 10, 100),
        80
      )}%`,
      borderRadius: Math.max(
        Math.min(25 - (scrollValue?.value ?? 0) / 5, 30),
        0
      ),
    };
  });

  return (
    <Box
      w="100%"
      overflow="hidden"
      paddingBottom="40px"
      position="absolute"
      zIndex={1}
    >
      <Box
        w="100%"
        bg={useColorModeValue("primary.800", "dark.800")}
        position="relative"
        zIndex="101"
        py="5px"
      >
        <Flex w="100%" px="20px" direction="row">
          {header}
        </Flex>
      </Box>
      <Animated.View
        style={[
          {
            display: "flex",
            width: "250%",
            height: 1000,
            backgroundColor:
              useColorModeValue("light", "dark") === "light"
                ? colors.primary[800]
                : colors.dark[800],
            borderRadius: 1000,
            position: "relative",
            marginTop: -930,
            marginLeft: "-75%",
            zIndex: 100,
          },
          animatedStyles,
        ]}
      ></Animated.View>
      {content && (
        <Animated.View
          style={[
            {
              display: "flex",
              width: "100%",
              position: "relative",
              zIndex: 102,
              alignItems: "center",
            },
            contentContainerAnimatedStyles,
          ]}
        >
          <Animated.View
            style={[
              {
                borderBottomRightRadius: 25,
                borderBottomLeftRadius: 25,
                backgroundColor:
                  useColorModeValue("light", "dark") === "light"
                    ? colors.white
                    : colors.dark[700],
                shadowColor: "#000",
                shadowOpacity: 0.2,
                shadowRadius: 10,
                shadowOffset: {
                  height: 10,
                  width: 0,
                },
              },
              contentBoxAnimatedStyles,
            ]}
          >
            {content}
          </Animated.View>
        </Animated.View>
        // <Center w="100%" zIndex="102" marginTop="-90px" position="relative">

        // </Center>
      )}
    </Box>
  );
}
