import { Flex } from "native-base";
import * as React from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryStack,
  VictoryTheme,
} from "victory-native";
import { Dimensions } from "react-native";
import { CategoriesOverviewItemDto } from "../hooks/types";

type CategoryOverviewItemChartProps = {
  date: any;
  color: string;
  open: boolean;
  item: CategoriesOverviewItemDto;
};

export function CategoryOverviewItemChart(
  props: CategoryOverviewItemChartProps
) {
  const { color, item, date, open } = props;
  const screenWidth = Dimensions.get("window").width;

  let max = 0;
  for (const chartItem of item.chartItems) {
    max = Math.max(chartItem.value, max);
  }
  const placeHolderItems = item.chartItems.map((chartItem) => ({
    ...chartItem,
    value: Math.min(max, item.budget?.limit ?? 999999999),
  }));
  const budgetPlaceHolderItems = item.chartItems.map((chartItem) => ({
    ...chartItem,
    value: max - Math.min(max, item.budget?.limit ?? 999999999),
  }));

  return (
    <Flex
      w="100%"
      height={open ? "150px" : "30px"}
      alignItems="center"
      direction="row"
      position="relative"
      top={open ? "10px" : "0px"}
    >
      <VictoryChart
        height={open ? 240 : 140}
        width={screenWidth - 65}
        containerComponent={
          <VictoryContainer
            responsive={false}
            style={{
              pointerEvents: "auto",
              userSelect: "auto",
              touchAction: "auto",
            }}
          />
        }
        padding={{ left: 65, top: 50, bottom: 50, right: 70 }}
      >
        <VictoryStack>
          <VictoryBar
            cornerRadius={{
              top: item.budget?.limit ? 0 : 5,
              bottom: 5,
            }}
            alignment="middle"
            barRatio={1}
            data={placeHolderItems}
            style={{
              data: {
                fill: `${color}10`,
              },
            }}
            y="value"
            x="key"
            sortKey={"key"}
            sortOrder={"ascending"}
          />
          <VictoryBar
            cornerRadius={{
              top: 5,
              bottom: item.budget?.limit ? 0 : 5,
            }}
            alignment="middle"
            barRatio={1}
            data={budgetPlaceHolderItems}
            style={{
              data: {
                fill: `${color}30`,
              },
            }}
            y="value"
            x="key"
            sortKey={"key"}
            sortOrder={"ascending"}
          />
        </VictoryStack>
        <VictoryBar
          cornerRadius={{ top: 5, bottom: 5 }}
          alignment="middle"
          barRatio={1}
          data={item.chartItems}
          style={{
            data: {
              fill: ({ data, index }: any) => {
                return data[index].key ===
                  `${date.year}-${
                    date.month + 1 < 10 ? `0${date.month + 1}` : date.month + 1
                  }`
                  ? `${color}`
                  : `${color}80`;
              },
            },
          }}
          y="value"
          x="key"
          sortKey={"key"}
          sortOrder={"ascending"}
        />
        {!open && (
          <VictoryAxis
            style={{
              axis: { stroke: "transparent" },
              ticks: { stroke: "transparent" },
              tickLabels: { fill: "transparent" },
              grid: { stroke: "transparent" },
            }}
          />
        )}
        {open && (
          <VictoryAxis
            crossAxis
            dependentAxis
            style={{
              axis: { stroke: `transparent` },
              tickLabels: { fill: `${color}80` },
              grid: { stroke: `${color}80`, strokeDasharray: 4 },
            }}
            theme={VictoryTheme.material}
            tickFormat={(t) => `$${t}`}
          />
        )}
      </VictoryChart>
    </Flex>
  );
}
