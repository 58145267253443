import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { Ionicons } from "@expo/vector-icons";
import {
  Button,
  Center,
  Flex,
  FormControl,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  Select,
  Spacer,
  Switch,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CompanyPicker from "./CompanyPicker";
import { ReminderDto, ReminderType } from "../hooks/types";
import useCreateReminder from "../hooks/useCreateReminder";
import useUpdateReminder from "../hooks/useUpdateReminder";
import useDeleteReminder from "../hooks/useDeleteReminder";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";

type ReminderModalProps = {
  open: boolean;
  handleClose: () => void;
  reminder?: ReminderDto;
};

function ReminderModal(props: ReminderModalProps) {
  const { open, handleClose, reminder } = props;

  const { t } = useTranslation();

  const mode = reminder ? "edit" : "add";

  const days: { value: string; label: string }[] = [];
  for (let i = 1; i < 29; i += 1) {
    days.push({ value: `${i}`, label: moment().date(i).format("Do") });
  }

  const weekDays: { value: string; label: string }[] = [];
  for (let i = 0; i < 7; i += 1) {
    weekDays.push({ value: `${i + 1}`, label: moment().day(i).format("ddd") });
  }

  const months: { value: string; label: string }[] = [];
  for (let i = 0; i < 12; i += 1) {
    months.push({ value: `${i + 1}`, label: moment().month(i).format("MMM") });
  }

  const schema = yup
    .object({
      type: yup
        .string()
        .oneOf(Object.values(ReminderType))
        .required()
        .label("Type"),
      title: yup.string().min(3).required().label("Title"),
      amount: yup
        .number()
        .typeError("Amount must be a number")
        .positive()
        .required()
        .label("Amount"),
      recurring: yup.boolean().required().label("Recurring"),
      recurringType: yup.string().required().label("Recurring"),
      recurringValue: yup.string().required().label("Recurring"),
      reminder: yup.boolean().required().label("Reminder"),
      reminderType: yup.string().required().label("Reminder"),
      reminderValue: yup
        .number()
        .typeError("Reminder must be a number")
        .positive()
        .label("Reminder"),
      thirdParty: yup
        .object({
          name: yup.string().min(3).required().label("Name"),
          image: yup.string().min(3).required().label("Image"),
          domain: yup.string().min(3).nullable().label("Domain"),
        })
        .required()
        .label("Third Party"),
    })
    .required();

  const { handleSubmit, control, setValue, reset, watch, formState } =
    useForm<any>({
      resolver: yupResolver(schema),
      defaultValues: {
        recurring: false,
        recurringType: "month",
        recurringValue: "1",
        reminder: false,
        reminderType: "day",
        reminderValue: 1,
      },
    });

  React.useEffect(() => {
    if (reminder) {
      setValue("id", reminder.id);
      setValue("type", reminder.type);
      setValue("title", reminder.title);
      setValue("amount", `${Math.abs(reminder.amount)}`);
      setValue("recurring", reminder.recurring ?? false);
      setValue(
        "recurringValue",
        `${
          reminder.recurringWeek ??
          reminder.recurringMonth ??
          moment()
            .dayOfYear(reminder.recurringYear ?? 1)
            .format("M-D")
        }`
      );
      setValue(
        "recurringType",
        reminder.recurringWeek
          ? "week"
          : reminder.recurringMonth
          ? "month"
          : "year"
      );
      setValue("reminder", reminder.reminder ?? false);
      setValue(
        "reminderValue",
        reminder.reminderDay ?? reminder.reminderWeek ?? reminder.reminderMonth
      );
      setValue(
        "reminderType",
        reminder.reminderDay ? "day" : reminder.reminderWeek ? "week" : "month"
      );
      setValue("thirdParty", reminder.thirdParty);
    }
  }, [setValue, reminder]);

  const { mutateAsync: createReminder } = useCreateReminder();

  const onCreate = (data: any) => {
    createReminder({
      type: data.type,
      title: data.title,
      amount: parseFloat(data.amount),
      recurring: data.recurring,
      recurringWeek:
        data.recurringType === "week"
          ? parseInt(data.recurringValue)
          : undefined,
      recurringMonth:
        data.recurringType === "month"
          ? parseInt(data.recurringValue)
          : undefined,
      recurringYear:
        data.recurringType === "year"
          ? moment(data.recurringValue, "M-D").dayOfYear()
          : undefined,
      reminder: data.reminder,
      reminderDay: data.reminderType === "day" ? data.reminderValue : undefined,
      reminderWeek:
        data.reminderType === "week" ? data.reminderValue : undefined,
      reminderMonth:
        data.reminderType === "month" ? data.reminderValue : undefined,
      thirdParty: {
        name: data.thirdParty.name,
        image: data.thirdParty.image,
        domain: data.thirdParty.domain,
      },
    })
      .then(() => {
        reset();
        handleClose();
      })
      .catch((error) => {});
  };

  const { mutateAsync: updateReminder } = useUpdateReminder();

  const onEdit = (data: any) => {
    updateReminder({
      id: data.id,
      type: data.type,
      title: data.title,
      amount: parseFloat(data.amount),
      recurring: data.recurring,
      recurringWeek:
        data.recurringType === "week"
          ? parseInt(data.recurringValue)
          : undefined,
      recurringMonth:
        data.recurringType === "month"
          ? parseInt(data.recurringValue)
          : undefined,
      recurringYear:
        data.recurringType === "year"
          ? moment(data.recurringValue, "M-D").dayOfYear()
          : undefined,
      reminder: data.reminder,
      reminderDay: data.reminderType === "day" ? data.reminderValue : undefined,
      reminderWeek:
        data.reminderType === "week" ? data.reminderValue : undefined,
      reminderMonth:
        data.reminderType === "month" ? data.reminderValue : undefined,
      thirdParty: {
        name: data.thirdParty.name,
        image: data.thirdParty.image,
        domain: data.thirdParty.domain,
      },
    })
      .then(() => {
        reset();
        handleClose();
      })
      .catch((error) => {});
  };

  const { mutateAsync: deleteReminder } = useDeleteReminder();

  const onDelete = (data: any) =>
    deleteReminder(data.id)
      .then(() => {
        reset();
        handleClose();
      })
      .catch((error) => {});

  const onModalClose = () => {
    handleClose();
    reset();
  };

  const addButtonColorScheme = useColorModeValue("green", "dark");
  const deleteButtonColorScheme = useColorModeValue("red", "dark");
  const deleteButtonVariant = useColorModeValue("solid", "outline");
  const editButtonColorScheme = useColorModeValue("blue", "dark");

  return (
    <Modal
      isOpen={open}
      onClose={onModalClose}
      size="xl"
      animationPreset="slide"
      avoidKeyboard={true}
    >
      <Modal.Content
        borderRadius="3xl"
        bg={useColorModeValue("white", "dark.700")}
      >
        <Modal.Header
          borderBottomWidth={0}
          bg={useColorModeValue("white", "dark.700")}
          alignItems={"center"}
        >
          <Heading size="md">{t(TranslationKeys.Reminder)}</Heading>
        </Modal.Header>
        <Modal.Body>
          <VStack w="100%" space={5}>
            <Controller
              name={"type"}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormControl isRequired w="100%" isInvalid={invalid}>
                  <Select
                    placeholder={t(TranslationKeys.Type) as string}
                    selectedValue={value}
                    onValueChange={onChange}
                    size="lg"
                    borderRadius={"full"}
                  >
                    {Object.keys(ReminderType).map((key) => (
                      <Select.Item
                        key={key}
                        label={key}
                        value={ReminderType[key as keyof typeof ReminderType]}
                      />
                    ))}
                  </Select>
                  <FormControl.ErrorMessage>
                    {error?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            />
            <Controller
              name={"title"}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormControl isRequired w="100%" isInvalid={invalid}>
                  <Input
                    value={value}
                    onChangeText={onChange}
                    placeholder={t(TranslationKeys.Title) as string}
                    size="lg"
                    borderRadius={"full"}
                    InputLeftElement={
                      <Icon
                        as={MaterialIcons}
                        name="description"
                        size="sm"
                        ml="10px"
                        textAlign="center"
                      />
                    }
                  />
                  <FormControl.ErrorMessage>
                    {error?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            />
            <Controller
              name={"amount"}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormControl isRequired w="100%" isInvalid={invalid}>
                  <Input
                    value={value}
                    onChangeText={onChange}
                    placeholder={t(TranslationKeys.Amount) as string}
                    keyboardType="decimal-pad"
                    size="lg"
                    borderRadius={"full"}
                    InputLeftElement={
                      <Icon
                        as={FontAwesome}
                        name="dollar"
                        size="sm"
                        ml="10px"
                        textAlign="center"
                      />
                    }
                  />
                  <FormControl.ErrorMessage>
                    {error?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            />
            <Flex
              w="100%"
              borderWidth={1}
              borderColor={useColorModeValue("muted.200", "muted.500")}
              borderRadius={watch("recurring") === true ? "22px" : "full"}
              px={3}
            >
              <Controller
                name={"recurring"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl isRequired w="100%" isInvalid={invalid}>
                    <Flex w="100%" direction="row" alignItems="center">
                      <Icon as={Ionicons} name="refresh" size="md" />
                      <Text fontSize="md" ml={1} py={2}>
                        {t(TranslationKeys.Recurring) as string}
                      </Text>
                      <Spacer />
                      <Switch
                        size="md"
                        onValueChange={onChange}
                        value={value}
                      />
                    </Flex>
                    <FormControl.ErrorMessage>
                      {error?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                )}
              />
              {watch("recurring") === true && (
                <FormControl
                  isRequired
                  w="100%"
                  isInvalid={formState.errors.reminderValue !== undefined}
                >
                  <Flex
                    w="100%"
                    direction="row"
                    alignItems="center"
                    flexWrap="wrap"
                    pb={2}
                  >
                    <Controller
                      name={"recurringType"}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          size="lg"
                          variant="underlined"
                          selectedValue={value}
                          onValueChange={(v) => {
                            if (v === "week") setValue("recurringValue", "1");
                            if (v === "month") setValue("recurringValue", "1");
                            if (v === "year") setValue("recurringValue", "1-1");
                            onChange(v);
                          }}
                          width="80px"
                          textAlign="center"
                          dropdownIcon={<></>}
                        >
                          <Select.Item
                            value="week"
                            label={t(TranslationKeys.Weekly) as string}
                          />
                          <Select.Item
                            value="month"
                            label={t(TranslationKeys.Monthly) as string}
                          />
                          <Select.Item
                            value="year"
                            label={t(TranslationKeys.Yearly) as string}
                          />
                        </Select>
                      )}
                    />
                    <Text>{t(TranslationKeys.On) as string}</Text>
                    <Controller
                      name={"recurringValue"}
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        const type = watch("recurringType");
                        return (
                          <>
                            <Select
                              size="lg"
                              variant="underlined"
                              selectedValue={value}
                              onValueChange={onChange}
                              width="70px"
                              textAlign="center"
                              display={type === "week" ? "flex" : "none"}
                              dropdownIcon={<></>}
                            >
                              {weekDays.map((day) => (
                                <Select.Item
                                  key={day.value}
                                  value={day.value}
                                  label={t(day.label)}
                                />
                              ))}
                            </Select>
                            <Select
                              size="lg"
                              variant="underlined"
                              selectedValue={value}
                              onValueChange={onChange}
                              width="70px"
                              textAlign="center"
                              display={type === "month" ? "flex" : "none"}
                              dropdownIcon={<></>}
                            >
                              {days.map((day) => (
                                <Select.Item
                                  key={day.value}
                                  value={day.value}
                                  label={t(day.label)}
                                />
                              ))}
                            </Select>
                            <Select
                              size="lg"
                              variant="underlined"
                              selectedValue={value.split("-")[0]}
                              onValueChange={(v) =>
                                onChange(`${v}-${value.split("-")[1]}`)
                              }
                              width="60px"
                              textAlign="center"
                              display={type === "year" ? "flex" : "none"}
                              dropdownIcon={<></>}
                            >
                              {months.map((day) => (
                                <Select.Item
                                  key={day.value}
                                  value={day.value}
                                  label={t(day.label)}
                                />
                              ))}
                            </Select>
                            <Select
                              size="lg"
                              variant="underlined"
                              selectedValue={value.split("-")[1]}
                              onValueChange={(v) =>
                                onChange(`${value.split("-")[0]}-${v}`)
                              }
                              width="70px"
                              ml="5px"
                              textAlign="center"
                              display={type === "year" ? "flex" : "none"}
                              dropdownIcon={<></>}
                            >
                              {days.map((day) => (
                                <Select.Item
                                  key={day.value}
                                  value={day.value}
                                  label={t(day.label)}
                                />
                              ))}
                            </Select>
                          </>
                        );
                      }}
                    />
                  </Flex>
                </FormControl>
              )}
            </Flex>

            <Flex
              w="100%"
              borderWidth={1}
              borderColor={useColorModeValue("muted.200", "muted.500")}
              borderRadius={watch("recurring") === true ? "22px" : "full"}
              px={3}
            >
              <Controller
                name={"reminder"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl isRequired w="100%" isInvalid={invalid}>
                    <Flex w="100%" direction="row" alignItems="center">
                      <Icon as={Ionicons} name="notifications" size="md" />
                      <Text fontSize="md" ml={1} py={2}>
                        {t(TranslationKeys.Notification) as string}
                      </Text>
                      <Spacer />
                      <Switch
                        size="md"
                        onValueChange={onChange}
                        value={value}
                      />
                    </Flex>
                    <FormControl.ErrorMessage>
                      {error?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                )}
              />
              {watch("reminder") === true && (
                <FormControl
                  isRequired
                  w="100%"
                  isInvalid={formState.errors.reminderValue !== undefined}
                >
                  <Flex
                    w="100%"
                    direction="row"
                    alignItems="center"
                    flexWrap="wrap"
                    pb={2}
                  >
                    <Text>{t(TranslationKeys.RemindMe) as string}</Text>
                    <Controller
                      name={"reminderValue"}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          value={`${value}`}
                          onChangeText={onChange}
                          placeholder="1"
                          textAlign="center"
                          size="lg"
                          width="60px"
                          variant="underlined"
                        />
                      )}
                    />
                    <Controller
                      name={"reminderType"}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          size="lg"
                          variant="underlined"
                          selectedValue={value}
                          onValueChange={onChange}
                          ml="5px"
                          width="70px"
                          textAlign="center"
                          dropdownIcon={<></>}
                        >
                          <Select.Item
                            value="day"
                            label={
                              watch("reminderValue", 1) < 2
                                ? t(TranslationKeys.Day)
                                : t(TranslationKeys.Days)
                            }
                          />
                          <Select.Item
                            value="week"
                            label={
                              watch("reminderValue", 1) < 2
                                ? t(TranslationKeys.Week)
                                : t(TranslationKeys.Weeks)
                            }
                          />
                          <Select.Item
                            value="month"
                            label={
                              watch("reminderValue", 1) < 2
                                ? t(TranslationKeys.Month)
                                : t(TranslationKeys.Months)
                            }
                          />
                        </Select>
                      )}
                    />

                    <Text>{t(TranslationKeys.Before) as string}</Text>
                  </Flex>
                </FormControl>
              )}
            </Flex>

            <Controller
              name={"thirdParty"}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormControl isRequired w="100%" isInvalid={invalid}>
                  <CompanyPicker value={value} onChange={onChange} />
                  <FormControl.ErrorMessage>
                    Company is required
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            />
            <Center w="100%">
              {mode === "add" && (
                <HStack space={5} w={"100%"}>
                  <Button.Group isAttached w={"100%"} borderRadius={"full"}>
                    <Button
                      colorScheme={addButtonColorScheme}
                      leftIcon={
                        <Icon as={Ionicons} name="checkmark" size="lg" />
                      }
                      onPress={handleSubmit(onCreate)}
                      w={"2/3"}
                    >
                      Add
                    </Button>
                    <Button
                      variant="outline"
                      colorScheme={deleteButtonColorScheme}
                      leftIcon={
                        <Icon as={Ionicons} name="close-sharp" size="lg" />
                      }
                      onPress={onModalClose}
                      w={"1/3"}
                    >
                      Close
                    </Button>
                  </Button.Group>
                </HStack>
              )}
              {mode === "edit" && (
                <HStack space={5}>
                  <Button.Group isAttached w={"100%"} borderRadius={"full"}>
                    <Button
                      colorScheme={editButtonColorScheme}
                      leftIcon={<Icon as={Ionicons} name="pencil" size="lg" />}
                      onPress={handleSubmit(onEdit)}
                      w={"1/3"}
                    >
                      Edit
                    </Button>
                    <Button
                      colorScheme={deleteButtonColorScheme}
                      leftIcon={<Icon as={Ionicons} name="trash" size="lg" />}
                      onPress={handleSubmit(onDelete)}
                      w={"1/3"}
                    >
                      Delete
                    </Button>
                    <Button
                      variant="outline"
                      colorScheme={deleteButtonColorScheme}
                      leftIcon={
                        <Icon as={Ionicons} name="close-sharp" size="lg" />
                      }
                      onPress={onModalClose}
                      w={"1/3"}
                    >
                      Close
                    </Button>
                  </Button.Group>
                </HStack>
              )}
            </Center>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

const areEqual = (
  prevProps: ReminderModalProps,
  nextProps: ReminderModalProps
) => {
  if (prevProps.open !== nextProps.open) {
    return false;
  }
  if (prevProps.reminder?.id !== nextProps.reminder?.id) {
    return false;
  }
  return true;
};

export default React.memo(ReminderModal, areEqual);
