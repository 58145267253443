import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./en";
import { fa } from "./fa";
import { de } from "./de";
import { ar } from "./ar";
import { fr } from "./fr";
import { es } from "./es";

export const localConfigs: Record<string, any> = {
  en: {
    rtl: false,
  },
  fa: {
    rtl: true,
  },
  de: {
    rtl: false,
  },
  ar: {
    rtl: true,
  },
  fr: {
    rtl: false,
  },
  es: {
    rtl: false,
  },
};

const resources = {
  en: {
    translation: en,
  },
  fa: {
    translation: fa,
  },
  de: {
    translation: de,
  },
  ar: {
    translation: ar,
  },
  fr: {
    translation: fr,
  },
  es: {
    translation: es,
  },
};

i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources,
  lng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
