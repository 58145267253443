import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateWalletDto, WalletDto } from "./types";
import { api } from "./api";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";

export default function useCreateWallet() {
  const token = useSelector((state: RootState) => state.auth.token);
  const accountId = useSelector((state: RootState) => state.auth.accountId);
  const queryClient = useQueryClient();

  const query = async (input: CreateWalletDto) => {
    const result = await api.post(`accounts/${accountId}/wallets`, input, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  };
  return useMutation<WalletDto, unknown, CreateWalletDto>(
    ["createWallet"],
    query,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["wallets"],
        });
      },
    }
  );
}
