import * as React from "react";
import moment from "moment";
import { Box, Progress, Text, useColorModeValue, VStack } from "native-base";
import { NumericFormat } from "react-number-format";
import useGetRemindersOverview from "../hooks/useGetRemindersOverview";

function Dot({ item, color, doneColor }: any) {
  const currentDate = moment();
  const dotDate = moment(item.date);
  const left = (dotDate.date() / dotDate.daysInMonth()) * 100;
  const done = dotDate.isSameOrBefore(currentDate, "day");
  return (
    <>
      <Box
        w="2px"
        h="20px"
        borderRadius="full"
        bg={done ? doneColor : color}
        position="absolute"
        top="66px"
        left={`${left}%`}
        ml="-8px"
      />
      <NumericFormat
        value={item.amount}
        displayType={"text"}
        thousandSeparator={true}
        prefix={"$"}
        renderText={(value) => (
          <Text
            position="absolute"
            fontSize={12}
            fontWeight="thin"
            width="60px"
            left={`${left}%`}
            top="22px"
            ml="-50px"
            textAlign="right"
            color={done ? doneColor : color}
            style={{ transform: [{ rotate: "60deg" }] }}
          >
            {value}
          </Text>
        )}
      />
      <Text
        position="absolute"
        fontSize={10}
        width="30px"
        left={`${left}%`}
        top="98px"
        ml="-21px"
        textAlign="left"
        color={done ? doneColor : color}
        style={{ transform: [{ rotate: "90deg" }] }}
      >
        {dotDate.format("Do")}
      </Text>
    </>
  );
}

export function RemindersOverview() {
  const { data } = useGetRemindersOverview();

  const progressBgColor = useColorModeValue("dark.200", "dark.400");
  const dotColor = useColorModeValue("dark.500", "dark.300");
  const dotDoneColor = useColorModeValue("green.700", "green.400");

  const progress = (moment().date() / moment().daysInMonth()) * 100;
  return (
    <VStack w="100%" px="20px" py="10px">
      <Text w="100%" textAlign="center" fontSize="xl">
        {moment().format("MMM YYYY")}
      </Text>
      <Box w="100%" pt="70px" pb="40px">
        <Progress
          value={progress}
          size="md"
          colorScheme="green"
          bg={progressBgColor}
          rounded="2"
        />
        {data?.map((remindersOverviewItem, index) => (
          <Dot
            key={index}
            item={remindersOverviewItem}
            color={dotColor}
            doneColor={dotDoneColor}
          />
        ))}
      </Box>
    </VStack>
  );
}
