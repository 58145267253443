import {
  Box,
  Center,
  Divider,
  Flex,
  Icon,
  IconButton,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import * as React from "react";
import { NumericFormat } from "react-number-format";
import AntDesign from "@expo/vector-icons/AntDesign";
import Emoji from "./Emoji";
import { CategoryOverviewItemChart } from "./CategoryOverviewItemChart";
import { CategoriesOverviewItemDto } from "../hooks/types";

type CategoryOverviewItemProps = {
  date: any;
  color: string;
  item: CategoriesOverviewItemDto;
};

export function CategoryOverviewItem(props: CategoryOverviewItemProps) {
  const { color, item, date } = props;

  const [open, setOpen] = React.useState(false);

  let max = 0;
  for (const chartItem of item.chartItems) {
    max = Math.max(chartItem.value, max);
  }

  return (
    <Box px="20px">
      <VStack
        w="100%"
        alignItems="center"
        bg={useColorModeValue("white", "dark.700")}
        shadow={3}
        borderRadius="xl"
        p={"20px"}
        mb="20px"
      >
        <Flex w="100%" alignItems="center" direction="row">
          <VStack flex={1}>
            <Flex w="100%" alignItems="center" direction="row">
              <Center
                bg={`${color}20`}
                color="white"
                borderRadius="2xl"
                width="45px"
                height="45px"
              >
                <Emoji name={item.category.icon} fontSize="2xl" />
              </Center>
              <VStack space={0} marginLeft="10px" textAlign="left">
                <Text fontSize="sm">{item.category.title}</Text>
              </VStack>
              <Spacer />
              <NumericFormat
                value={Math.abs(item.value)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => <Text fontSize="md">{value}</Text>}
              />
            </Flex>
            <CategoryOverviewItemChart
              color={color}
              date={date}
              item={item}
              open={open}
            />
          </VStack>
          {item.children.length > 0 && (
            <IconButton
              colorScheme={useColorModeValue("primary", "dark")}
              aria-label="Next"
              size="sm"
              borderRadius="full"
              variant="solid"
              ml="20px"
              icon={
                <Icon
                  as={AntDesign}
                  name={open ? "caretup" : "caretdown"}
                  size="xs"
                />
              }
              onPress={() => setOpen(!open)}
            />
          )}
        </Flex>

        {open && (
          <>
            <Divider
              borderColor="gray.200"
              orientation="horizontal"
              my="20px"
            />
            <VStack w="100%" space={5} alignItems="stretch">
              {item.children.map((child) => (
                <Flex
                  key={child.category.id}
                  w="100%"
                  alignItems="center"
                  direction="row"
                >
                  <Center
                    bg={`${color}20`}
                    color="white"
                    borderRadius="xl"
                    width="35px"
                    height="35px"
                  >
                    <Emoji name={child.category.icon} fontSize="lg" />
                  </Center>
                  <VStack space={0} marginLeft="10px" textAlign="left">
                    <Text fontSize="xs">{child.category.title}</Text>
                  </VStack>
                  <Spacer />
                  <NumericFormat
                    value={Math.abs(child.value)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    renderText={(value) => <Text fontSize="xs">{value}</Text>}
                  />
                </Flex>
              ))}
            </VStack>
          </>
        )}
      </VStack>
    </Box>
  );
}
