import { useMutation } from "@tanstack/react-query";
import { RegisterDto, TokenDto } from "./types";
import { api } from "./api";

export const registerApi = async (input: RegisterDto) => {
  const result = await api.post("users", input);
  return result.data;
};

export default function useRegister() {
  return useMutation<TokenDto, unknown, RegisterDto>(["register"], registerApi);
}
