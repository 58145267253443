import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReminderDto } from "./types";
import { api } from "./api";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";

export default function useDeleteReminder() {
  const token = useSelector((state: RootState) => state.auth.token);
  const accountId = useSelector((state: RootState) => state.auth.accountId);
  const queryClient = useQueryClient();

  const query = async (id: string) => {
    const result = await api.delete(`accounts/${accountId}/reminders/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  };
  return useMutation<ReminderDto, unknown, string>(["deleteReminder"], query, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["reminders"],
      });
      queryClient.invalidateQueries({
        queryKey: ["remindersOverview"],
      });
    },
  });
}
