import React from "react";
import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import {
  View,
  Center,
  Flex,
  HStack,
  Icon,
  Pressable,
  IconButton,
  useColorModeValue,
} from "native-base";
import AntDesign from "@expo/vector-icons/AntDesign";
import Ionicons from "@expo/vector-icons/Ionicons";
import TransactionModal from "./TransactionModal";

export default function NavBar(props: BottomTabBarProps) {
  const { navigation, state } = props;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Flex
      h="120px"
      w="100%"
      pt="50px"
      overflow="hidden"
      direction="row"
      safeAreaBottom
      position="absolute"
      bottom={0}
    >
      <TransactionModal open={open} handleClose={handleClose} />
      <IconButton
        icon={<Icon color="white" as={AntDesign} name="plus" size="md" />}
        w="60px"
        h="60px"
        left="50%"
        ml="-30px"
        _web={{
          mt: "-35px",
        }}
        mt="15px"
        position="absolute"
        variant="solid"
        borderRadius="full"
        colorScheme={useColorModeValue("primary", "dark")}
        shadow={3}
        alignItems="center"
        justifyContent="center"
        onPress={handleOpen}
        zIndex={10000}
        style={{ elevation: 10000 }}
      />
      <View
        borderTopRadius="3xl"
        bg={useColorModeValue("white", "dark.800")}
        flex={1}
        h="70px"
        style={{
          shadowColor: "black",
          shadowOpacity: 0.2,
          shadowRadius: 15,
          shadowOffset: { width: 0, height: 0 },
        }}
      >
        <HStack
          w="100%"
          alignContent="center"
          justifyContent="center"
          px="20px"
          py="5px"
        >
          <Pressable
            py="3"
            flex={1}
            onPress={() => navigation.navigate("Transactions")}
          >
            <Center>
              <Icon
                mb="1"
                as={
                  <Ionicons
                    name={0 === state.index ? "list" : "list-outline"}
                  />
                }
                color={useColorModeValue("primary.700", "dark.300")}
                size="md"
              />
            </Center>
          </Pressable>
          <Pressable
            py="3"
            flex={1}
            onPress={() => navigation.navigate("Chart")}
          >
            <Center>
              <Icon
                mb="1"
                as={
                  <Ionicons
                    name={1 === state.index ? "pie-chart" : "pie-chart-outline"}
                  />
                }
                color={useColorModeValue("primary.700", "dark.300")}
                size="md"
              />
            </Center>
          </Pressable>
        </HStack>
      </View>
      <View h="70px" w="60px" zIndex={1}>
        <View
          w="62px"
          h="40px"
          ml="-1px"
          mt="10px"
          position="relative"
          overflow="hidden"
        >
          <View
            w="112px"
            h="110px"
            mt="-67px"
            ml="-25px"
            bg="#00000000"
            borderRadius="full"
            borderColor={useColorModeValue("white", "dark.800")}
            borderWidth="20px"
          ></View>
        </View>
        <View
          w="60px"
          h="40px"
          mt="-10px"
          position="relative"
          bg={useColorModeValue("white", "dark.800")}
        ></View>
      </View>
      <View
        borderTopRadius="3xl"
        flex={1}
        bg={useColorModeValue("white", "dark.800")}
        h="70px"
        style={{
          shadowColor: "black",
          shadowOpacity: 0.2,
          shadowRadius: 15,
          shadowOffset: { width: 0, height: 0 },
        }}
      >
        <HStack
          w="100%"
          alignContent="center"
          justifyContent="center"
          px="20px"
          py="5px"
        >
          <Pressable
            py="3"
            flex={1}
            onPress={() => navigation.navigate("Wallets")}
          >
            <Center>
              <Icon
                mb="1"
                as={
                  <Ionicons
                    name={2 === state.index ? "wallet" : "wallet-outline"}
                  />
                }
                color={useColorModeValue("primary.700", "dark.300")}
                size="md"
              />
            </Center>
          </Pressable>
          <Pressable
            py="3"
            flex={1}
            onPress={() => navigation.navigate("Reminders")}
          >
            <Center>
              <Icon
                mb="1"
                as={
                  <Ionicons
                    name={3 === state.index ? "calendar" : "calendar-outline"}
                  />
                }
                color={useColorModeValue("primary.700", "dark.300")}
                size="md"
              />
            </Center>
          </Pressable>
        </HStack>
      </View>
    </Flex>
  );
}
