import { useInfiniteQuery } from "@tanstack/react-query";
import { GetGroupedTransactionsDto, GroupedTransactionsDto } from "./types";
import { api } from "./api";
import { RootState, store } from "../store/store";
import { useSelector } from "react-redux";
import { logout } from "../store/slices/authSlice";

export default function useGetGroupedTransactions(
  qs: GetGroupedTransactionsDto
) {
  const token = useSelector((state: RootState) => state.auth.token);
  const accountId = useSelector((state: RootState) => state.auth.accountId);
  const query = async ({ pageParam = 0 }) => {
    qs.cursor = pageParam * (qs.limit ?? 5);

    const result = await api.get(`accounts/${accountId}/transactions/grouped`, {
      params: qs,
      headers: { Authorization: `Bearer ${token}` },
    });

    return {
      items: result.data.items,
      nextPage: result.data.hasNext ? pageParam + 1 : undefined,
      groupBy: result.data.groupBy,
    };
  };
  return useInfiniteQuery<
    GroupedTransactionsDto,
    unknown,
    GroupedTransactionsDto
  >(["groupedTransactions"], query, {
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    onError: (error: any) => {
      if (error.response.status === 401) {
        store.dispatch(logout());
      }
    },
  });
}
