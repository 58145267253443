import {
  Actionsheet,
  Box,
  Flex,
  Image,
  Input,
  Pressable,
  Text,
  useColorModeValue,
} from "native-base";
import * as React from "react";
import { getImage } from "../utils/getImage";

type BankPickerProps = {
  value?: string;
  onChange: (value: string) => void;
  placeHolder?: string;
};

const banks = [
  {
    value: "scotia",
    name: "Scotiabank",
  },
  {
    value: "cibc",
    name: "CIBC",
  },
  {
    value: "td",
    name: "TD",
  },
  {
    value: "rbc",
    name: "RBC",
  },
];

function BankPicker(props: BankPickerProps) {
  const { value, onChange, placeHolder } = props;
  const [open, setOpen] = React.useState(false);
  const bank = banks.find((bank) => bank.value === value) ?? banks[0];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value: string) => () => {
    onChange(value);
    handleClose();
  };

  return (
    <>
      <Actionsheet isOpen={open} onClose={handleClose}>
        <Actionsheet.Content bg={useColorModeValue("white", "dark.700")}>
          {banks.map((bank: any) => (
            <Actionsheet.Item
              key={bank.value}
              onPress={handleSelect(bank.value)}
              py={2}
              bg={useColorModeValue("white", "dark.700")}
            >
              <Flex alignItems="center" w="100%" direction="row">
                <Box bg="white" borderRadius="full">
                  <Image
                    source={getImage(bank.value)}
                    size="12"
                    resizeMode={"contain"}
                    borderRadius="full"
                    alt={bank.name}
                    overflow="hidden"
                  />
                </Box>

                <Text pl="10px" fontSize="14">
                  {bank.name}
                </Text>
              </Flex>
            </Actionsheet.Item>
          ))}
        </Actionsheet.Content>
      </Actionsheet>
      <Input
        w="100%"
        value={bank?.name}
        placeholder={placeHolder || "Bank"}
        size="lg"
        borderRadius={"full"}
        InputLeftElement={
          <Box bg="white" borderRadius="full" ml="10px">
            {bank.value === "scotia" && (
              <Image
                source={getImage("scotia")}
                size="8"
                resizeMode={"contain"}
                borderRadius="full"
                alt={bank.name}
              />
            )}
            {bank.value === "cibc" && (
              <Image
                source={getImage("cibc")}
                size="8"
                resizeMode={"contain"}
                borderRadius="full"
                alt={bank.name}
              />
            )}
            {bank.value === "td" && (
              <Image
                source={getImage("td")}
                size="8"
                resizeMode={"contain"}
                borderRadius="full"
                alt={bank.name}
              />
            )}
            {bank.value === "rbc" && (
              <Image
                source={getImage("rbc")}
                size="8"
                resizeMode={"contain"}
                borderRadius="full"
                alt={bank.name}
              />
            )}
          </Box>
        }
        isReadOnly
        onPressOut={() => handleOpen()}
      />
      <Pressable
        onPress={() => handleOpen()}
        h="40px"
        w="100%"
        position="relative"
        mt="-40px"
      ></Pressable>
    </>
  );
}

const areEqual = (prevProps: BankPickerProps, nextProps: BankPickerProps) => {
  if (
    prevProps.value === nextProps.value &&
    prevProps.placeHolder === nextProps.placeHolder
  ) {
    return true;
  }
  return false;
};

export default React.memo(BankPicker, areEqual);
