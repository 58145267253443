import React from "react";
import { useColorModeValue, View } from "native-base";
import { HeaderCard } from "../components/HeaderCard";
import { CategoryOverview } from "../components/CategoryOverview";
import { CategoryChart } from "../components/CategoryChart";
import { useSharedValue } from "react-native-reanimated";

export default function ChartScreen() {
  const [categoryType, setCategoryType] = React.useState<string>("expenses");

  const scrollValue = useSharedValue(0);
  const onScroll = (value: number) => {
    scrollValue.value = value;
  };

  return (
    <View bg={useColorModeValue("tertiary.50", "tertiary.900")} flex={1}>
      <HeaderCard
        scrollValue={scrollValue}
        content={
          <CategoryChart
            categoryType={categoryType}
            setCategoryType={setCategoryType}
            scrollValue={scrollValue}
          />
        }
      />
      <CategoryOverview categoryType={categoryType} onScroll={onScroll} />
    </View>
  );
}
