import React from "react";
import { useColorModeValue, View } from "native-base";
import { LoginForm } from "../components/LoginForm";
import { NavigationProp } from "@react-navigation/native";

type LoginScreenProps = {
  navigation: NavigationProp<any>;
};

export default function LoginScreen({ navigation }: LoginScreenProps) {
  return (
    <View bg={useColorModeValue("tertiary.50", "tertiary.900")} flex={1}>
      <LoginForm navigation={navigation} />
    </View>
  );
}
