import * as React from "react";
import CategoryItem from "./CategoryItem";
import { CategoryModal } from "./CategoryModal";
import useGetCategories from "../hooks/useGetCategories";

type CategoriesProps = {
  childrenMode: boolean;
};

export function Categories(props: CategoriesProps) {
  const { childrenMode } = props;
  const [category, setCategory] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = (category: any) => () => {
    setCategory(category);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const { data, refetch } = useGetCategories();

  const [refreshing, setRefreshing] = React.useState(false);
  const onRefresh = React.useCallback(async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  }, []);

  const [cats, setCats] = React.useState<any[]>([]);

  React.useEffect(() => {
    const categories = [...(data ?? [])].sort((a, b) => a.order - b.order);

    const flatCategories: any[] = [];
    for (const category of categories) {
      flatCategories.push(category);
      if (category.children) {
        for (const child of category.children) {
          flatCategories.push({ ...child, parentId: category.id });
        }
      }
    }
    setCats(flatCategories);
  }, [data]);

  React.useEffect(() => {
    refetch();
  }, [childrenMode]);

  const renderItem = ({ item, drag, isActive }: any) => {
    return (
      <CategoryItem
        category={item}
        key={item.id}
        handleOpen={handleOpen}
        handleDrag={drag}
        visible={!(!childrenMode && item.parentId)}
        dragHandleVisible={
          (childrenMode && item.parentId) || (!childrenMode && !item.parentId)
        }
      />
    );
  };

  return (
    <>
      <CategoryModal
        handleClose={handleClose}
        open={open}
        category={category}
      />
    </>
  );
}
