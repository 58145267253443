import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { Ionicons } from "@expo/vector-icons";
import {
  Button,
  Center,
  FormControl,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Modal,
  Select,
  useColorModeValue,
  VStack,
} from "native-base";
import { FontAwesome, MaterialIcons } from "@expo/vector-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BankPicker from "./BankPicker";
import { WalletDto } from "../hooks/types";
import useCreateWallet from "../hooks/useCreateWallet";
import useUpdateWallet from "../hooks/useUpdateWallet";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";

type WalletModalProps = {
  open: boolean;
  handleClose: () => void;
  wallet?: WalletDto | undefined;
};

const WalletType = {
  "Credit Card": "Credit Card",
  "Checking Account": "Checking Account",
  "Savings Account": "Savings Account",
};

function WalletModal(props: WalletModalProps) {
  const { open, handleClose, wallet } = props;

  const { t } = useTranslation();

  const mode = wallet ? "edit" : "add";

  const schema = yup
    .object({
      type: yup.string().required().label("Type"),
      title: yup.string().min(3).required().label("Title"),
      balance: yup
        .number()
        .typeError("Balance must be a number")
        .required()
        .label("Balance"),
      bank: yup.string().required().label("Bank"),
      lastFour: yup.string().min(4).max(4).label("Last Four Digits"),
      limit: yup
        .number()
        .typeError("Limit must be a number")
        .positive()
        .label("Limit"),
    })
    .required();

  const { handleSubmit, control, setValue, reset, watch, formState } =
    useForm<any>({
      resolver: yupResolver(schema),
      defaultValues: {
        type: "Credit Card",
        bank: "scotia",
      },
    });

  React.useEffect(() => {
    if (wallet) {
      setValue("id", wallet.id);
      setValue("type", wallet.type);
      setValue("title", wallet.title);
      setValue("bank", wallet.bank);
      setValue("balance", `${Math.abs(wallet.balance)}`);
      if (wallet.limit) setValue("limit", `${Math.abs(wallet.limit)}`);
      if (wallet.lastFour) setValue("lastFour", wallet.lastFour);
    }
  }, [setValue, wallet]);

  const { mutateAsync: createWallet } = useCreateWallet();

  const onCreate = (data: any) => {
    createWallet({
      type: data.type,
      title: data.title,
      balance: parseFloat(data.balance),
      currency: "CAD",
      bank: data.bank,
      description: data.description,
      lastFour: data.lastFour,
      limit: data.limit ? parseFloat(data.limit) : undefined,
    })
      .then(() => {
        reset();
        handleClose();
      })
      .catch(() => {});
  };

  const { mutateAsync: updateWallet } = useUpdateWallet();

  const onEdit = (data: any) => {
    updateWallet({
      id: data.id,
      type: data.type,
      title: data.title,
      bank: data.bank,
      description: data.description,
      lastFour: data.lastFour,
      limit: data.limit ? parseFloat(data.limit) : undefined,
    })
      .then(() => {
        reset();
        handleClose();
      })
      .catch(() => {});
  };

  const onModalClose = () => {
    handleClose();
    reset();
  };

  const addButtonColorScheme = useColorModeValue("green", "dark");
  const editButtonColorScheme = useColorModeValue("blue", "dark");
  const deleteButtonColorScheme = useColorModeValue("red", "dark");

  return (
    <Modal
      isOpen={open}
      onClose={onModalClose}
      size="xl"
      animationPreset="slide"
      avoidKeyboard={true}
    >
      <Modal.Content
        borderRadius="3xl"
        bg={useColorModeValue("white", "dark.700")}
        paddingBottom={5}
      >
        <Modal.Header
          borderBottomWidth={0}
          bg={useColorModeValue("white", "dark.700")}
          alignItems={"center"}
        >
          <Heading size="md">{t(TranslationKeys.Wallet)}</Heading>
        </Modal.Header>
        <Modal.Body>
          <VStack w="100%" space={5}>
            <HStack w="99%" space={1}>
              <Controller
                name={"bank"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid },
                }) => (
                  <FormControl isRequired w="1/2" isInvalid={invalid}>
                    <BankPicker value={value} onChange={onChange} />
                    <FormControl.ErrorMessage>
                      Bank is required
                    </FormControl.ErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                name={"type"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl isRequired w="1/2" isInvalid={invalid}>
                    <Select
                      placeholder="Type"
                      selectedValue={value}
                      onValueChange={onChange}
                      borderRadius={"full"}
                      size="lg"
                    >
                      {Object.keys(WalletType).map((key) => (
                        <Select.Item
                          key={key}
                          label={t(key)}
                          value={WalletType[key as keyof typeof WalletType]}
                        />
                      ))}
                    </Select>
                    <FormControl.ErrorMessage>
                      {error?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                )}
              />
            </HStack>

            <Controller
              name={"title"}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { invalid, error },
              }) => (
                <FormControl isRequired w="100%" isInvalid={invalid}>
                  <Input
                    value={value}
                    onChangeText={onChange}
                    placeholder={t(TranslationKeys.Title) as string}
                    size="lg"
                    borderRadius={"full"}
                    InputLeftElement={
                      <Icon
                        as={MaterialIcons}
                        name="description"
                        size="sm"
                        ml="10px"
                        textAlign="center"
                      />
                    }
                  />
                  <FormControl.ErrorMessage>
                    {error?.message}
                  </FormControl.ErrorMessage>
                </FormControl>
              )}
            />
            {mode === "add" && (
              <Controller
                name={"balance"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl isRequired w="100%" isInvalid={invalid}>
                    <Input
                      value={value}
                      onChangeText={onChange}
                      placeholder={t(TranslationKeys.Balance) as string}
                      keyboardType="decimal-pad"
                      size="lg"
                      borderRadius={"full"}
                      InputLeftElement={
                        <Icon
                          as={FontAwesome}
                          name="dollar"
                          size="sm"
                          ml="10px"
                          textAlign="center"
                        />
                      }
                    />
                    <FormControl.ErrorMessage>
                      {error?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                )}
              />
            )}
            <HStack w="99%" space={1}>
              <Controller
                name={"limit"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl w="1/2" isInvalid={invalid}>
                    <Input
                      value={value}
                      onChangeText={onChange}
                      placeholder={t(TranslationKeys.Limit) as string}
                      keyboardType="decimal-pad"
                      size="lg"
                      borderRadius={"full"}
                      InputLeftElement={
                        <Icon
                          as={FontAwesome}
                          name="dollar"
                          size="sm"
                          ml="10px"
                          textAlign="center"
                        />
                      }
                    />
                    <FormControl.ErrorMessage>
                      {error?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                )}
              />
              <Controller
                name={"lastFour"}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl w="1/2" isInvalid={invalid}>
                    <Input
                      value={value}
                      onChangeText={onChange}
                      placeholder={t(TranslationKeys.LastFourDigits) as string}
                      keyboardType="decimal-pad"
                      size="lg"
                      borderRadius={"full"}
                      InputLeftElement={
                        <Icon
                          as={MaterialIcons}
                          name="credit-card"
                          size="sm"
                          ml="10px"
                          textAlign="center"
                        />
                      }
                    />
                    <FormControl.ErrorMessage>
                      {error?.message}
                    </FormControl.ErrorMessage>
                  </FormControl>
                )}
              />
            </HStack>

            <Center w="100%">
              {mode === "add" && (
                <HStack space={5} w={"100%"}>
                  <Button.Group isAttached w={"100%"} borderRadius={"full"}>
                    <Button
                      colorScheme={addButtonColorScheme}
                      leftIcon={
                        <Icon as={Ionicons} name="checkmark" size="lg" />
                      }
                      onPress={handleSubmit(onCreate)}
                      w={"2/3"}
                    >
                      Add
                    </Button>
                    <Button
                      variant="outline"
                      colorScheme={deleteButtonColorScheme}
                      leftIcon={
                        <Icon as={Ionicons} name="close-sharp" size="lg" />
                      }
                      onPress={onModalClose}
                      w={"1/3"}
                    >
                      Close
                    </Button>
                  </Button.Group>
                </HStack>
              )}
              {mode === "edit" && (
                <HStack space={5}>
                  <Button.Group isAttached w={"100%"} borderRadius={"full"}>
                    <Button
                      colorScheme={editButtonColorScheme}
                      leftIcon={<Icon as={Ionicons} name="pencil" size="lg" />}
                      onPress={handleSubmit(onEdit)}
                      w={"2/3"}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="outline"
                      colorScheme={deleteButtonColorScheme}
                      leftIcon={
                        <Icon as={Ionicons} name="close-sharp" size="lg" />
                      }
                      onPress={onModalClose}
                      w={"1/3"}
                    >
                      Close
                    </Button>
                  </Button.Group>
                </HStack>
              )}
            </Center>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

const areEqual = (prevProps: WalletModalProps, nextProps: WalletModalProps) => {
  if (prevProps.open !== nextProps.open) {
    return false;
  }
  if (prevProps.wallet?.id !== nextProps.wallet?.id) {
    return false;
  }
  return true;
};

export default React.memo(WalletModal, areEqual);
