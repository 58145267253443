import { TranslationKeys, TranslationObject } from "./keys";

export const fr: TranslationObject = {
  [TranslationKeys.Wallets]: "Portefeuilles",
  [TranslationKeys.Categories]: "Catégories",
  [TranslationKeys.Transactions]: "Transactions",
  [TranslationKeys.Transaction]: "Transaction",
  [TranslationKeys.Income]: "Revenus",
  [TranslationKeys.Expenses]: "Dépenses",
  [TranslationKeys.AddTransaction]: "Ajouter une transaction",
  [TranslationKeys.EditTransaction]: "Modifier la transaction",
  [TranslationKeys.Amount]: "Montant",
  [TranslationKeys.Category]: "Catégorie",
  [TranslationKeys.Title]: "Titre",
  [TranslationKeys.Wallet]: "Portefeuille",
  [TranslationKeys.ToWallet]: "Au portefeuille",
  [TranslationKeys.Chart]: "Graphique",
  [TranslationKeys.Assets]: "Actifs",
  [TranslationKeys.Liabilities]: "Passifs",
  [TranslationKeys.AddANewWallet]: "Ajouter un nouveau portefeuille",
  [TranslationKeys.CreditCard]: "Carte de crédit",
  [TranslationKeys.CheckingAccount]: "Compte courant",
  [TranslationKeys.SavingsAccount]: "Compte d'épargne",
  [TranslationKeys.Balance]: "Solde",
  [TranslationKeys.Limit]: "Limite",
  [TranslationKeys.LastFourDigits]: "Quatre derniers chiffres",
  [TranslationKeys.AddWallet]: "Ajouter un portefeuille",
  [TranslationKeys.EditWallet]: "Modifier le portefeuille",
  [TranslationKeys.Reminders]: "Rappels",
  [TranslationKeys.Reminder]: "Rappel",
  [TranslationKeys.AddANewReminder]: "Ajouter un nouveau rappel",
  [TranslationKeys.AddReminder]: "Ajouter un rappel",
  [TranslationKeys.EditReminder]: "Modifier le rappel",
  [TranslationKeys.Type]: "Type",
  [TranslationKeys.Subscription]: "Abonnement",
  [TranslationKeys.Bill]: "Facture",
  [TranslationKeys.Mortgage]: "Crédit immobilier",
  [TranslationKeys.Installment]: "Versement",
  [TranslationKeys.Recurring]: "Récurrent",
  [TranslationKeys.Weekly]: "Hebdomadaire",
  [TranslationKeys.Monthly]: "Mensuel",
  [TranslationKeys.Yearly]: "Annuel",
  [TranslationKeys.On]: "le",
  [TranslationKeys.RemindMe]: "Rappelez-moi",
  [TranslationKeys.Day]: "Jour",
  [TranslationKeys.Week]: "Semaine",
  [TranslationKeys.Month]: "Mois",
  [TranslationKeys.Notification]: "Notification",
  [TranslationKeys.Company]: "Entreprise",
  [TranslationKeys.Custom]: "Personnalisé",
  [TranslationKeys.Search]: "Recherche...",
  [TranslationKeys.Name]: "Nom",
  [TranslationKeys.Description]: "Description",
  [TranslationKeys.Settings]: "Paramètres",
  [TranslationKeys.Light]: "Clair",
  [TranslationKeys.Dark]: "Sombre",
  [TranslationKeys.GoldMembership]: "Adhésion Gold",
  [TranslationKeys.SignOut]: "Se déconnecter",
  [TranslationKeys.Home]: "Accueil",
  [TranslationKeys.SignIn]: "Se connecter",
  [TranslationKeys.SignUp]: "S'inscrire",
  [TranslationKeys.MostUsed]: "Les plus utilisés",
  [TranslationKeys.NextOn]: "Prochain sur",
  [TranslationKeys.Before]: "avant",
  [TranslationKeys.Days]: "jours",
  [TranslationKeys.Weeks]: "semaines",
  [TranslationKeys.Months]: "mois",
  [TranslationKeys.Animations]: "Animations",
};
