import * as React from "react";
import moment from "moment";
import DailyTransactionsDayItem from "./DailyTransactionsDayItem";
import { NumericFormat } from "react-number-format";
import {
  Box,
  Divider,
  Flex,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import { GroupedTransactionsItemDto, TransactionDto } from "../hooks/types";

type DailyTransactionsDayProps = {
  date: Date;
  groupedTransactionsItem: GroupedTransactionsItemDto;
  handleOpen: (transaction: TransactionDto) => void;
};

function DailyTransactionsDay(props: DailyTransactionsDayProps) {
  const { date, groupedTransactionsItem, handleOpen } = props;

  const bg = useColorModeValue("white", "dark.700");

  return (
    <Box px="20px">
      <VStack
        w="100%"
        divider={<Divider borderColor="gray.200" orientation="horizontal" />}
        mb="20px"
        bg={bg}
        p="20px"
        borderRadius="2xl"
        shadow={2}
        space={2}
      >
        <Flex w="100%" alignItems="center" direction="row">
          <Text fontSize="md" fontWeight="bold">
            {moment(date).format("MMM DD")}
          </Text>
          <Spacer />
          <NumericFormat
            value={groupedTransactionsItem.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value) => (
              <Text fontSize="md" fontWeight="bold">
                {value}
              </Text>
            )}
          />
        </Flex>
        <VStack w="100%" space={5} alignItems="stretch">
          {groupedTransactionsItem.transactions.map((transaction) => (
            <DailyTransactionsDayItem
              key={transaction.id}
              transaction={transaction}
              onClick={() => handleOpen(transaction)}
            />
          ))}
        </VStack>
      </VStack>
    </Box>
  );
}

const areEqual = (
  prevProps: DailyTransactionsDayProps,
  nextProps: DailyTransactionsDayProps
) => {
  if (prevProps.date !== nextProps.date) {
    return false;
  }
  if (
    prevProps.groupedTransactionsItem?.amount !==
    nextProps.groupedTransactionsItem?.amount
  ) {
    return false;
  }
  if (
    prevProps.groupedTransactionsItem?.transactions?.length !==
    nextProps.groupedTransactionsItem?.transactions?.length
  ) {
    return false;
  }
  return true;
};

export default React.memo(DailyTransactionsDay, areEqual);
