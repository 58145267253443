import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import TransactionsScreen from "./TransactionsScreen";
import NavBar from "../components/NavBar";
import {
  Icon,
  Pressable,
  useColorMode,
  useColorModeValue,
  useTheme,
} from "native-base";
import * as NavigationBar from "expo-navigation-bar";
import { Platform } from "react-native";
import ChartScreen from "./ChartScreen";
import WalletsScreen from "./WalletsScreen";
import Ionicons from "@expo/vector-icons/Ionicons";
import { useFocusEffect } from "@react-navigation/native";
import RemindersScreen from "./RemindersScreen";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";

const Tab = createBottomTabNavigator();

export default function TabsNavigation({ navigation }: any) {
  const { t } = useTranslation();
  const { colorMode } = useColorMode();
  const { colors } = useTheme();

  useFocusEffect(() => {
    if (Platform.OS === "android") {
      NavigationBar.setBackgroundColorAsync(
        colorMode === "light" ? colors.white : colors.dark[800]
      );
    }
  });

  return (
    <Tab.Navigator
      tabBar={(props) => <NavBar {...props} />}
      screenOptions={{
        headerRight: () => (
          <Pressable mr="20px" onPress={() => navigation.navigate("Settings")}>
            <Icon
              as={<Ionicons name="settings" />}
              color={useColorModeValue("dark.300", "dark.300")}
              size="sm"
            />
          </Pressable>
        ),
        headerStyle: {
          backgroundColor:
            colorMode === "light" ? colors.primary[800] : colors.dark[800],
          borderBottomWidth: 0,
          elevation: 0,
          shadowOpacity: 0,
          shadowRadius: 0,
        },
        headerTintColor: colors.blueGray[300],
      }}
    >
      <Tab.Screen
        name="Transactions"
        component={TransactionsScreen}
        options={{ title: t(TranslationKeys.Transactions) as string }}
      />
      <Tab.Screen
        name="Chart"
        component={ChartScreen}
        options={{ title: t(TranslationKeys.Chart) as string }}
      />
      <Tab.Screen
        name="Wallets"
        component={WalletsScreen}
        options={{ title: t(TranslationKeys.Wallets) as string }}
      />
      <Tab.Screen
        name="Reminders"
        component={RemindersScreen}
        options={{ title: t(TranslationKeys.Reminders) as string }}
      />
    </Tab.Navigator>
  );
}
