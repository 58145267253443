import * as React from "react";
import { Text } from "native-base";
import useGetGroupedTransactions from "../hooks/useGetGroupedTransactions";
import { FlashList } from "@shopify/flash-list";
import DailyTransactionsDay from "./DailyTransactionsDay";
import moment from "moment";
import TransactionModal from "./TransactionModal";
import {
  GroupedTransactionsDto,
  GroupedTransactionsGroupBy,
  TransactionDto,
} from "../hooks/types";
import { GroupedTransactionsItemDto } from "../hooks/types";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

type DailyTransactionsProps = {
  wallets: string[];
  categories: string[];
  onScroll: (value: number) => void;
};

export function DailyTransactions(props: DailyTransactionsProps) {
  const { wallets, categories, onScroll } = props;
  const [transaction, setTransaction] = React.useState<TransactionDto>();
  const [open, setOpen] = React.useState(false);
  const handleOpen = (transaction: TransactionDto) => {
    setTransaction(transaction);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const LIMIT = 20;
  const year = useSelector((state: RootState) => state.settings.year);
  const month = useSelector((state: RootState) => state.settings.month);
  const animation = useSelector((state: RootState) => state.settings.animation);

  const { data, hasNextPage, fetchNextPage, refetch, isRefetching, remove } =
    useGetGroupedTransactions({
      year,
      groupBy: GroupedTransactionsGroupBy.DAY,
      month: month !== undefined ? month + 1 : undefined,
      reverse: true,
      limit: LIMIT,
      wallets,
      categories,
    });

  React.useEffect(() => {
    remove();
    refetch();
  }, [year, month, wallets, categories]);

  const loadNextPageData = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const onRefresh = () => {
    refetch();
  };

  const flattenData =
    data?.pages.flatMap((page: GroupedTransactionsDto) => page.items) ?? [];
  const flattenDataObj: Record<string, GroupedTransactionsItemDto> = {};
  for (const fd of flattenData) {
    if (flattenDataObj[fd.key]) {
      flattenDataObj[fd.key].amount =
        Math.floor(flattenDataObj[fd.key].amount + fd.amount * 100) / 100;
      flattenDataObj[fd.key].transactions.push(...fd.transactions);
    } else {
      flattenDataObj[fd.key] = {
        amount: fd.amount,
        key: fd.key,
        transactions: [...fd.transactions],
      };
    }
  }
  const flattenMergedData = Object.values(flattenDataObj);

  return (
    <>
      <TransactionModal
        handleClose={handleClose}
        open={open}
        transaction={transaction}
      />
      <FlashList
        contentContainerStyle={{ paddingTop: 205, paddingBottom: 110 }}
        keyExtractor={(item: any) => item.key}
        data={flattenMergedData}
        renderItem={({ item }) => (
          <DailyTransactionsDay
            key={item.key}
            date={moment(item.key).toDate()}
            groupedTransactionsItem={item}
            handleOpen={handleOpen}
          />
        )}
        estimatedItemSize={200}
        onEndReachedThreshold={0.5}
        onEndReached={loadNextPageData}
        refreshing={isRefetching}
        onRefresh={onRefresh}
        showsVerticalScrollIndicator={false}
        onScroll={(e) => {
          if (animation) {
            onScroll(e.nativeEvent.contentOffset.y);
          }
        }}
      />
    </>
  );
}
