import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  Flex,
  HStack,
  Modal,
  Pressable,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import * as React from "react";
import { getImage } from "../utils/getImage";
import useGetWallets from "../hooks/useGetWallets";
import { TranslationKeys } from "../translations/keys";
import { useTranslation } from "react-i18next";

type WalletFilterProps = {
  wallets: string[];
  setWallets: (value: string[]) => void;
};

function WalletFilter(props: WalletFilterProps) {
  const { wallets: walletsIds, setWallets } = props;
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState<string[]>([]);

  const { t } = useTranslation();

  React.useEffect(() => {
    if (walletsIds) {
      setValues(walletsIds);
    }
  }, [walletsIds]);

  const wallets = useGetWallets();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setWallets(values);
  };
  const handleSelect = (value: any) => () => {
    const index = values.findIndex((v) => v === value.id);
    if (index > -1) {
      setValues((prev) => prev.filter((v) => v !== value.id));
    } else {
      setValues((prev) => [...prev, value.id]);
    }
  };

  const bgColor = useColorModeValue("dark.50", "dark.800");
  const badgeBgColor = useColorModeValue("primary.200", "primary.200");

  return (
    <>
      <Modal
        isOpen={open}
        onClose={handleClose}
        animationPreset="slide"
        avoidKeyboard={true}
      >
        <Modal.Content borderRadius="3xl" bg={bgColor} h="60%">
          <Modal.CloseButton />
          <Modal.Body>
            <VStack>
              {wallets.data?.map((wallet) => (
                <Pressable key={wallet.id} onPress={handleSelect(wallet)}>
                  <Flex
                    alignItems="center"
                    w="100%"
                    py="10px"
                    bg={bgColor}
                    direction="row"
                  >
                    <Checkbox
                      value={wallet.id}
                      isChecked={values.findIndex((v) => v === wallet.id) > -1}
                      mx="10px"
                      accessibilityLabel={wallet.title}
                    />
                    <Avatar
                      bg="green.500"
                      source={getImage(wallet.bank)}
                      size="sm"
                    ></Avatar>
                    <Text pl="10px">{wallet.title}</Text>
                  </Flex>
                </Pressable>
              ))}
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Button
        size="sm"
        rounded="2xl"
        flex={1}
        variant={useColorModeValue("subtle", "solid")}
        colorScheme={
          values.length > 0
            ? useColorModeValue("primary", "primary")
            : useColorModeValue("coolGray", "dark")
        }
        onPress={handleOpen}
      >
        <HStack space={2}>
          <Text>{t(TranslationKeys.Wallets)}</Text>
          {values.length > 0 && (
            <Badge rounded="full" bg={badgeBgColor}>
              {values.length}
            </Badge>
          )}
        </HStack>
      </Button>
    </>
  );
}

const areEqual = (
  prevProps: WalletFilterProps,
  nextProps: WalletFilterProps
) => {
  if (prevProps.wallets.length === nextProps.wallets.length) {
    return true;
  }
  return false;
};

export default React.memo(WalletFilter, areEqual);
