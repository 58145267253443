import { TranslationKeys, TranslationObject } from "./keys";

export const es: TranslationObject = {
  [TranslationKeys.Wallets]: "Carteras",
  [TranslationKeys.Categories]: "Categorías",
  [TranslationKeys.Transactions]: "Transacciones",
  [TranslationKeys.Transaction]: "Transaccion",
  [TranslationKeys.Income]: "Ingresos",
  [TranslationKeys.Expenses]: "Gastos",
  [TranslationKeys.AddTransaction]: "Añadir transacción",
  [TranslationKeys.EditTransaction]: "Editar transacción",
  [TranslationKeys.Amount]: "Cantidad",
  [TranslationKeys.Category]: "Categoría",
  [TranslationKeys.Title]: "Título",
  [TranslationKeys.Wallet]: "Cartera",
  [TranslationKeys.ToWallet]: "A cartera",
  [TranslationKeys.Chart]: "Gráfico",
  [TranslationKeys.Assets]: "Activos",
  [TranslationKeys.Liabilities]: "Pasivos",
  [TranslationKeys.AddANewWallet]: "Agregar una nueva cartera",
  [TranslationKeys.CreditCard]: "Tarjeta de crédito",
  [TranslationKeys.CheckingAccount]: "Cuenta corriente",
  [TranslationKeys.SavingsAccount]: "Cuenta de ahorros",
  [TranslationKeys.Balance]: "Saldo",
  [TranslationKeys.Limit]: "Límite",
  [TranslationKeys.LastFourDigits]: "Últimos cuatro dígitos",
  [TranslationKeys.AddWallet]: "Añadir cartera",
  [TranslationKeys.EditWallet]: "Editar cartera",
  [TranslationKeys.Reminders]: "Recordatorios",
  [TranslationKeys.Reminder]: "Recordatorio",
  [TranslationKeys.AddANewReminder]: "Agregar un nuevo recordatorio",
  [TranslationKeys.AddReminder]: "Añadir recordatorio",
  [TranslationKeys.EditReminder]: "Editar recordatorio",
  [TranslationKeys.Type]: "Tipo",
  [TranslationKeys.Subscription]: "Suscripción",
  [TranslationKeys.Bill]: "Factura",
  [TranslationKeys.Mortgage]: "Hipoteca",
  [TranslationKeys.Installment]: "Cuota",
  [TranslationKeys.Recurring]: "Recurrente",
  [TranslationKeys.Weekly]: "Semanal",
  [TranslationKeys.Monthly]: "Mensual",
  [TranslationKeys.Yearly]: "Anual",
  [TranslationKeys.On]: "el",
  [TranslationKeys.RemindMe]: "Recordarme",
  [TranslationKeys.Day]: "Día",
  [TranslationKeys.Week]: "Semana",
  [TranslationKeys.Month]: "Mes",
  [TranslationKeys.Notification]: "Notificación",
  [TranslationKeys.Company]: "Empresa",
  [TranslationKeys.Custom]: "Personalizado",
  [TranslationKeys.Search]: "Buscar...",
  [TranslationKeys.Name]: "Nombre",
  [TranslationKeys.Description]: "Descripción",
  [TranslationKeys.Settings]: "Configuración",
  [TranslationKeys.Light]: "Claro",
  [TranslationKeys.Dark]: "Oscuro",
  [TranslationKeys.GoldMembership]: "Membresía Gold",
  [TranslationKeys.SignOut]: "Cerrar sesión",
  [TranslationKeys.Home]: "Inicio",
  [TranslationKeys.SignIn]: "Iniciar sesión",
  [TranslationKeys.SignUp]: "Registrarse",
  [TranslationKeys.MostUsed]: "Más utilizados",
  [TranslationKeys.NextOn]: "Siguiente en",
  [TranslationKeys.Before]: "antes",
  [TranslationKeys.Days]: "días",
  [TranslationKeys.Weeks]: "semanas",
  [TranslationKeys.Months]: "meses",
  [TranslationKeys.Animations]: "animaciones",
};
