import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UpdateWalletDto, WalletDto } from "./types";
import { api } from "./api";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";

export default function useUpdateWallet() {
  const token = useSelector((state: RootState) => state.auth.token);
  const accountId = useSelector((state: RootState) => state.auth.accountId);
  const queryClient = useQueryClient();

  const query = async (input: UpdateWalletDto) => {
    const result = await api.put(
      `accounts/${accountId}/wallets/${input.id}`,
      input,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return result.data;
  };
  return useMutation<WalletDto, unknown, UpdateWalletDto>(
    ["updateWallet"],
    query,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["wallets"],
        });
        queryClient.invalidateQueries({
          queryKey: ["groupedTransactions"],
        });
        queryClient.invalidateQueries({
          queryKey: ["transactionsOverview"],
        });
        queryClient.invalidateQueries({
          queryKey: ["transactions"],
        });
      },
    }
  );
}
