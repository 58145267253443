import { useQuery } from "@tanstack/react-query";
import { CompanyDto } from "./types";
import { store } from "../store/store";
import { logout } from "../store/slices/authSlice";

export default function useGetCompanies(q?: string) {
  const query = async () => {
    const res = await (
      await fetch(
        `https://autocomplete.clearbit.com/v1/companies/suggest?query=${
          q && q.length > 0 ? q : "amazon"
        }`
      )
    ).json();
    return (
      res.map((c: any) => ({
        name: c.name,
        domain: c.domain,
        image: c.logo,
      })) ?? []
    );
  };
  return useQuery<CompanyDto[]>(["companies"], query, {
    onError: (error: any) => {
      if (error.response.status === 401) {
        store.dispatch(logout());
      }
    },
  });
}
