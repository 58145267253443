import React from "react";
import { useColorModeValue, View } from "native-base";
import { HeaderCard } from "../components/HeaderCard";
import { CategoryCount } from "../components/CategoryCount";
import { Categories } from "../components/Categories";

export default function CategoriesScreen() {
  const [childrenMode, setChildrenMode] = React.useState(false);

  return (
    <View bg={useColorModeValue("tertiary.50", "tertiary.900")} flex={1}>
      <HeaderCard
        content={
          <CategoryCount
            childrenMode={childrenMode}
            setChildrenMode={setChildrenMode}
          />
        }
      />
      <Categories childrenMode={childrenMode} />
    </View>
  );
}
