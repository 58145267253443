import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateReminderDto, ReminderDto } from "./types";
import { api } from "./api";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";

export default function useCreateReminder() {
  const token = useSelector((state: RootState) => state.auth.token);
  const accountId = useSelector((state: RootState) => state.auth.accountId);
  const queryClient = useQueryClient();

  const query = async (input: CreateReminderDto) => {
    const result = await api.post(`accounts/${accountId}/reminders`, input, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return result.data;
  };
  return useMutation<ReminderDto, unknown, CreateReminderDto>(
    ["createReminder"],
    query,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["reminders"],
        });
        queryClient.invalidateQueries({
          queryKey: ["remindersOverview"],
        });
      },
    }
  );
}
