import {
  Avatar,
  Flex,
  Pressable,
  Progress,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import * as React from "react";
import { NumericFormat } from "react-number-format";
import { getImage } from "../utils/getImage";
import { WalletDto } from "../hooks/types";
import { useTranslation } from "react-i18next";

type WalletItemProps = {
  wallet: WalletDto;
  handleOpen: (wallet?: WalletDto) => void;
};

function WalletItem(props: WalletItemProps) {
  const { wallet, handleOpen } = props;

  const { t } = useTranslation();

  return (
    <Pressable onPress={() => handleOpen(wallet)} px="20px">
      <VStack
        w="100%"
        alignItems="center"
        bg={useColorModeValue("white", "dark.700")}
        shadow={6}
        borderRadius="xl"
        p={"20px"}
        mb="20px"
      >
        <Flex w="100%" alignItems="center" direction="row">
          <Avatar
            bg="green.500"
            source={getImage(wallet.bank)}
            size="md"
          ></Avatar>
          <VStack space={0} marginLeft="10px" alignItems="flex-start">
            <Text fontSize="sm" fontWeight="bold">
              {wallet.title}
            </Text>
            <Text fontSize="xs">{t(wallet.type)}</Text>
          </VStack>
          <Spacer />
          <NumericFormat
            value={Math.abs(wallet.balance)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value) => <Text fontSize="xl">{value}</Text>}
          />
        </Flex>
        {wallet.limit !== null && wallet.limit !== undefined && (
          <NumericFormat
            value={Math.abs(wallet.limit)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value) => (
              <Text w="100%" textAlign="right" fontSize="xs" color="gray.400">
                {value}
              </Text>
            )}
          />
        )}
        {wallet.limit !== null && wallet.limit !== undefined && (
          <Progress
            w="100%"
            borderRadius="xl"
            value={(Math.abs(wallet.balance) / wallet.limit) * 100}
            bg={useColorModeValue("dark.200", "dark.600")}
            colorScheme={
              (Math.abs(wallet.balance) / wallet.limit) * 100 < 30
                ? "green"
                : (Math.abs(wallet.balance) / wallet.limit) * 100 < 70
                ? "orange"
                : "red"
            }
            size="sm"
            mt="0px"
          />
        )}
      </VStack>
    </Pressable>
  );
}

const areEqual = (prevProps: WalletItemProps, nextProps: WalletItemProps) => {
  if (JSON.stringify(prevProps.wallet) !== JSON.stringify(nextProps.wallet)) {
    return false;
  }
  return true;
};

export default React.memo(WalletItem, areEqual);
