import { useMutation } from "@tanstack/react-query";
import { AuthenticateDto, TokenDto } from "./types";
import { api } from "./api";

export async function authenticateApi(input: AuthenticateDto) {
  const result = await api.post("users/authenticate", input);
  return result.data;
}

export default function useAuthenticate() {
  return useMutation<TokenDto, unknown, AuthenticateDto>(
    ["authenticate"],
    authenticateApi
  );
}
