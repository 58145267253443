import * as React from "react";
import moment from "moment";
import { Dimensions } from "react-native";
import {
  Box,
  Button,
  Center,
  Flex,
  Icon,
  IconButton,
  Skeleton,
  Text,
  useColorModeValue,
  useTheme,
  VStack,
} from "native-base";
import { NumericFormat } from "react-number-format";
import Svg, {
  Circle,
  ForeignObject,
  G,
  Line,
  Text as SvgText,
} from "react-native-svg";
import {
  VictoryPie,
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
} from "victory-native";
import AntDesign from "@expo/vector-icons/AntDesign";
import Emoji from "./Emoji";
import useGetCategoriesChart from "../hooks/useGetCategoriesChart";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { updateDate } from "../store/slices/settingsSlice";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";
import Animated, {
  SharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";

const RADIAN = Math.PI / 180;
function Label(props: any) {
  const midAngle = (props.datum.endAngle + props.datum.startAngle) / 2;
  const x1 = props.x + 12 * Math.sin(-midAngle * RADIAN);
  const x2 = props.x + 30 * Math.sin(-midAngle * RADIAN);
  const y1 = props.y + 12 * Math.cos(-midAngle * RADIAN);
  const y2 = props.y + 30 * Math.cos(-midAngle * RADIAN);
  return (
    <G>
      <Circle cx={props.x} cy={props.y} r={12} fill={props.datum.color} />
      <Line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={props.datum.color}
        strokeWidth={1}
        strokeOpacity="0.3"
      />
      <ForeignObject x={props.x - 9} y={props.y - 11} width={20} height={20}>
        <Emoji
          name={props.datum.category.icon}
          style={{
            fontSize: 15,
            width: 20,
            height: 20,
          }}
        />
      </ForeignObject>
      {props.datum.index === props.activeIndex && (
        <G>
          <SvgText
            x={props.width / 2}
            y={props.height / 2 - 10}
            textAnchor="middle"
            fill={props.datum.color}
          >
            {props.datum.category.title.length > 15
              ? `${props.datum.category.title.substring(0, 15)}...`
              : props.datum.category.title}
          </SvgText>
          <NumericFormat
            value={Math.floor(Math.abs(props.datum.value) * 100) / 100}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value) => (
              <SvgText
                x={props.width / 2}
                y={props.height / 2 + 10}
                textAnchor="middle"
                fontWeight="bold"
                fontSize={16}
                fill={props.datum.color}
              >
                {value}
              </SvgText>
            )}
          />
        </G>
      )}
    </G>
  );
}

type CategoryChartProps = {
  categoryType: any;
  setCategoryType: (value: string) => void;
  scrollValue: SharedValue<number>;
};

export function CategoryChart(props: CategoryChartProps) {
  const { categoryType, setCategoryType, scrollValue } = props;

  const { t } = useTranslation();

  const screenWidth = Dimensions.get("window").width;

  const dispatch = useDispatch();
  const year = useSelector((state: RootState) => state.settings.year);
  const month = useSelector((state: RootState) => state.settings.month);

  const [activeIndex, setActiveIndex] = React.useState(0);
  const currentDate = moment({
    year,
    month,
  });
  const prevDate = currentDate.clone().subtract({ month: 1 });
  const nextDate = currentDate.clone().add({ month: 1 });

  const handleNext = () => {
    dispatch(
      updateDate({
        year: nextDate.year(),
        month: nextDate.month(),
      })
    );
    setActiveIndex(0);
  };

  const handlePrev = () => {
    dispatch(
      updateDate({
        year: prevDate.year(),
        month: prevDate.month(),
      })
    );
    setActiveIndex(0);
  };

  const { colors } = useTheme();
  const pieColors = [
    colors.red[500],
    colors.blue[500],
    colors.orange[400],
    colors.green[500],
    colors.pink[400],
    colors.cyan[400],
    colors.yellow[400],
    colors.teal[500],
  ];

  const { data, isLoading, refetch } = useGetCategoriesChart({
    year,
    month: month !== undefined ? month + 1 : undefined,
    reverse: true,
    type: categoryType,
  });

  React.useEffect(() => {
    refetch();
  }, [year, month, categoryType]);

  const chartData =
    data?.map((c, index) => ({
      ...c,
      index: index,
      color: pieColors[index % pieColors.length],
    })) ?? [];

  const buttonColorScheme = useColorModeValue("primary", "dark");

  const animatedStyles = useAnimatedStyle(() => {
    return {
      height: Math.min(Math.max(290 - scrollValue.value, 50), 290),
    };
  });

  return (
    <Animated.View style={[{ overflow: "hidden" }, animatedStyles]}>
      <VStack w="100%" p="10px 10px">
        <Flex
          w="100%"
          alignItems="center"
          justifyContent="center"
          direction="row"
        >
          <IconButton
            colorScheme={buttonColorScheme}
            aria-label="Prev"
            size="md"
            borderRadius="full"
            variant="solid"
            icon={<Icon as={AntDesign} name="caretleft" size="xs" />}
            onPress={handlePrev}
          />
          <Text fontSize="xl" textAlign="center" p="0px 20px" flex="1">
            {currentDate.format("MMM YYYY")}
          </Text>
          <IconButton
            colorScheme={buttonColorScheme}
            aria-label="Next"
            size="md"
            borderRadius="full"
            variant="solid"
            icon={<Icon as={AntDesign} name="caretright" size="xs" />}
            onPress={handleNext}
            isDisabled={nextDate > moment()}
          />
        </Flex>
        <Box w="100%" h="240px" flex={1}>
          {isLoading && (
            <Center w="100%" pt="45px">
              <Skeleton
                startColor="dark.800"
                endColor="dark.600"
                size="40"
                rounded="full"
              />
            </Center>
          )}
          {!isLoading && (
            <>
              <Flex
                position="absolute"
                alignContent="center"
                justifyContent="center"
                h="240px"
                ml="-20px"
                zIndex={1000}
              >
                <Button
                  style={{ transform: [{ rotate: "90deg" }] }}
                  size="sm"
                  onPress={() => setCategoryType("expenses")}
                  colorScheme={buttonColorScheme}
                  variant={categoryType === "expenses" ? "solid" : "ghost"}
                >
                  {t(TranslationKeys.Expenses)}
                </Button>
                <Button
                  style={{ transform: [{ rotate: "90deg" }] }}
                  mt="40px"
                  size="sm"
                  onPress={() => setCategoryType("income")}
                  colorScheme={buttonColorScheme}
                  variant={categoryType === "income" ? "solid" : "ghost"}
                >
                  {t(TranslationKeys.Income)}
                </Button>
              </Flex>
              <VictoryChart
                width={screenWidth - 50}
                height={250}
                theme={VictoryTheme.material}
                containerComponent={<Svg />}
              >
                <VictoryPie
                  data={chartData}
                  x="title"
                  y="value"
                  innerRadius={60}
                  labelComponent={
                    <Label
                      activeIndex={activeIndex}
                      setActiveIndex={setActiveIndex}
                    />
                  }
                  labelRadius={() => 100}
                  style={{
                    data: {
                      fill: ({ data, index }: any) => {
                        return data[index].color ?? "red";
                      },
                      stroke: 0,
                    },
                  }}
                  events={[
                    {
                      target: "data",
                      eventHandlers: {
                        onPress: () => {
                          return [
                            {
                              target: "labels",
                              mutation: (props) => {
                                setActiveIndex(props.datum.index);
                              },
                            },
                          ];
                        },
                        onClick: () => {
                          return [
                            {
                              target: "labels",
                              mutation: (props) => {
                                setActiveIndex(props.datum.index);
                              },
                            },
                          ];
                        },
                      },
                    },
                  ]}
                />
                <VictoryAxis
                  style={{
                    axis: { stroke: "transparent" },
                    ticks: { stroke: "transparent" },
                    tickLabels: { fill: "transparent" },
                    grid: { stroke: "transparent" },
                  }}
                />
              </VictoryChart>
            </>
          )}
        </Box>
      </VStack>
    </Animated.View>
  );
}
