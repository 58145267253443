import {
  Actionsheet,
  Box,
  Center,
  Flex,
  Icon,
  IconButton,
  Image,
  Input,
  Link,
  Modal,
  Pressable,
  Switch,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import * as React from "react";
import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import { EmojiPicker } from "./EmojiPicker";
import Emoji from "./Emoji";
import { CompanyDto } from "../hooks/types";
import useGetCompanies from "../hooks/useGetCompanies";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";

type CompanyPickerProps = {
  value?: CompanyDto;
  onChange: (value: CompanyDto) => void;
  placeHolder?: string;
};

function CompanyPicker(props: CompanyPickerProps) {
  const { value, onChange, placeHolder } = props;
  const [open, setOpen] = React.useState(false);
  const [custom, setCustom] = React.useState(false);
  const [customCompany, setCustomCompany] = React.useState<CompanyDto>({
    name: "",
    domain: undefined,
    image: "taxi",
  });

  const { t } = useTranslation();

  React.useEffect(() => {
    if (value && !value?.image.startsWith("http")) {
      setCustom(true);
      setCustomCompany(value);
    }
  }, [value]);

  const [query, setQuery] = React.useState<string | undefined>(undefined);

  const { data, refetch } = useGetCompanies(query);

  React.useEffect(() => {
    refetch();
  }, [query]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value: CompanyDto) => () => {
    onChange(value);
    handleClose();
  };

  const handleCustomSelect = () => {
    onChange(customCompany);
    handleClose();
  };

  const addButtonColorScheme = useColorModeValue("green", "dark");
  const bg = useColorModeValue("white", "dark.700");

  return (
    <>
      <Modal
        isOpen={open}
        onClose={handleClose}
        size="xl"
        animationPreset="slide"
        avoidKeyboard={true}
      >
        <Modal.Content borderRadius="3xl" bg={bg}>
          <Modal.CloseButton />
          <Modal.Header borderBottomWidth={0} bg={bg}>
            <Flex w="100%" direction="row" alignItems="center">
              <Text fontSize="md">{t(TranslationKeys.Custom)}</Text>
              <Switch
                size="md"
                onValueChange={setCustom}
                value={custom}
                ml={2}
              />
            </Flex>
          </Modal.Header>
          <Modal.Body>
            {custom && (
              <VStack
                w="100%"
                h={220}
                px={4}
                justifyContent="center"
                alignItems="flex-end"
                space={5}
              >
                <Input
                  w="100%"
                  placeholder={t(TranslationKeys.Name) as string}
                  size="lg"
                  value={customCompany.name}
                  onChangeText={(v) => {
                    setCustomCompany({ ...customCompany, name: v });
                  }}
                />
                <Input
                  w="100%"
                  placeholder={t(TranslationKeys.Description) as string}
                  size="lg"
                  value={customCompany.domain}
                  onChangeText={(v) => {
                    setCustomCompany({ ...customCompany, domain: v });
                  }}
                />
                <EmojiPicker
                  value={customCompany.image}
                  onChange={(v) => {
                    setCustomCompany({ ...customCompany, image: v });
                  }}
                />
                <Center w="100%">
                  <IconButton
                    colorScheme={addButtonColorScheme}
                    variant="solid"
                    size="lg"
                    borderRadius="full"
                    icon={<Icon as={Ionicons} name="checkmark" size="sm" />}
                    onPress={handleCustomSelect}
                  />
                </Center>
              </VStack>
            )}
            {!custom && (
              <Box
                w="100%"
                h={60}
                justifyContent="center"
                alignItems="flex-end"
              >
                <Input
                  w="100%"
                  value={query}
                  onChangeText={setQuery}
                  placeholder={t(TranslationKeys.Search) as string}
                  size="lg"
                />
                <Link
                  href="https://clearbit.com"
                  isUnderlined={false}
                  fontSize={12}
                  _text={{
                    color: "dark.400",
                  }}
                >
                  Powered by Clearbit
                </Link>
              </Box>
            )}
            {!custom &&
              data?.map((company: CompanyDto) => (
                <Actionsheet.Item
                  key={company.domain}
                  onPress={handleSelect(company)}
                  py={2}
                  bg={bg}
                >
                  <Flex alignItems="center" w="100%" direction="row">
                    <Box bg="white" borderRadius="full">
                      <Image
                        source={{ uri: company.image }}
                        size="12"
                        resizeMode={"contain"}
                        borderRadius="full"
                        alt={company.name}
                        overflow="hidden"
                      />
                    </Box>

                    <Text pl="10px" fontSize="14">
                      {company.name}
                    </Text>
                    <Text pl="10px" fontSize="10" color="dark.400">
                      {company.domain}
                    </Text>
                  </Flex>
                </Actionsheet.Item>
              ))}
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Input
        w="100%"
        value={value?.name}
        placeholder={placeHolder || (t(TranslationKeys.Company) as string)}
        size="lg"
        borderRadius={"full"}
        InputLeftElement={
          value ? (
            <Box bg="white" borderRadius="full" ml="10px">
              {value.image.startsWith("http") && (
                <Image
                  source={{ uri: value.image }}
                  size="8"
                  resizeMode={"contain"}
                  borderRadius="full"
                  alt={value.name}
                />
              )}
              {!value.image.startsWith("http") && (
                <Emoji name={value.image} fontSize="sm" w={6} h={6} pl={1} />
              )}
            </Box>
          ) : (
            <Icon as={FontAwesome5} name="suitcase" size="sm" ml="10px" />
          )
        }
        isReadOnly
        onPressOut={() => handleOpen()}
      />
      <Pressable
        onPress={() => handleOpen()}
        h="40px"
        w="100%"
        position="relative"
        mt="-40px"
      ></Pressable>
    </>
  );
}

const areEqual = (
  prevProps: CompanyPickerProps,
  nextProps: CompanyPickerProps
) => {
  if (prevProps.value !== nextProps.value) {
    return false;
  }
  if (prevProps.placeHolder !== nextProps.placeHolder) {
    return false;
  }
  return true;
};

export default React.memo(CompanyPicker, areEqual);
