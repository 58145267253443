import * as React from "react";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { VictoryArea, VictoryChart, VictoryAxis } from "victory-native";
import {
  Box,
  Divider,
  HStack,
  Text,
  useTheme,
  View,
  VStack,
} from "native-base";
import { useWindowDimensions } from "react-native";
import useGetTransactions from "../hooks/useGetTransactions";
import useGetWallets from "../hooks/useGetWallets";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";

const filterTransactionsByDay = (
  day: number,
  transactions?: { time: string; amount: number }[]
) => {
  return transactions?.filter(
    (transaction) => moment(transaction.time).date() === day
  );
};

const generateChartData = (
  amount: number,
  transactions?: { time: string; amount: number }[]
) => {
  const chartData: Record<string, any> = {};
  const today = moment();
  let date = today.clone().startOf("day");
  let balance = amount;
  while (date.month() === today.month()) {
    const key = date.format("YYYYMMDD");
    const keyTransactions = filterTransactionsByDay(date.date(), transactions);
    chartData[key] = {
      key,
      value: Math.abs(balance),
    };
    balance =
      balance -
      Math.floor(
        keyTransactions?.reduce(
          (total, transaction) => total + transaction.amount * 100,
          0
        ) ?? 0
      ) /
        100;
    date = date.clone().subtract({ days: 1 });
  }
  return Object.values(chartData);
};

export function WalletsOverview() {
  const { data } = useGetWallets();

  const { t } = useTranslation();

  const { width } = useWindowDimensions();

  const date = moment();
  const { data: transactionsData } = useGetTransactions({
    year: date.year(),
    month: date.month() + 1,
    reverse: true,
  });

  const transactions =
    transactionsData?.nodes.reduce<any[]>((transactions, transaction) => {
      if (transaction.category?.type === "transfer") {
        return [
          ...transactions,
          transaction,
          {
            ...transaction,
            amount: -1 * transaction.amount,
            wallet: transaction.toWallet,
            toWallet: transaction.wallet,
          },
        ];
      } else {
        return [...transactions, transaction];
      }
    }, []) ?? [];

  const assets =
    Math.floor(
      data
        ?.filter((wallet) => wallet.type !== "Credit Card")
        .reduce((total, wallet) => total + wallet.balance * 100, 0) ?? 0
    ) / 100;
  const assetsTransactions = transactions.filter(
    (transaction) => transaction.wallet?.type !== "Credit Card"
  );
  const assetsChartData = generateChartData(assets ?? 0, assetsTransactions);

  const liabilities =
    Math.floor(
      data
        ?.filter((wallet) => wallet.type === "Credit Card")
        .reduce((total, wallet) => total + wallet.balance * 100, 0) ?? 0
    ) / 100;
  const liabilitiesTransactions = transactions.filter(
    (transaction) => transaction.wallet?.type === "Credit Card"
  );
  const liabilitiesChartData = generateChartData(
    liabilities ?? 0,
    liabilitiesTransactions
  );

  const { colors } = useTheme();

  return (
    <VStack w="100%" p="10px 10px">
      <HStack
        w="100%"
        divider={<Divider borderColor="gray.200" orientation="vertical" />}
        space={4}
        alignItems="stretch"
      >
        <Box flex="1" p="5px">
          <Text fontSize="sm" textAlign="center">
            {t(TranslationKeys.Assets)}
          </Text>
          <NumericFormat
            value={Math.abs(assets ?? 0)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value) => (
              <Text
                fontSize="xl"
                fontWeight="bold"
                color="green.500"
                textAlign="center"
              >
                {value}
              </Text>
            )}
          />

          <View
            w="100%"
            h="50px"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <VictoryChart
              width={width / 2 - 50}
              height={50}
              padding={{ top: 10, left: 5 }}
            >
              <VictoryArea
                data={assetsChartData}
                style={{
                  data: {
                    fill: colors.green[200],
                    stroke: colors.green[700],
                  },
                }}
                y="value"
                x="key"
              />
              <VictoryAxis
                style={{
                  axis: { stroke: "transparent" },
                  ticks: { stroke: "transparent" },
                  tickLabels: { fill: "transparent" },
                  grid: { stroke: "transparent" },
                }}
              />
            </VictoryChart>
          </View>
        </Box>
        <Box flex="1" p="5px">
          <Text fontSize="sm" textAlign="center">
            {t(TranslationKeys.Liabilities)}
          </Text>
          <NumericFormat
            value={Math.abs(liabilities ?? 0)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value) => (
              <Text
                fontSize="xl"
                fontWeight="bold"
                color="red.500"
                textAlign="center"
              >
                {value}
              </Text>
            )}
          />

          <View
            w="100%"
            h="50px"
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <VictoryChart
              width={width / 2 - 50}
              height={50}
              padding={{ top: 10, left: 5 }}
            >
              <VictoryArea
                data={liabilitiesChartData}
                style={{
                  data: { fill: colors.red[300], stroke: colors.red[600] },
                }}
                interpolation={"basis"}
                y="value"
                x="key"
              />
              <VictoryAxis
                style={{
                  axis: { stroke: "transparent" },
                  ticks: { stroke: "transparent" },
                  tickLabels: { fill: "transparent" },
                  grid: { stroke: "transparent" },
                }}
              />
            </VictoryChart>
          </View>
        </Box>
      </HStack>
    </VStack>
  );
}
