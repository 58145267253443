import {
  Button,
  Flex,
  Icon,
  Input,
  Modal,
  Pressable,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import * as React from "react";
import { Ionicons } from "@expo/vector-icons";
import Emoji from "./Emoji";
import useGetCategories from "../hooks/useGetCategories";
import useGetTopCategories from "../hooks/useGetTopCategories";
import { TranslationKeys } from "../translations/keys";
import { useTranslation } from "react-i18next";

type CategoryPickerProps = {
  value: {
    id: string;
    type: string;
  };
  onChange: (value: { id: string; type: string }) => void;
  parentsOnly?: boolean;
};

function CategoryPicker(props: CategoryPickerProps) {
  const { value, onChange, parentsOnly = false } = props;
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState<string | undefined>(undefined);

  const { t } = useTranslation();

  const categories = useGetCategories();

  const mostUsedCategories = useGetTopCategories({
    limit: 30,
  });

  const allCategories = [
    ...(mostUsedCategories.data || []),
    ...(categories.data || []),
  ];

  const selectedCategory = allCategories
    .reduce<any[]>(
      (list, category) =>
        category.children
          ? [...list, category, ...category.children]
          : [...list, category],
      []
    )
    .find((category) => category.id === value.id);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (value: { id: string; type: string }) => () => {
    onChange(value);
    handleClose();
  };

  const bgColor = useColorModeValue("dark.50", "dark.800");

  return (
    <>
      <Modal
        isOpen={open}
        onClose={handleClose}
        animationPreset="slide"
        avoidKeyboard={true}
        size="xl"
      >
        <Modal.Content
          borderRadius="3xl"
          bg={useColorModeValue("white", "dark.700")}
          maxH="50%"
        >
          <Modal.Header>
            <Flex w="100%" direction="row" alignItems="center">
              <Input
                w="100%"
                value={query}
                onChangeText={setQuery}
                placeholder={t(TranslationKeys.Search) as string}
                size="lg"
                borderRadius={"full"}
              />
            </Flex>
          </Modal.Header>
          <Modal.Body>
            <VStack>
              {parentsOnly === false &&
                [
                  {
                    id: "most-used-categories",
                    title: t(TranslationKeys.MostUsed),
                    icon: "star",
                  },
                ].map((parent) => {
                  const children = mostUsedCategories.data
                    ?.filter((category) =>
                      query
                        ? category.title
                            .toLowerCase()
                            .includes(query.toLowerCase())
                        : true
                    )
                    .map((category) => (
                      <Pressable
                        key={category.id}
                        onPress={handleSelect(category)}
                      >
                        <Flex
                          alignItems="center"
                          w="100%"
                          pl="20px"
                          py="10px"
                          mb="2px"
                          bg={bgColor}
                          borderRadius="lg"
                          direction="row"
                        >
                          <Emoji
                            name={category.icon}
                            style={{ fontSize: 15 }}
                          />
                          <Text pl="10px">{category.title}</Text>
                        </Flex>
                      </Pressable>
                    ));
                  return [
                    <Flex
                      key={parent.id}
                      alignItems="center"
                      w="100%"
                      py="10px"
                      direction="row"
                    >
                      <Emoji name={parent.icon} fontSize="lg" />
                      <Text pl="10px" fontSize="xl">
                        {parent.title}
                      </Text>
                    </Flex>,
                    children,
                  ];
                })}
              {categories.data
                ?.filter((category) =>
                  query
                    ? category.children.filter((child) =>
                        query
                          ? child.title
                              .toLowerCase()
                              .includes(query.toLowerCase())
                          : true
                      ).length > 0
                    : true
                )
                .map((category) => {
                  if (
                    parentsOnly === false &&
                    category.children &&
                    category.children.length > 0
                  ) {
                    const children = category.children
                      .filter((child) =>
                        query
                          ? child.title
                              .toLowerCase()
                              .includes(query.toLowerCase())
                          : true
                      )
                      .map((child) => (
                        <Pressable key={child.id} onPress={handleSelect(child)}>
                          <Flex
                            alignItems="center"
                            w="100%"
                            pl="20px"
                            py="10px"
                            mb="2px"
                            bg={bgColor}
                            borderRadius="lg"
                            direction="row"
                          >
                            <Emoji name={child.icon} fontSize="lg" />
                            <Text pl="10px">{child.title}</Text>
                          </Flex>
                        </Pressable>
                      ));
                    return [
                      <Flex
                        key={category.id}
                        alignItems="center"
                        w="100%"
                        py="10px"
                        direction="row"
                      >
                        <Emoji name={category.icon} fontSize="lg" />
                        <Text pl="10px" fontSize="xl">
                          {category.title}
                        </Text>
                      </Flex>,
                      children,
                    ];
                  } else {
                    return (
                      <Pressable
                        key={category.id}
                        onPress={handleSelect(category)}
                      >
                        <Flex
                          alignItems="center"
                          w="100%"
                          py="10px"
                          bg={bgColor}
                          direction="row"
                        >
                          <Emoji name={category.icon} fontSize="lg" />
                          <Text pl="10px">{category.title}</Text>
                        </Flex>
                      </Pressable>
                    );
                  }
                })}
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
      <Input
        w="100%"
        value={selectedCategory ? selectedCategory.title : ""}
        placeholder={t(TranslationKeys.Category) as string}
        size="lg"
        borderRadius={"full"}
        InputLeftElement={
          selectedCategory ? (
            <Emoji
              name={selectedCategory.icon}
              style={{ fontSize: 15, marginLeft: 10 }}
            />
          ) : (
            <Icon as={Ionicons} name="pricetags" size="sm" ml="10px" />
          )
        }
        isReadOnly
      />
      <Pressable
        onPress={() => handleOpen()}
        h="40px"
        w="100%"
        position="relative"
        mt="-40px"
      ></Pressable>
    </>
  );
}

const areEqual = (
  prevProps: CategoryPickerProps,
  nextProps: CategoryPickerProps
) => {
  if (prevProps.value?.id !== nextProps.value?.id) {
    return false;
  }
  if (prevProps.parentsOnly !== nextProps.parentsOnly) {
    return false;
  }
  return true;
};

export default React.memo(CategoryPicker, areEqual);
