import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TransactionDto } from "./types";
import { api } from "./api";
import { RootState } from "../store/store";
import { useSelector } from "react-redux";

export default function useDeleteTransaction() {
  const token = useSelector((state: RootState) => state.auth.token);
  const accountId = useSelector((state: RootState) => state.auth.accountId);
  const queryClient = useQueryClient();

  const query = async (id: string) => {
    const result = await api.delete(
      `accounts/${accountId}/transactions/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return result.data;
  };
  return useMutation<TransactionDto, unknown, string>(
    ["deleteTransaction"],
    query,
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["categoriesChart"],
        });
        queryClient.invalidateQueries({
          queryKey: ["categoriesOverview"],
        });
        queryClient.invalidateQueries({
          queryKey: ["topCategories"],
        });
        queryClient.invalidateQueries({
          queryKey: ["groupedTransactions"],
        });
        queryClient.invalidateQueries({
          queryKey: ["transactionsOverview"],
        });
        queryClient.invalidateQueries({
          queryKey: ["transactions"],
        });
        queryClient.invalidateQueries({
          queryKey: ["wallets"],
        });
      },
    }
  );
}
