import * as React from "react";
import { Box, Divider, Flex, HStack, Switch, Text, VStack } from "native-base";
import useGetCategories from "../hooks/useGetCategories";

type CategoryCountProps = {
  childrenMode: boolean;
  setChildrenMode: (childrenMode: boolean) => void;
};

export function CategoryCount(props: CategoryCountProps) {
  const { childrenMode, setChildrenMode } = props;

  const { data } = useGetCategories();

  const flatCategories =
    data?.reduce<any[]>((categories, category) => {
      categories.push(category, ...(category.children || []));
      return categories;
    }, []) || [];

  return (
    <VStack w="100%" p="10px 10px">
      <HStack
        w="100%"
        divider={<Divider borderColor="gray.200" orientation="vertical" />}
        space={4}
        alignItems="stretch"
      >
        <Box flex="1" p="5px">
          <Text fontSize="sm" textAlign="center">
            Income
          </Text>
          <Text
            fontSize="xl"
            fontWeight="bold"
            color="green.500"
            textAlign="center"
          >
            {
              flatCategories.filter((category) => category.type === "income")
                .length
            }
          </Text>
        </Box>
        <Box flex="1" p="5px">
          <Text fontSize="sm" textAlign="center">
            Expenses
          </Text>
          <Text
            fontSize="xl"
            fontWeight="bold"
            color="red.500"
            textAlign="center"
          >
            {
              flatCategories.filter((category) => category.type === "expenses")
                .length
            }
          </Text>
        </Box>
        <Box flex="1" p="5px">
          <Text fontSize="sm" textAlign="center">
            Transfer
          </Text>
          <Text
            fontSize="xl"
            fontWeight="bold"
            color="blue.500"
            textAlign="center"
          >
            {
              flatCategories.filter((category) => category.type === "transfer")
                .length
            }
          </Text>
        </Box>
      </HStack>
      <Flex
        w="100%"
        pt={5}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize="md">Show subcategories</Text>
        <Switch
          size="md"
          onToggle={() => setChildrenMode(!childrenMode)}
          value={childrenMode}
          ml={2}
        />
      </Flex>
    </VStack>
  );
}
