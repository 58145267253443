import * as React from "react";
import {
  Flex,
  Icon,
  Pressable,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import * as Haptics from "expo-haptics";
import Emoji from "./Emoji";
import { Platform } from "react-native";
import { CategoryDto } from "../hooks/types";

type CategoryItemProps = {
  category: CategoryDto;
  handleOpen: (category: CategoryDto) => () => void;
  handleDrag: () => void;
  visible?: boolean;
  dragHandleVisible?: boolean;
};

function CategoryItem(props: CategoryItemProps) {
  const { category, handleOpen, handleDrag, visible, dragHandleVisible } =
    props;

  return (
    <VStack w="100%" pl={(category.parentId ? 1 : 0) * 5}>
      {visible && (
        <Pressable
          p={1}
          onLongPress={() => {
            if (dragHandleVisible) {
              if (Platform.OS !== "web") {
                Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
              }
              handleDrag();
            }
          }}
        >
          <Flex
            w="100%"
            alignItems="center"
            bg={useColorModeValue("white", "dark.700")}
            shadow={3}
            borderRadius="xl"
            p={"10px"}
            direction="row"
          >
            {dragHandleVisible && (
              <Icon as={MaterialIcons} name="drag-indicator" size="md" mr={4} />
            )}
            <Emoji name={category.icon} fontSize="lg" />
            <Flex alignItems="center" flex={1} direction="row">
              <VStack space={0} marginLeft="10px" alignItems="flex-start">
                <Text fontSize="xs">{category.title}</Text>
              </VStack>
            </Flex>
          </Flex>
        </Pressable>
      )}
    </VStack>
  );
}

const areEqual = (
  prevProps: CategoryItemProps,
  nextProps: CategoryItemProps
) => {
  if (
    prevProps.category.id === nextProps.category.id &&
    prevProps.visible === nextProps.visible
  ) {
    return true;
  }
  return false;
};

export default React.memo(CategoryItem, areEqual);
