import { Text, ITextProps } from "native-base";
import * as React from "react";
import { getEmoji } from "../utils/getEmoji";

type EmojiProps = {
  name: string;
} & ITextProps;

function Emoji(props: EmojiProps) {
  const { name, ...textProps } = props;

  return <Text {...textProps}>{getEmoji(name)}</Text>;
}

const areEqual = (prevProps: EmojiProps, nextProps: EmojiProps) => {
  if (prevProps.name === nextProps.name) {
    return true;
  }
  return false;
};

export default React.memo(Emoji, areEqual);
