import { useQuery } from "@tanstack/react-query";
import { GetOverviewDto, OverviewDto } from "./types";
import { api } from "./api";
import { RootState, store } from "../store/store";
import { useSelector } from "react-redux";
import { logout } from "../store/slices/authSlice";

export default function useGetOverview(qs: GetOverviewDto) {
  const token = useSelector((state: RootState) => state.auth.token);
  const accountId = useSelector((state: RootState) => state.auth.accountId);

  const query = async () => {
    const result = await api.get(
      `accounts/${accountId}/transactions/overview`,
      {
        params: qs,
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return result.data;
  };
  return useQuery<OverviewDto>(["transactionsOverview"], query, {
    onError: (error: any) => {
      if (error.response.status === 401) {
        store.dispatch(logout());
      }
    },
  });
}
