import {
  Flex,
  Icon,
  Pressable,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import * as React from "react";
import { Ionicons } from "@expo/vector-icons";
import { FlashList } from "@shopify/flash-list";

import WalletItem from "./WalletItem";
import WalletModal from "./WalletModal";
import useGetWallets from "../hooks/useGetWallets";
import { WalletDto } from "../hooks/types";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

type WalletsProps = {
  onScroll: (value: number) => void;
};

export function Wallets(props: WalletsProps) {
  const { onScroll } = props;

  const [wallet, setWallet] = React.useState<WalletDto>();
  const [open, setOpen] = React.useState(false);

  const animation = useSelector((state: RootState) => state.settings.animation);

  const { t } = useTranslation();

  const handleOpen = (wallet?: WalletDto) => {
    if (wallet) {
      setWallet(wallet);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setWallet(undefined);
  };

  const { data, refetch, isRefetching, isLoading, isError } = useGetWallets();

  const onRefresh = () => {
    refetch();
  };

  if (isLoading) return <Text>Loading...</Text>;
  if (isError) return <Text>An error occurred while fetching data</Text>;

  const wallets = data?.sort(
    (a, b) => Math.abs(b.balance) - Math.abs(a.balance)
  );

  return (
    <>
      <WalletModal open={open} handleClose={handleClose} wallet={wallet} />
      <FlashList
        contentContainerStyle={{ paddingTop: 165 }}
        keyExtractor={(item: WalletDto) => item.id}
        data={wallets}
        renderItem={({ item }) => (
          <WalletItem key={item.id} wallet={item} handleOpen={handleOpen} />
        )}
        estimatedItemSize={10}
        refreshing={isRefetching}
        onRefresh={onRefresh}
        ListFooterComponent={
          <Pressable onPress={() => handleOpen()} mb="130px" px="20px">
            <VStack
              w="100%"
              alignItems="center"
              bg={useColorModeValue("white", "dark.700")}
              shadow={6}
              borderRadius="xl"
              p={"20px"}
            >
              <Flex w="100%" alignItems="center" direction="row">
                <Icon as={Ionicons} name="add" size="md" />
                <Text fontSize="md">{t(TranslationKeys.AddANewWallet)}</Text>
              </Flex>
            </VStack>
          </Pressable>
        }
        showsVerticalScrollIndicator={false}
        onScroll={(e) => {
          if (animation) {
            onScroll(e.nativeEvent.contentOffset.y);
          }
        }}
      />
    </>
  );
}
