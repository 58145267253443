import React from "react";
import { useColorModeValue, View } from "native-base";
import { HeaderCard } from "../components/HeaderCard";
import { Profile } from "../components/Profile";
import { SettingsMenu } from "../components/SettingsMenu";

export default function SettingsMainScreen(props: any) {
  return (
    <View bg={useColorModeValue("tertiary.50", "tertiary.900")} flex={1}>
      <HeaderCard content={<Profile />} />
      <SettingsMenu navigation={props.navigation} />
    </View>
  );
}
