import { TranslationKeys, TranslationObject } from "./keys";

export const fa: TranslationObject = {
  [TranslationKeys.Wallets]: "کیف‌پول‌ها",
  [TranslationKeys.Categories]: "دسته‌بندی‌ها",
  [TranslationKeys.Transactions]: "تراکنش‌ها",
  [TranslationKeys.Transaction]: "تراکنش‌",
  [TranslationKeys.Income]: "درآمد",
  [TranslationKeys.Expenses]: "هزینه‌ها",
  [TranslationKeys.AddTransaction]: "افزودن تراکنش",
  [TranslationKeys.EditTransaction]: "ویرایش تراکنش",
  [TranslationKeys.Amount]: "مبلغ",
  [TranslationKeys.Category]: "دسته‌بندی",
  [TranslationKeys.Title]: "عنوان",
  [TranslationKeys.Wallet]: "کیف‌پول",
  [TranslationKeys.ToWallet]: "به کیف‌پول",
  [TranslationKeys.Chart]: "نمودار",
  [TranslationKeys.Assets]: "دارایی‌ها",
  [TranslationKeys.Liabilities]: "بدهی‌ها",
  [TranslationKeys.AddANewWallet]: "افزودن کیف‌پول جدید",
  [TranslationKeys.CreditCard]: "کارت اعتباری",
  [TranslationKeys.CheckingAccount]: "حساب جاری",
  [TranslationKeys.SavingsAccount]: "حساب پس‌انداز",
  [TranslationKeys.Balance]: "موجودی",
  [TranslationKeys.Limit]: "محدودیت",
  [TranslationKeys.LastFourDigits]: "چهار رقم آخر",
  [TranslationKeys.AddWallet]: "افزودن کیف‌پول",
  [TranslationKeys.EditWallet]: "ویرایش کیف‌پول",
  [TranslationKeys.Reminders]: "یادآوری‌ها",
  [TranslationKeys.Reminder]: "یادآوری‌",
  [TranslationKeys.AddANewReminder]: "افزودن یادآوری جدید",
  [TranslationKeys.AddReminder]: "افزودن یادآوری",
  [TranslationKeys.EditReminder]: "ویرایش یادآوری",
  [TranslationKeys.Type]: "نوع",
  [TranslationKeys.Subscription]: "اشتراک",
  [TranslationKeys.Bill]: "صورت‌حساب",
  [TranslationKeys.Mortgage]: "وام مسکن",
  [TranslationKeys.Installment]: "قسط",
  [TranslationKeys.Recurring]: "مداوم",
  [TranslationKeys.Weekly]: "هفتگی",
  [TranslationKeys.Monthly]: "ماهانه",
  [TranslationKeys.Yearly]: "سالانه",
  [TranslationKeys.On]: "در",
  [TranslationKeys.RemindMe]: "یادآوری کن",
  [TranslationKeys.Day]: "روز",
  [TranslationKeys.Week]: "هفته",
  [TranslationKeys.Month]: "ماه",
  [TranslationKeys.Notification]: "اطلاعیه",
  [TranslationKeys.Company]: "شرکت",
  [TranslationKeys.Custom]: "سفارشی",
  [TranslationKeys.Search]: "جستجو...",
  [TranslationKeys.Name]: "نام",
  [TranslationKeys.Description]: "توضیحات",
  [TranslationKeys.Settings]: "تنظیمات",
  [TranslationKeys.Light]: "روشن",
  [TranslationKeys.Dark]: "تیره",
  [TranslationKeys.GoldMembership]: "عضویت طلایی",
  [TranslationKeys.SignOut]: "خروج",
  [TranslationKeys.Home]: "خانه",
  [TranslationKeys.SignIn]: "ورود",
  [TranslationKeys.SignUp]: "ثبت نام",
  [TranslationKeys.MostUsed]: "بیشترین استفاده",
  [TranslationKeys.NextOn]: "بعدی در",
  [TranslationKeys.Before]: "قبل",
  [TranslationKeys.Days]: "روزها",
  [TranslationKeys.Weeks]: "هفته‌ها",
  [TranslationKeys.Months]: "ماه‌ها",
  [TranslationKeys.Animations]: "تصاوير متحرك",
};
