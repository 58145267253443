import {
  Flex,
  Icon,
  Pressable,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import * as React from "react";
import { Ionicons } from "@expo/vector-icons";
import ReminderItem from "./ReminderItem";
import { FlashList } from "@shopify/flash-list";
import ReminderModal from "./ReminderModal";
import useGetReminders from "../hooks/useGetReminders";
import { ReminderDto } from "../hooks/types";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";

type RemindersProps = {
  onScroll: (value: number) => void;
};

export function Reminders(props: RemindersProps) {
  const { onScroll } = props;

  const [reminder, setReminder] = React.useState<ReminderDto>();
  const [open, setOpen] = React.useState(false);

  const animation = useSelector((state: RootState) => state.settings.animation);

  const { t } = useTranslation();

  const handleOpen = (reminder?: ReminderDto) => {
    if (reminder) {
      setReminder(reminder);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setReminder(undefined);
  };

  const bg = useColorModeValue("white", "dark.700");

  const { data, refetch, isRefetching, isLoading, isError } = useGetReminders();

  const onRefresh = () => {
    refetch();
  };

  if (isLoading) return <Text>Loading...</Text>;
  if (isError) return <Text>An error occurred while fetching data</Text>;

  return (
    <>
      <ReminderModal
        open={open}
        handleClose={handleClose}
        reminder={reminder}
      />
      <FlashList
        contentContainerStyle={{ paddingTop: 205 }}
        keyExtractor={(item: ReminderDto) => item.id}
        data={data}
        renderItem={({ item }) => (
          <ReminderItem key={item.id} reminder={item} handleOpen={handleOpen} />
        )}
        estimatedItemSize={10}
        refreshing={isRefetching}
        onRefresh={onRefresh}
        ListFooterComponent={
          <Pressable onPress={() => handleOpen()} mb="130px" px="20px">
            <VStack
              w="100%"
              alignItems="center"
              bg={bg}
              shadow={6}
              borderRadius="xl"
              p={"20px"}
            >
              <Flex w="100%" alignItems="center" direction="row">
                <Icon as={Ionicons} name="add" size="md" />
                <Text fontSize="md">{t(TranslationKeys.AddANewReminder)}</Text>
              </Flex>
            </VStack>
          </Pressable>
        }
        showsVerticalScrollIndicator={false}
        onScroll={(e) => {
          if (animation) {
            onScroll(e.nativeEvent.contentOffset.y);
          }
        }}
      />
    </>
  );
}
