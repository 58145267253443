import * as React from "react";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import AntDesign from "@expo/vector-icons/AntDesign";
import CategoryFilter from "./CategoryFilter";
import WalletFilter from "./WalletFilter";
import useGetOverview from "../hooks/useGetOverview";
import Animated, {
  SharedValue,
  useAnimatedStyle,
  useDerivedValue,
} from "react-native-reanimated";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { updateDate } from "../store/slices/settingsSlice";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";

type OverviewProps = {
  wallets: string[];
  setWallets: (value: string[]) => void;
  categories: string[];
  setCategories: (value: string[]) => void;
  scrollValue: SharedValue<number>;
};

function Overview(props: OverviewProps) {
  const { wallets, setWallets, categories, setCategories, scrollValue } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const year = useSelector((state: RootState) => state.settings.year);
  const month = useSelector((state: RootState) => state.settings.month);

  const { data, refetch } = useGetOverview({
    year,
    month: month !== undefined ? month + 1 : undefined,
    categories: categories?.length > 0 ? categories : undefined,
    wallets: wallets?.length > 0 ? wallets : undefined,
  });

  React.useEffect(() => {
    refetch();
  }, [categories, wallets, year, month]);

  const currentDate = moment({
    year,
    month,
  });
  const prevDate = currentDate.clone().subtract({ month: 1 });
  const nextDate = currentDate.clone().add({ month: 1 });

  const handleNext = () => {
    dispatch(
      updateDate({
        year: nextDate.year(),
        month: nextDate.month(),
      })
    );
  };

  const handlePrev = () => {
    dispatch(
      updateDate({
        year: prevDate.year(),
        month: prevDate.month(),
      })
    );
  };

  const animatedStyles = useAnimatedStyle(() => {
    return {
      height: Math.min(Math.max(170 - scrollValue.value, 50), 170),
    };
  });

  return (
    <Animated.View style={[{ overflow: "hidden" }, animatedStyles]}>
      <VStack w="100%" p="10px 10px">
        <Flex
          w="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            colorScheme={useColorModeValue("primary", "dark")}
            aria-label="Prev"
            size="md"
            borderRadius="full"
            variant="solid"
            icon={<Icon as={AntDesign} name="caretleft" size="xs" />}
            onPress={handlePrev}
          />
          <Text fontSize="xl" textAlign="center" p="0px 20px" flex="1">
            {currentDate.format("MMM YYYY")}
          </Text>
          <IconButton
            colorScheme={useColorModeValue("primary", "dark")}
            aria-label="Next"
            size="md"
            borderRadius="full"
            variant="solid"
            icon={<Icon as={AntDesign} name="caretright" size="xs" />}
            onPress={handleNext}
            isDisabled={nextDate > moment()}
          />
        </Flex>
        <HStack
          w="100%"
          alignItems="stretch"
          divider={<Divider orientation="vertical" mx="3" my={2} />}
        >
          <Box flex="1" p="5px">
            <Text fontSize="sm" textAlign="center">
              {t(TranslationKeys.Income)}
            </Text>
            <NumericFormat
              value={Math.abs(data?.income ?? 0)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              renderText={(value) => (
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  color="green.500"
                  textAlign="center"
                >
                  {value}
                </Text>
              )}
            />
          </Box>
          <Box flex="1" p="5px">
            <Text fontSize="sm" textAlign="center">
              {t(TranslationKeys.Expenses)}
            </Text>
            <NumericFormat
              value={Math.abs(data?.expenses ?? 0)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              renderText={(value) => (
                <Text
                  fontSize="xl"
                  fontWeight="bold"
                  color="red.500"
                  textAlign="center"
                >
                  {value}
                </Text>
              )}
            />
          </Box>
        </HStack>
        <Flex
          w="100%"
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt="20px"
        >
          <IconButton
            colorScheme={useColorModeValue("coolGray", "dark")}
            size="sm"
            borderRadius="full"
            variant={useColorModeValue("subtle", "solid")}
            icon={<Icon as={AntDesign} name="filter" size="md" />}
          />
          <Divider orientation="vertical" mx="2" thickness={1} />
          <WalletFilter wallets={wallets} setWallets={setWallets} />
          <Divider orientation="vertical" mx="1" thickness={0} />
          <CategoryFilter
            categories={categories}
            setCategories={setCategories}
          />
        </Flex>
      </VStack>
    </Animated.View>
  );
}

const areEqual = (prevProps: OverviewProps, nextProps: OverviewProps) => {
  if (prevProps.wallets.length !== nextProps.wallets.length) {
    return false;
  }
  if (prevProps.categories.length !== nextProps.categories.length) {
    return false;
  }
  return true;
};

export default React.memo(Overview, areEqual);
