import { useMutation } from "@tanstack/react-query";
import { AuthenticateWithGoogleDto, TokenDto } from "./types";
import { api } from "./api";

export const authenticateWithGoogleApi = async (
  input: AuthenticateWithGoogleDto
) => {
  const result = await api.post("users/authenticate/google", input);
  return result.data;
};

export default function useAuthenticateWithGoogle() {
  return useMutation<TokenDto, unknown, AuthenticateWithGoogleDto>(
    ["authenticateWithGoogle"],
    authenticateWithGoogleApi
  );
}
