import * as SplashScreen from "expo-splash-screen";

import {
  useFonts,
  Nunito_300Light,
  Nunito_400Regular,
  Nunito_700Bold,
} from "@expo-google-fonts/nunito";
import { extendTheme, NativeBaseProvider, useColorMode } from "native-base";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import i18n, { localConfigs } from "../translations/i18n";
import { DevSettings, I18nManager, Platform } from "react-native";

export interface ThemeProps {
  children?: React.ReactNode;
}

SplashScreen.preventAutoHideAsync();

export function Theme(props: ThemeProps) {
  const { children } = props;
  const colorMode = useSelector((state: RootState) => state.settings.colorMode);
  const language = useSelector((state: RootState) => state.settings.language);
  const { setColorMode } = useColorMode();

  let [fontsLoaded] = useFonts({
    Nunito_300Light,
    Nunito_400Regular,
    Nunito_700Bold,
  });

  useEffect(() => {
    if (colorMode) {
      setColorMode(colorMode);
    }
    if (colorMode && fontsLoaded) {
      setTimeout(() => {
        SplashScreen.hideAsync();
      }, 1000);
    }
  }, [colorMode, fontsLoaded]);

  useEffect(() => {
    i18n.changeLanguage(language);

    const shouldBeRTL = localConfigs[language.split("-")[0]].rtl;

    if (shouldBeRTL !== I18nManager.isRTL && Platform.OS !== "web") {
      I18nManager.allowRTL(shouldBeRTL);
      I18nManager.forceRTL(shouldBeRTL);

      setTimeout(() => {
        DevSettings.reload();
      }, 100);
    }
  }, [language]);

  const defaultTheme = extendTheme({});

  const config = { initialColorMode: colorMode };
  const fontConfig = {
    Nunito: {
      100: {
        normal: "Nunito_300Light",
      },
      200: {
        normal: "Nunito_300Light",
      },
      300: {
        normal: "Nunito_400Regular",
      },
      400: {
        normal: "Nunito_400Regular",
      },
      500: {
        normal: "Nunito_700Bold",
      },
      600: {
        normal: "Nunito_700Bold",
      },
    },
  };
  const fonts = {
    heading: "Nunito",
    body: "Nunito",
    mono: "Nunito",
  };
  const colors = {
    primary: defaultTheme.colors.darkBlue,
    secondary: defaultTheme.colors.secondary,
    tertiary: defaultTheme.colors.blueGray,
    dark: defaultTheme.colors.blueGray,
  };
  const theme = extendTheme({
    config,
    fontConfig,
    fonts,
    colors,
    components: {
      Input: {
        baseStyle: {
          placeholderTextColor: "text.400",
          color: "text.900",
          borderColor: "dark.300",
          focusOutlineColor: "dark.500",
          _dark: {
            placeholderTextColor: "text.400",
            color: "text.100",
            borderColor: "dark.500",
            focusOutlineColor: "dark.300",
          },
        },
      },
    },
  });

  if (!fontsLoaded) {
    return null;
  }

  return <NativeBaseProvider theme={theme}>{children}</NativeBaseProvider>;
}
