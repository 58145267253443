import {
  Avatar,
  Badge,
  Box,
  Center,
  Flex,
  Icon,
  Image,
  Pressable,
  Spacer,
  Text,
  useColorModeValue,
  VStack,
} from "native-base";
import * as React from "react";
import { NumericFormat } from "react-number-format";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import Emoji from "./Emoji";
import { ReminderDto } from "../hooks/types";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../translations/keys";

type ReminderItemProps = {
  reminder: ReminderDto;
  handleOpen: (reminder?: ReminderDto) => void;
};

function ReminderItem(props: ReminderItemProps) {
  const { reminder, handleOpen } = props;

  const { t } = useTranslation();

  const date = moment();

  if (reminder.recurringYear) {
    if (date.dayOfYear() < reminder.recurringYear) {
      date.dayOfYear(reminder.recurringYear);
    } else {
      date.add({ year: 1 }).dayOfYear(reminder.recurringYear);
    }
  }
  if (reminder.recurringMonth) {
    if (date.date() < reminder.recurringMonth) {
      date.date(reminder.recurringMonth);
    } else {
      date.add({ month: 1 }).date(reminder.recurringMonth);
    }
  }
  if (reminder.recurringWeek) {
    if (date.day() < reminder.recurringWeek) {
      date.day(reminder.recurringWeek);
    } else {
      date.add({ week: 1 }).day(reminder.recurringWeek);
    }
  }

  let reminderText = "";
  if (reminder.reminderDay) {
    reminderText = `${
      reminder.reminderDay < 2 ? "One day" : `${reminder.reminderDay} days`
    } before`;
  }
  if (reminder.reminderWeek) {
    reminderText = `${
      reminder.reminderWeek < 2 ? "One week" : `${reminder.reminderWeek} weeks`
    } before`;
  }
  if (reminder.reminderMonth) {
    reminderText = `${
      reminder.reminderMonth < 2
        ? "One month"
        : `${reminder.reminderMonth} month`
    } before`;
  }

  const nameColor = useColorModeValue("dark.600", "dark.300");
  const domainColor = useColorModeValue("dark.400", "dark.500");
  const bg = useColorModeValue("white", "dark.700");

  return (
    <Pressable onPress={() => handleOpen(reminder)} px="20px">
      <VStack
        w="100%"
        alignItems="center"
        bg={bg}
        shadow={6}
        borderRadius="xl"
        p={"20px"}
        space={3}
        mb="20px"
      >
        <Flex w="100%" alignItems="center" direction="row">
          <Text fontSize="xl">{reminder.title}</Text>
          <Spacer />
          <Badge fontSize="xs" borderRadius="lg" colorScheme="primary">
            {t(reminder.type)}
          </Badge>
        </Flex>
        <Flex w="100%" alignItems="center" direction="row">
          {reminder.thirdParty.image.startsWith("http") && (
            <Box bg="white" borderRadius="full">
              <Image
                source={{ uri: reminder.thirdParty.image }}
                size="12"
                resizeMode={"contain"}
                borderRadius="full"
                alt={reminder.thirdParty.name}
              />
            </Box>
          )}
          {!reminder.thirdParty.image.startsWith("http") && (
            <Center
              bg="white"
              color="white"
              borderRadius="full"
              width="45px"
              height="45px"
            >
              <Emoji name={reminder.thirdParty.image} fontSize="3xl" />
            </Center>
          )}
          <VStack space={0} marginLeft="10px" alignItems="flex-start">
            <Text fontSize="sm" fontWeight="bold" color={nameColor}>
              {reminder.thirdParty.name}
            </Text>
            {reminder.thirdParty.domain && (
              <Text fontSize="xs" color={domainColor}>
                {reminder.thirdParty.domain}
              </Text>
            )}
          </VStack>
          <Spacer />
          <NumericFormat
            value={Math.abs(reminder.amount)}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(value) => <Text fontSize="xl">{value}</Text>}
          />
        </Flex>
        <Flex w="100%" alignItems="center" direction="row">
          {reminder.recurring && (
            <>
              <Center backgroundColor={"amber.200"} borderRadius="lg" p={0.5}>
                <Icon
                  as={Ionicons}
                  name="refresh"
                  size="xs"
                  color="amber.600"
                />
              </Center>
              <Text fontSize="xs" color="amber.600" ml={1}>
                {`${t(TranslationKeys.NextOn)} ${
                  date.year() === moment().year()
                    ? date.format("MMM Do")
                    : date.format("YYYY MMM Do")
                }`}
              </Text>
            </>
          )}
          <Spacer />
          {reminder.reminder && (
            <>
              <Center backgroundColor={"fuchsia.200"} borderRadius="lg" p={0.5}>
                <Icon
                  as={Ionicons}
                  name="notifications"
                  size="xs"
                  color="fuchsia.600"
                />
              </Center>
              <Text fontSize="xs" color="fuchsia.600" ml={1}>
                {reminderText}
              </Text>
            </>
          )}
        </Flex>
      </VStack>
    </Pressable>
  );
}

const areEqual = (
  prevProps: ReminderItemProps,
  nextProps: ReminderItemProps
) => {
  if (
    JSON.stringify(prevProps.reminder) !== JSON.stringify(nextProps.reminder)
  ) {
    return false;
  }
  return true;
};

export default React.memo(ReminderItem, areEqual);
