const images: Record<string, any> = {
  scotia: require("../assets/images/scotia.png"),
  cibc: require("../assets/images/cibc.png"),
  rbc: require("../assets/images/rbc.jpg"),
  td: require("../assets/images/td.png"),
};

export function getImage(imageName: string): any {
  return images[imageName.toLocaleLowerCase()];
}
